export const columnsDataComplex = [
    {
      Header: "Logo",
      accessor: "imageUrl",
      isImage: true,
      altText: "title",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Partnership Link",
      accessor: "url",
      isLink: true,
      linkTitle: "url",
    },
    {
      Header: "Actions",
      accessor: "id",
    },
];