export const columnsDataComplex = [
    {
        Header: "Name",
        accessor: "name",
    },
    {
      Header: "email",
      accessor: "email",
    },
    {
      Header: "Contact Number",
      accessor: "contactNumber",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Actions",
    },
];