export const columnsDataComplex = [
    {
      Header: "Date Time submitted",
      accessor: "paddedContactedDateTime",
    },
    {
      Header: "Contact Name",
      accessor: "fullName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Location",
      accessor: "locationName",
    },
    {
      Header: "Viewed",
      accessor: "isSeen",
    },
    {
      Header: "Actions",
      accessor: "id",
    },
];