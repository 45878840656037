import React from "react";
import { Box, useRadio } from "@chakra-ui/react";
export default function RadioCard(props) {
    const { getInputProps, getCheckboxProps, htmlProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
        <Box as='label'>
            <input {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                _checked={{
                    bg: 'teal.600',
                    color: 'white',
                    borderColor: 'teal.600',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                _invalid={{
                    borderColor: 'red.400',
                    borderWidth: '2px'
                }}
                {...htmlProps}
            >
                {props.children}
            </Box>
        </Box>
    )
}

RadioCard.defaultProps = {
    px: 4,
    py: 3,
    m: 1
}