import React from "react";

import { RangeDatepicker } from "chakra-dayzed-datepicker";

export default function DefaultStyleRangeDatepicker(props) {
    return <RangeDatepicker {...props} />
}
DefaultStyleRangeDatepicker.defaultProps = {
    propsConfigs: {
        triggerBtnProps: {
            w: "100%",
            borderRadius: "0.375rem",
            justifyContent: "flex-start"
        },
        dayOfMonthBtnProps: {
            defaultBtnProps: {
                borderColor: "red.400",
                _hover: {
                    background: 'blue.400',
                }
            },
        },
        calendarPanelProps: {
            // wrapperProps: {
            //   borderColor: 'green',
            // },
            contentProps: {
                borderWidth: 0,
            },
            // headerProps: {
            //   padding: '5px',
            // },
            dividerProps: {
                display: "none",
            },
        },
    },
    configs: {
        dateFormat: "dd/MM/yyyy"
    }
}