import { Box, createStandaloneToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { columnsDataComplex } from "views/admin/productionSite/components/columnsData";
import ItemsTable from "components/admin/ItemsTable";
import { getProductSite } from "apis/productSite";
import { disableProductSite } from "apis/productSite";
import FilterBar from "views/admin/productionSite/components/filterBar";

export default function ProductionSite() {
  useEffect(() => {
    refreshProductionSite();
  }, []);

  const [data, setData] = useState([]);
  const toast = createStandaloneToast();
  const [unfilteredData, setUnfilteredData] = useState([]);

  function prepareData(data) {
    const preparedData = data.map((productionSite) => {
      return productionSite;
    });
    setData(preparedData);
  }

  function refreshProductionSite() {
    getProductSite().then(res => {
      setUnfilteredData(res.data);
      prepareData(res.data);
    }).catch(err => {
      console.log(err);
    })
  }

  function disableProductionSite(id, isDisabled) {
    disableProductSite(id, !isDisabled).then(res => {
      refreshProductionSite();
      toast({
        title: 'Successful',
        description: `Production site ${isDisabled ? "enabled" : "disabled"}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={prepareData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        createUrl="site/create"
        viewUrl="site/view"
        disableCallback={disableProductionSite}
      ></ItemsTable>
    </Box>
  );
}
