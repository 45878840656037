import { Box, createStandaloneToast } from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import { columnsDataComplex, } from "views/admin/organisation/components/columnsData";
import ItemsTable from "components/admin/ItemsTable";
import { getOrganisations, disableOrganisation } from "apis/organisation";
import FilterBar from "views/admin/organisation/components/filterBar";
import { UserRoles } from "utils/Constants";
import { UserContext } from "App";

export default function OrganisationList() {
  const [data, setData] = useState([]);
  const toast = createStandaloneToast();
  const [unfilteredData, setUnfilteredData] = useState([]);

  const [userContext, _] = useContext(UserContext);
  const isAdmin = userContext.roles.includes(UserRoles.Admin)

  useEffect(() => {
    refreshOrganisation();
  }, []);


  function refreshOrganisation() {
    getOrganisations().then(res => {
      setUnfilteredData(res.data);
      setData(res.data);
    }).catch(err => {
      console.log(err);
    })
  }

  function disableOrg(id, isDisabled) {
    disableOrganisation(id, !isDisabled).then(res => {
      refreshOrganisation();
      toast({
        title: 'Successful',
        description: `Organisation ${isDisabled ? "enabled" : "disabled"}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={setData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        createUrl={isAdmin ? "organisation/create" : undefined}
        viewUrl="organisation/view"
        disableCallback={isAdmin ? disableOrg : undefined}
      ></ItemsTable>
    </Box>
  );
}
