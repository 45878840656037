export const columnsDataComplex = [
    {
      Header: "Product",
      accessor: "productName",
    },
    {
      Header: "Production Site",
      accessor: "productionSiteName",
    },
    {
      Header: "Organisation",
      accessor: "orderOrganisationLink",
  },
    {
        Header: "Quantity",
        accessor: "quantity",
    },
    {
        Header: "Purchase Order",
        accessor: "purchaseOrder",
    },
    {
      Header: "Ordered Time",
      accessor: "formattedOrderedDateTime",
    },
    {
      Header: "Delivery Time",
      accessor: "requiredDateTime",
    },
    {
      Header: "Status",
      accessor: "statusDisplay",
    },
    {
      Header: "Actions"
    },
];