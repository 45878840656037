import api from "./api";

const API_URL = `${process.env.REACT_APP_API_URL}/api/Career`;

export const getCareers = async () => {
    const res = await api.get(`${API_URL}`);
    return res;
};

export const getCareer = async (id) => {
    const res = await api.get(`${API_URL}/${id}`);
    return res;
};

export const createCareer = async (form) => {
  const res = await api.post(`${API_URL}`, form);
  return res;
}

export const updateCareer = async (id, form) => {
  const res = await api.put(`${API_URL}/${id}`, form);
  return res;
}

export const removeCareer = async (id) => {
  const res = await api.delete(`${API_URL}/${id}`);
  return res;
}