import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';

import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import App from "App"

ReactDOM.render(
	<div>

	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ThemeEditorProvider>
				<App/>
			</ThemeEditorProvider>
		</React.StrictMode>
	</ChakraProvider>
	</div>
		,
	document.getElementById('root')
);
