import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { columnsDataComplex } from "views/admin/news/components/columnsData";
import { getNews, removeNews } from "apis/news";
import { deleteImage } from "apis/files";
import ItemsTable from "components/admin/ItemsTable";
import FilterBar from "views/admin/news/components/filterBar";

export default function News() {
  useEffect(() => {
    refreshNews();
  }, []);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  function prepareData(data) {
    const preparedData = data.map((news) => {
      return news;
    });
    setData(preparedData);
  }

  function refreshNews() {
    getNews()
      .then((res) => {
        setUnfilteredData(res.data);
        prepareData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deleteNews(id) {
    removeNews(id).then(() => {
      let imageUrl = data.find((element) => element.id == id).imageUrl;
      deleteImage(imageUrl)
        .then(() => {
          refreshNews();
        })
        .catch((err) => {
          console.log(err);
        });
    }).catch((err) => {
      console.log(err);
    });
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={prepareData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="new/view"
        createUrl="new/create"
        disableCallback={deleteNews}
        disableBtnText="Delete"
        disableDialogHeader="Delete "
        disableDialogText="Are you sure you want to delete "
        disableConfirmBtnText="Delete"
        displayField="title"
      />
    </Box>
  );
}
