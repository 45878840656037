import { Box, createStandaloneToast } from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import { columnsDataComplex, } from "views/admin/user/components/columnsData";
import ItemsTable from "components/admin/ItemsTable";
import { getUsers, banUser } from "apis/user"
import { UserRoleNames } from "utils/Constants"
import FilterBar from "views/admin/user/components/filterBar";
import { UserContext } from "App";


export default function UserList() {
  const [userContext, _] = useContext(UserContext);
  const [data, setData] = useState([]);
  const toast = createStandaloneToast();
  const [unfilteredData, setUnfilteredData] = useState([]);

  function prepareData(data) {
    const preparedData = data.map((user) => {
      return user;
    });
    setData(preparedData);
  }

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    getUsers().then(res => {
      res.data.map( user => {
        user.paddedRoles = user.roles.map(r => UserRoleNames[r]).join(", ")
      })
      setUnfilteredData(res.data);
      prepareData(res.data);
    }).catch(err => {
      console.log(err);
    })
  }

  function banUserFunc(id, isBanned) {
    let userId;
    data.forEach(user => {
      if (user.username == userContext.username){
        userId = user.id
      }
    });

    if (id == userId){
      toast({
        title: '🤨 Be careful there!',
        description: `You can't ban yourself!`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } else {
      banUser(id, !isBanned).then(res => {
        refreshData();
        toast({
          title: 'Successful',
          description: `User is ${isBanned ? "unbanned" : "banned"}`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }).catch(err => {
        console.log(err);
      })
    }
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={prepareData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        createUrl="user/create"
        viewUrl="user/view"
        disableCallback={banUserFunc}
        disableBtnText="Ban"
        disableDialogHeader="Ban "
        disableDialogText="Are you sure you want to ban "
        disableConfirmBtnText="Ban"
        enableBtnText="Unban"
        enableDialogHeader="Unban "
        enableDialogText="Are you sure you want to Unban "
        enableConfirmBtnText="Unban"
        displayField="displayName"
        isDisabledField="isBanned"
      ></ItemsTable>
    </Box>
  );
}
