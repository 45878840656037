import React, { useEffect, useState, useRef  } from "react";

import {
    Flex,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    FormHelperText,
    Button,
    Checkbox,
    Spacer,
    SimpleGrid,
    Input,
    Text,
} from "@chakra-ui/react";

import {
    getSelectOptionObj
} from "utils/Constants";

import SearchBar from 'components/admin/SearchBar';
import ReactSelect from "components/admin/DefaultStyleReactSelect";
import { UserRoles } from "utils/Constants";
import { getOrganisations } from "apis/organisation";

export default function FilterBar(props) {
    const { data, setData } = props;
    const [organisations, setOrganisations] = useState([]);

    // states for filtering
    const [displayNameSearchInput, setDisplayNameSearchInput] = useState();
    const displayNameSearchRef = useRef(null);
    const [usernameSearchInput, setUsernameSearchInput] = useState();
    const usernameSearchRef = useRef(null);
    const [selectedOrganisations, setSelectedOrganisations] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [notAdmin, setNotAdmin] = useState(false);
    const [isCustomer, setIsCustomer] = useState(false);
    const [notCustomer, setNotCustomer] = useState(false);
    const [isStaff, setIsStaff] = useState(false);
    const [notStaff, setNotStaff] = useState(false);
    const [isDriver, setIsDriver] = useState(false);
    const [notDriver, setNotDriver] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [notDisabled, setNotDisabled] = useState(false);

    //

    const menuBg = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function resetForm() {
        setDisplayNameSearchInput()
        displayNameSearchRef.current?.clear();
        setUsernameSearchInput()
        usernameSearchRef.current?.clear();
        setSelectedOrganisations([])
        setIsAdmin(false)
        setNotAdmin(false)
        setIsCustomer(false)
        setNotCustomer(false)
        setIsStaff(false)
        setNotStaff(false)
        setIsDriver(false)
        setNotDriver(false)
        setIsDisabled(false)
        setNotDisabled(false)
    }

    //// filter funtions
    function filter() {
        const filtered = data.filter(user =>
            filterDisplayName(user)
            && filterUsername(user)
            && filterOrganisations(user)
            && filterAdmin(user)
            && filterCustomer(user)
            && filterStaff(user)
            && filterDriver(user)
            && filterDisabled(user)
        )

        setData(filtered)
    }

    function filterDisplayName(user) {
        if (displayNameSearchInput){
            if (!user.displayName.toLowerCase().includes(displayNameSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterUsername(user) {
        if (usernameSearchInput){
            if (!user.username.toLowerCase().includes(usernameSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterOrganisations(user) {
        if (selectedOrganisations.length > 0) {
            return selectedOrganisations.some(item => item.value == user.organisation.id)
        }
        return true;
    }

    function filterAdmin(user){
        if (isAdmin == true && !user.roles.includes(UserRoles["Admin"])){
            return false;
        }
        if (notAdmin == true && user.roles.includes(UserRoles["Admin"])){
            return false;
        }
        return true;
    }

    function filterCustomer(user){
        if (isCustomer == true && !user.roles.includes(UserRoles["Customer"])){
            return false;
        }
        if (notCustomer == true && user.roles.includes(UserRoles["Customer"])){
            return false;
        }
        return true;
    }

    function filterStaff(user){
        if (isStaff == true && !user.roles.includes(UserRoles["ProductionStaff"])){
            return false;
        }
        if (notStaff == true && user.roles.includes(UserRoles["ProductionStaff"])){
            return false;
        }
        return true;
    }

    function filterDriver(user){
        if (isDriver == true && !user.roles.includes(UserRoles["Driver"])){
            return false;
        }
        if (notDriver == true && user.roles.includes(UserRoles["Driver"])){
            return false;
        }
        return true;
    }

    function filterDisabled(user){
        if (isDisabled == true && !user.isBanned){
            return false;
        }
        if (notDisabled == true && user.isBanned){
            return false;
        }
        return true;
    }

    ////

    useEffect(() => {
        getOrganisations()
        .then((res) => setOrganisations(res.data))
        .catch(err => console.log(err));
    }, [])

    return (
        <form>
            <Box
                w={{ sm: "100%", lg: "auto" }}
                bg={menuBg}
                p="20px"
                borderRadius="10px"
                boxShadow={shadow}
                mb="10px"
            >

                <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} gap='10px' mb='10px'>
                    <FormControl>
                        <FormLabel>Filter By Display Name</FormLabel>
                        <SearchBar
                            ref={displayNameSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setDisplayNameSearchInput}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Username</FormLabel>
                        <SearchBar
                            ref={usernameSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setUsernameSearchInput}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Organisation</FormLabel>
                        <ReactSelect
                            placeholder="Select organisations"
                            isClearable
                            isMulti
                            options={organisations.map(getSelectOptionObj)}
                            value={selectedOrganisations}
                            onChange={setSelectedOrganisations}
                        />
                    </FormControl>

                </SimpleGrid>

                <Flex
                    justifyContent="end"
                    alignItems="end"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                >

                    <FormControl maxW={{ base: "100%", lg: "700px" }} my={{ base: "10px", lg: "unset" }}>
                        <Flex 
                            justifyContent={{ base: "center", md: "start" }}
                            flexWrap={{ base: "wrap", md: "nowrap" }}
                            gap="36px"
                        >
                            <Box>
                                <FormLabel textAlign="center" w="100%">Admin Permission</FormLabel>
                                <FormHelperText>
                                    <Flex gap="24px" justify="center">
                                        <Checkbox colorScheme='green' isChecked={isAdmin} onChange={(e) => {setIsAdmin(e.target.checked); setNotAdmin(false)}}>
                                            Yes
                                        </Checkbox>

                                        <Checkbox colorScheme='red' isChecked={notAdmin} onChange={(e) => {setNotAdmin(e.target.checked); setIsAdmin(false)}}>
                                            No
                                        </Checkbox>
                                    </Flex>
                                </FormHelperText>
                            </Box>

                            <Box>
                                <FormLabel textAlign="center" w="100%">Customer Permission</FormLabel>
                                <FormHelperText>
                                    <Flex gap="24px" justify="center">
                                        <Checkbox colorScheme='green' isChecked={isCustomer} onChange={(e) => {setIsCustomer(e.target.checked); setNotCustomer(false)}}>
                                            Yes
                                        </Checkbox>

                                        <Checkbox colorScheme='red' isChecked={notCustomer} onChange={(e) => {setNotCustomer(e.target.checked); setIsCustomer(false)}}>
                                            No
                                        </Checkbox>
                                    </Flex>
                                </FormHelperText>
                            </Box>

                            <Box>
                                <FormLabel textAlign="center" w="100%">Staff Permission</FormLabel>
                                <FormHelperText>
                                    <Flex gap="24px" justify="center">
                                        <Checkbox colorScheme='green' isChecked={isStaff} onChange={(e) => {setIsStaff(e.target.checked); setNotStaff(false)}}>
                                            Yes
                                        </Checkbox>

                                        <Checkbox colorScheme='red' isChecked={notStaff} onChange={(e) => {setNotStaff(e.target.checked); setIsStaff(false)}}>
                                            No
                                        </Checkbox>
                                    </Flex>
                                </FormHelperText>
                            </Box>

                            <Box>
                                <FormLabel textAlign="center" w="100%">Driver Permission</FormLabel>
                                <FormHelperText>
                                    <Flex gap="24px" justify="center">
                                        <Checkbox colorScheme='green' isChecked={isDriver} onChange={(e) => {setIsDriver(e.target.checked); setNotDriver(false)}}>
                                            Yes
                                        </Checkbox>

                                        <Checkbox colorScheme='red' isChecked={notDriver} onChange={(e) => {setNotDriver(e.target.checked); setIsDriver(false)}}>
                                            No
                                        </Checkbox>
                                    </Flex>
                                </FormHelperText>
                            </Box>

                        </Flex>
                    </FormControl>
                    
                    <Spacer />

                </Flex>

                <Flex
                    justifyContent="end"
                    alignItems="center"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                    mt="10px"
                >
                    <FormControl>
                        <Flex gap="12px" fontSize="medium" >
                            <Text fontWeight="medium" >User is Banned</Text>
                            <Checkbox colorScheme='green' textColor="gray.500" isChecked={isDisabled} onChange={(e) => {setIsDisabled(e.target.checked); setNotDisabled(false)}}>
                                Yes
                            </Checkbox>
                            <Checkbox colorScheme='red' textColor="gray.500" isChecked={notDisabled} onChange={(e) => {setNotDisabled(e.target.checked); setIsDisabled(false)}}>
                                No
                            </Checkbox>
                        </Flex>
                    </FormControl>
                    
                    <Spacer />

                    <Button variant="outline" colorScheme='blue' px="30px" onClick={filter}>Search</Button>
                    <Button variant="outline" colorScheme='yellow' px="30px" type="reset" onClick={resetForm}>Clear</Button>
                </Flex>
            </Box>
        </form>
    )
}