import React, { useEffect, useState } from "react";

import {
    Flex,
    Box,
    useColorModeValue,
    HStack,
    FormControl,
    FormLabel,
    FormHelperText,
    Button,
    Checkbox,
    InputGroup,
    InputLeftAddon,
    Spacer,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useRadioGroup,
    SimpleGrid,
    useDisclosure,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Icon,
} from "@chakra-ui/react";

import moment from "moment";

import { SearchIcon } from "@chakra-ui/icons";

import {
    OrderStatusOptions,
    OrderStatusSelectFilterOptions,
    getSelectOptionObj
} from "utils/Constants";

import ReactSelect from "components/admin/DefaultStyleReactSelect"
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import RangeDatepicker from "components/admin/DefaultStyleRangeDatepicker"
import RadioCard from "components/radio/RadioCard";

//API import
import { getProductSite } from "apis/productSite";
import { getOrganisations } from "apis/organisation";
import { getProductsWithoutRules } from "apis/product";

function FilterBar(props) {
    const { data, setData, display } = props;
    const [products, setProducts] = useState([]);
    const [productionSites, setProductionSites] = useState([]);
    const [organisations, setOrganisations] = useState([]);

    // states for filtering
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedProductionSites, setSelectedProductionSites] = useState([]);
    const [selectedOrganisations, setSelectedOrganisations] = useState([]);

    const [typedInPO, setTypedInPO] = useState("");
    const [isFilterPO, setIsFilterPO] = useState(false);

    const [typedInQty, setTypedInQty] = useState(0);
    const [typedInQty2, setTypedInQty2] = useState(0);
    const [selectedQtyType, setSelectedQtyType] = useState("lt");
    const [isFilterQty, setIsFilterQty] = useState(false);

    const [selectedStatuses, setSelectedStatuses] = useState([]);

    const [selectedOrderedDates, setSelectedOrderedDates] = useState([new Date(), new Date()]);
    const [isFilterOrderedDates, setIsFilterOrderedDates] = useState(false);
    const [selectedDeliveryDates, setSelectedDeliveryDates] = useState([new Date(), new Date()]);
    const [isFilterDeliveryDates, setIsFilterDeliveryDates] = useState(false);

    const [typedInOther, setTypedInOther] = useState("");
    const [isFilterOther, setIsFilterOther] = useState(false);
    //

    const { getRootProps, getRadioProps } = useRadioGroup({
        defaultValue: selectedQtyType,
        onChange: setSelectedQtyType
    })

    const radioGroupProps = getRootProps();

    const menuBg = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function resetForm() {
        setSelectedProducts([])
        setSelectedProductionSites([])
        setSelectedOrganisations([])

        setTypedInPO("")
        setIsFilterPO(false)

        setTypedInQty(0)
        setTypedInQty2(0)
        setSelectedQtyType("lt")
        setIsFilterQty(false)

        setSelectedStatuses([])

        setSelectedOrderedDates([new Date(), new Date()])
        setIsFilterOrderedDates(false)
        setSelectedDeliveryDates([new Date(), new Date()])
        setIsFilterDeliveryDates(false)

        setTypedInOther("")
        setIsFilterOther(false)
    }

    //// filter funtions
    function filter() {
        const filtered = data.filter(order =>
            filterProducts(order)
            && filterProductionSites(order)
            && filterOrganisations(order)
            && filterQty(order)
            && filterPO(order)
            && filterOrderedDates(order)
            && filterDeliveryDates(order)
            && filterStatuses(order)
            && filterOtherFields(order)
        )

        setData(filtered)
    }

    //products
    function filterProducts(order) {
        if (selectedProducts.length > 0) {
            return selectedProducts.some(item => item.value == order.productId)
        }
        return true;
    }

    //production sites
    function filterProductionSites(order) {
        if (selectedProductionSites.length > 0) {
            return selectedProductionSites.some(item => item.value == order.productionSiteId)
        }
        return true;
    }

    //organisations
    function filterOrganisations(order) {
        if (selectedOrganisations.length > 0) {
            return selectedOrganisations.some(item => item.value == order.orderOrgainsationId)
        }
        return true;
    }

    //Purchase order
    function filterPO(order) {
        if (isFilterPO) {
            return typedInPO === "" ? order.purchaseOrder === "" : order.purchaseOrder.toLocaleLowerCase().includes(typedInPO.toLocaleLowerCase())
        }
        return true;
    }

    //qty
    function filterQty(order) {
        if (isFilterQty) {
            switch (selectedQtyType) {
                case "lt":
                    return order.quantity < typedInQty
                case "gt":
                    return order.quantity > typedInQty
                case "eq":
                    return order.quantity == typedInQty
                case "bw":
                    const smallerVal = typedInQty < typedInQty2 ? typedInQty : typedInQty2
                    const biggerVal = typedInQty > typedInQty2 ? typedInQty : typedInQty2
                    return order.quantity >= smallerVal && order.quantity <= biggerVal
                default:
                    return true;
            }
        }
        return true;
    }

    //ordered date
    function filterOrderedDates(order) {
        if (isFilterOrderedDates) {
            return moment(order.orderedDateTime).isSameOrAfter(selectedOrderedDates[0], 'day') &&
                moment(order.orderedDateTime).isSameOrBefore(selectedOrderedDates[1], 'day')
        }
        return true;
    }

    //delivery date
    function filterDeliveryDates(order) {
        if (isFilterDeliveryDates) {
            return moment(order.deliveryDate).isSameOrAfter(selectedDeliveryDates[0], 'day') &&
                moment(order.deliveryDate).isSameOrBefore(selectedDeliveryDates[1], 'day')
        }
        return true;
    }

    //status
    function filterStatuses(order) {
        if (selectedStatuses.length > 0) {
            return selectedStatuses.some(item => item.value == order.status)
        }
        return true;
    }

    //other fields
    function filterOtherFields(order) {
        if (isFilterOther) {
            if (typedInOther === "") {
                return order.internalNotes === ""
                    || order.additionalInformation === ""
                    || order.invoiceNumber === ""
                    || order.files.length === 0
            } else {
                return order.internalNotes.toLowerCase().includes(typedInOther.toLocaleLowerCase())
                    || order.additionalInformation.toLowerCase().includes(typedInOther.toLocaleLowerCase())
                    || order.invoiceNumber.toLowerCase().includes(typedInOther.toLocaleLowerCase())
                    || order.orderPerson.toLowerCase().includes(typedInOther.toLocaleLowerCase())
                    || order.patientDetails.name.toLowerCase().includes(typedInOther.toLocaleLowerCase())
                    || order.patientDetails.doctorName.toLowerCase().includes(typedInOther.toLocaleLowerCase())
                    || order.files.some(f => f.originalName.toLowerCase().includes(typedInOther.toLocaleLowerCase()))
            }
        }
        return true;
    }

    ////

    useEffect(() => {
        getProductsWithoutRules().then((res) => setProducts(res.data));
        getOrganisations().then((res) => setOrganisations(res.data));
        getProductSite().then((res) => setProductionSites(res.data));
    }, [])

    return (
        <Box
            display={display}
            w={{ sm: "100%", lg: "auto" }}
            bg={menuBg}
            p="20px"
            borderRadius="10px"
            boxShadow={shadow}
            mb="10px"
        >
            <form>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap='10px' mb='10px'>
                    <FormControl>
                        <FormLabel>Filter By Product</FormLabel>
                        <ReactSelect
                            placeholder="Select products"
                            isClearable
                            isMulti
                            options={products.map(getSelectOptionObj)}
                            value={selectedProducts}
                            onChange={setSelectedProducts}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Production Site</FormLabel>
                        <ReactSelect
                            placeholder="Select production sites"
                            isClearable
                            isMulti
                            options={productionSites.map(getSelectOptionObj)}
                            value={selectedProductionSites}
                            onChange={setSelectedProductionSites}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Organisation</FormLabel>
                        <ReactSelect
                            placeholder="Select organisations"
                            isClearable
                            isMulti
                            options={organisations.map(getSelectOptionObj)}
                            value={selectedOrganisations}
                            onChange={setSelectedOrganisations}
                        />
                    </FormControl>

                    <FormControl my={{ base: "10px", lg: "unset" }}>
                        <FormLabel>Purchase Order</FormLabel>
                        {isFilterPO && (<SearchBar width="unset" me="10px" borderRadius="0.375rem" onChange={e => setTypedInPO(e.target.value)} />)}
                        <FormHelperText>
                            <Checkbox isChecked={isFilterPO} onChange={e => setIsFilterPO(e.target.checked)}>Search Purchase Order</Checkbox>
                        </FormHelperText>
                    </FormControl>
                </SimpleGrid>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap='10px' mb='10px'>
                    <FormControl>
                        <FormLabel>Quantity</FormLabel>
                        {isFilterQty && (
                            <InputGroup gap="unset" flexWrap={{ base: "wrap", md: "nowrap" }}>
                                <InputLeftAddon px="0">
                                    <HStack {...radioGroupProps}>
                                        <RadioCard {...getRadioProps({ value: 'lt' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >&lt;</RadioCard>
                                        <RadioCard {...getRadioProps({ value: 'gt' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >&gt;</RadioCard>
                                        <RadioCard {...getRadioProps({ value: 'eq' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >=</RadioCard>
                                        <RadioCard {...getRadioProps({ value: 'bw' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >b/w</RadioCard>
                                    </HStack>
                                </InputLeftAddon>
                                <NumberInput
                                    value={typedInQty}
                                    onChange={(v) => setTypedInQty(Number(v))}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                {
                                    selectedQtyType === "bw" && (<NumberInput
                                        value={typedInQty2}
                                        onChange={(v) => setTypedInQty2(Number(v))}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>)
                                }
                            </InputGroup>
                        )}

                        <FormHelperText>
                            <Checkbox isChecked={isFilterQty} onChange={e => setIsFilterQty(e.target.checked)}>Search Quantity</Checkbox>
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Ordered Dates Between</FormLabel>
                        {isFilterOrderedDates && (<RangeDatepicker
                            selectedDates={selectedOrderedDates}
                            onDateChange={setSelectedOrderedDates}
                        />)}
                        <FormHelperText>
                            <Checkbox isChecked={isFilterOrderedDates} onChange={e => setIsFilterOrderedDates(e.target.checked)}>Search Ordered Dates</Checkbox>
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Delivery Dates Between</FormLabel>
                        {isFilterDeliveryDates && (<RangeDatepicker
                            selectedDates={selectedDeliveryDates}
                            onDateChange={setSelectedDeliveryDates}
                        />)}
                        <FormHelperText>
                            <Checkbox isChecked={isFilterDeliveryDates} onChange={e => setIsFilterDeliveryDates(e.target.checked)}>Search Delivery Dates</Checkbox>
                        </FormHelperText>
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="status">Filter by Statuses</FormLabel>
                        <ReactSelect
                            placeholder="Select statuses"
                            isClearable
                            isMulti
                            options={OrderStatusOptions}
                            filterOption={OrderStatusSelectFilterOptions}
                            value={selectedStatuses}
                            onChange={setSelectedStatuses}
                        />
                    </FormControl>
                </SimpleGrid>

                <Flex
                    justifyContent="end"
                    alignItems="center"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                >
                    <FormControl maxW={{ base: "100%", lg: "700px" }} my={{ base: "10px", lg: "unset" }}>
                        <FormLabel>Filter by Order Other Information</FormLabel>
                        {isFilterOther && (<SearchBar width="unset" me="10px" borderRadius="0.375rem" onChange={e => setTypedInOther(e.target.value)} />)}
                        <FormHelperText>
                            <Checkbox isChecked={isFilterOther} onChange={e => setIsFilterOther(e.target.checked)}>
                                Search other information (additional info, patient's name, nuclear medicine doctor's name, etc.,)
                            </Checkbox>
                        </FormHelperText>
                    </FormControl>
                    <Spacer />

                    <Button variant="outline" colorScheme='blue' px="30px" onClick={filter}>Search</Button>
                    <Button variant="outline" colorScheme='yellow' px="30px" type="reset" onClick={resetForm}>Clear</Button>
                </Flex>
            </form>
        </Box>
    )
}

export default function ResponsiveFilterBar(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const iconColor = useColorModeValue("white", "black");

    return (
        <>
            <FilterBar display={{ base: "none", xl: "block" }} {...props} />
            {/* <Flex display={{ base: "flex", xl: "none" }} justify={"center"}>
                <Button colorScheme="blue" onClick={onOpen}>Filter Bar</Button>
            </Flex> */}

            <Button
                display={{ base: "flex", xl: "none" }}
                h='60px'
                w='60px'
                zIndex='99'
                colorScheme="brand"
                // bg="#9ccb3b"
                position='fixed'
                // variant='no-effects'
                right="35px"
                bottom='30px'
                border='1px solid'
                borderColor='#ebe515'
                borderRadius='50px'
                onClick={onOpen}
                p='0px'
                align='center'
                justify='center'>
                <Icon
                    h='24px'
                    w='24px'
                    color={iconColor}
                    as={SearchIcon}
                />
            </Button>
            <Drawer placement='top' onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>Filter Orders</DrawerHeader>
                    <DrawerBody>
                        <FilterBar {...props} />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}