import api from "./api";

const API_URL = `${process.env.REACT_APP_API_URL}/api/ContactUs`;

export const getContacts = async () => {
    const res = await api.get(`${API_URL}`);
    return res;
};

export const getContact = async (id) => {
    const res = await api.get(`${API_URL}/${id}`);
    return res;
};

export const createContact = async (form) => {
  const res = await api.post(`${API_URL}`, form);
  return res;
}

export const updateContact = async (id, form) => {
  const res = await api.put(`${API_URL}/${id}`, form);
  return res;
}

export const removeContact = async (id) => {
  const res = await api.delete(`${API_URL}/${id}`);
  return res;
}