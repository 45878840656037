import React, { useState, useEffect } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { getDashboardStats } from "apis/dashboard";

export default function AdminDashboard() {
  const [data, setData] = useState(null);

  useEffect(() => {
    getDashboardStats()
      .then(res => {
        setData(res.data);
        console.log(data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex pt="10" justify="center" align="center">
        <Text fontSize="4xl">Work in progress</Text>
      </Flex>
    </Box>
  );
}
