import React from "react";

// Chakra imports
import {
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { MdUpload } from "react-icons/md";
import Dropzone from "./Dropzone";
import { MaxFilesUpload } from "utils/Constants";

export default function Upload(props) {
  const { onSelected, files, used, total, fileTypes, title, selectedFileListTitles, description, multiple, max, ...rest } = props;

  const brandColor = useColorModeValue("brand.500", "white");
  const iconSize = {
    base: "50px",
    md: "60px",
    lg: "70px",
  }

  return (
    <Card mb='20px' align='center' p='20px' {...rest}>
      <Flex h='100%' flexDirection="column" justify="center">
        <Dropzone
          mb='10px'
          minH='150px'
          max={max}
          onSelected={onSelected}
          files={files}
          fileTypes={fileTypes}
          multiple={multiple}
          title={selectedFileListTitles}
          content={
            <Flex flexDirection="column" align='center' justify='center'>
              <Icon as={MdUpload} w={iconSize} h={iconSize} color={brandColor} />
              <Text fontSize={{ base: 'md', lg: 'xl' }} mb='12px' fontWeight='700' color={brandColor}>
                {title}
              </Text>
              <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                {description}
              </Text>
            </Flex>
          }
        />
      </Flex>
    </Card>
  );
}

Upload.defaultProps = {
  selectedFileListTitles: "Selected Files",
  max: MaxFilesUpload,
}
