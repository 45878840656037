import { React, useEffect, useState } from "react";

import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  createStandaloneToast,
  useColorModeValue,
  Image,
  Textarea,
} from "@chakra-ui/react";

import { Field } from "formik";
import { useParams, useHistory } from "react-router-dom";
import FormContainer from "components/admin/FormContainer";
import Upload from "components/admin/Upload";
import { createNews, getNewsID, updateNews } from "apis/news";
import { imageUpload, deleteImage } from "apis/files";
import { ImageUploadFileTypes, validateEmpty } from "utils/Constants";
import AuthImage from "components/auth/AuthImage"

export default function NewsForm(props) {
  const toast = createStandaloneToast();
  const { action, id } = useParams();
  const viewOnly = action === "view";
  const history = useHistory();
  const backUrl = "/admin/news";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [NewsData, setNews] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (action && id) {
      getNewsID(id)
        .then((res) => {
          setNews(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  function submit(values) {
    if (!action) {
      createNews(values)
        .then(() => {
          toast({
            title: "Successful",
            description: `News "${values.title}" has been created`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push(backUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (action === "edit") {
      updateNews(values.id, values)
        .then(() => {
          toast({
            title: "Successful",
            description: `News "${values.title}" has been updated`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push(backUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast({
        title: "Invalid submit action.",
        description: "An error has occurred, please contact developers.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log("action not specified", action);
    }
  }

  return (
    (!action || NewsData) && (
      <FormContainer
        backUrl={backUrl}
        initialValues={
          action ? NewsData : { title: "", url: "", description: "", files: "" }
        }
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          if (files.length > 0) {
            let form = new FormData();
            form.append("image", files[0]);
            imageUpload(form)
              .then((res) => {
                if (NewsData?.imageUrl) {
                  deleteImage(NewsData.imageUrl)
                    .then(() => {
                      values.imageUrl = res.data.name;
                      submit(values);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  values.imageUrl = res.data.name;
                  submit(values);
                }
              })
              .catch((err) => {
                console.log("image upload error", err);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          } else {
            submit(values);
            actions.setSubmitting(false);
          }
        }}
      >
        <Flex px="25px" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            News Information
          </Text>
        </Flex>

        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          gap={{ base: "24px", lg: "48px" }}
          px="24px"
          my="24px"
          alignItems={"start"}
        >
          <Field name="title" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="title"
                  isInvalid={form.errors.title && form.touched.title}
                >
                  <FormLabel htmlFor="title">News Title</FormLabel>
                  <Input
                    readOnly={viewOnly}
                    placeholder="Title for the news"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>

          <Field name="url" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="url"
                  isInvalid={form.errors.url && form.touched.url}
                >
                  <FormLabel htmlFor="url">News Reference Link</FormLabel>
                  <Input
                    readOnly={viewOnly}
                    placeholder="Link for reference"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.url}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </Flex>

        <Flex px="25px" mt="24px" align="center">
          <Text
            color={textColor}
            fontSize="18px"
            fontWeight="500"
            lineHeight="100%"
          >
            Description
          </Text>
        </Flex>

        <Flex px="25px" mt="24px" align="center">
          <Field name="description" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="description"
                  isInvalid={
                    form.errors.description && form.touched.description
                  }
                >
                  <Textarea
                    readOnly={viewOnly}
                    placeholder="Information here"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </Flex>

        {action != "view" && (
          <Flex px="25px" mt="24px" align="center" justify="center">
            <Field
              name="files"
              validate={() => {
                if (action != "edit") {
                  if (!files[0]) {
                    return "Please upload an image file";
                  }
                }
              }}
            >
              {({ field, form }) => {
                return (
                  <FormControl
                    id="files"
                    isInvalid={!!(form.touched.files && files.length == 0)}
                  >
                    <Upload
                      minH={{
                        base: "auto",
                      }}
                      onSelected={(selectedFiles) => {
                        setFiles(selectedFiles);
                      }}
                      multiple={false}
                      selectedFileListTitles="Selected Image"
                      files={files}
                      title={
                        NewsData?.imageUrl || files.length > 0
                          ? "Change Image"
                          : "Upload Image"
                      }
                      description="Only image files are allowed"
                      fileTypes={ImageUploadFileTypes}
                    ></Upload>
                    <FormErrorMessage>{form.errors.files}</FormErrorMessage>
                  </FormControl>
                );
              }}
            </Field>
          </Flex>
        )}

        {files.map((file, i) => (
          <Flex key={i} justify="center" align="center">
            <AuthImage width="400px" src={file} isNotUploaded />
          </Flex>
        ))}

        {NewsData?.imageUrl && !files[0] && (
          <Flex px="25px" mt="24px" align="center" justify="center">
            <AuthImage width="400px" src={NewsData.imageUrl} />
          </Flex>
        )}
      </FormContainer>
    )
  );
}
