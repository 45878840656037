import React, { useEffect, useState, useRef } from "react";

import {
    Flex,
    Box,
    useColorModeValue,
    HStack,
    FormControl,
    FormLabel,
    FormHelperText,
    Button,
    CheckboxGroup,
    Stack,
    Checkbox,
    InputGroup,
    InputLeftAddon,
    Spacer,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useRadioGroup,
    SimpleGrid,
    Text
} from "@chakra-ui/react";

import {
    OrderStatusOptions,
    OrderStatusSelectFilterOptions,
} from "utils/Constants";

import ReactSelect from "components/admin/DefaultStyleReactSelect"
import RadioCard from "components/radio/RadioCard";
import SearchBar from 'components/admin/SearchBar';
import moment from "moment";
import RangeDatepicker from "components/admin/DefaultStyleRangeDatepicker"

export default function FilterBar(props) {
    const { data, setData } = props;

    // states for filtering
    const [typedInProductName, setTypedinProductName] = useState("");
    const productNameRef = useRef(null);
    const [typedInOrderPerson, setTypedInOrderPerson] = useState("");
    const orderPersonRef = useRef(null);
    const [typedInPurchaseOrder, setTypedInPurchaseOrder] = useState("");
    const purchaseOrderRef = useRef(null);

    const [isfilterDeliveryDates, setIsFilterDeliveryDates] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);

    const [typedInQty, setTypedInQty] = useState(0);
    const [typedInQty2, setTypedInQty2] = useState(0);
    const [selectedQtyType, setSelectedQtyType] = useState("lt");
    const [isFilterQty, setIsFilterQty] = useState(false);

    

    const [selectedStatuses, setSelectedStatuses] = useState([]);
    //

    const { getRootProps, getRadioProps } = useRadioGroup({
        defaultValue: selectedQtyType,
        onChange: setSelectedQtyType
    })

    const radioGroupProps = getRootProps();

    const menuBg = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function resetForm() {
        setTypedinProductName("")
        productNameRef.current?.clear();
        orderPersonRef.current?.clear();
        purchaseOrderRef.current?.clear();

        setIsFilterDeliveryDates(false)

        setTypedInQty(0)
        setTypedInQty2(0)
        setSelectedQtyType("lt")
        setIsFilterQty(false)

        setSelectedStatuses([])
    }

    //// filter funtions
    function filter() {
        const filtered = data.filter(order =>
            filterProductName(order)
            && filterPurchaseOder(order)
            && filterOrderPerson(order)
            && filterQty(order)
            && filterStatuses(order)
            && filterDeliveryDates(order)
        )

        setData(filtered)
    }

    //order name
    function filterProductName(order) {
        return order.productName.toLocaleLowerCase().includes(typedInProductName.toLocaleLowerCase());
    }

    //purchase order
    function filterPurchaseOder(order) {
        return order.purchaseOrder.toLocaleLowerCase().includes(typedInPurchaseOrder.toLocaleLowerCase());
    }

    //order person
    function filterOrderPerson(order) {
        return order.orderPerson.toLocaleLowerCase().includes(typedInOrderPerson.toLocaleLowerCase());
    }

    //qty
    function filterQty(order) {
        if (isFilterQty) {
            switch (selectedQtyType) {
                case "lt":
                    return order.quantity < typedInQty
                case "gt":
                    return order.quantity > typedInQty
                case "eq":
                    return order.quantity == typedInQty
                case "bw":
                    const smallerVal = typedInQty < typedInQty2 ? typedInQty : typedInQty2
                    const biggerVal = typedInQty > typedInQty2 ? typedInQty : typedInQty2
                    return order.quantity >= smallerVal && order.quantity <= biggerVal
                default:
                    return true;
            }
        }
        return true;
    }

    //status
    function filterStatuses(order) {
        if (selectedStatuses.length > 0) {
            return selectedStatuses.some(item => item.value == order.status)
        }
        return true;
    }
    
    //delivery
    function filterDeliveryDates(order) {
        if (isfilterDeliveryDates) {
            return moment(order.deliveryDate).isSameOrAfter(selectedDates[0], 'day') &&
                moment(order.deliveryDate).isSameOrBefore(selectedDates[1], 'day')
        }
        return true;
    }

    //

    return (
        <form>
            <Box
                w={{ sm: "100%", lg: "auto" }}
                bg={menuBg}
                p="20px"
                borderRadius="10px"
                boxShadow={shadow}
                mb="10px"
            >
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 4 }} gap='10px' mb='10px'>

                    <FormControl my="10px">
                        <FormLabel>Product Name</FormLabel>
                        <SearchBar
                            ref={productNameRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setTypedinProductName}
                        />
                    </FormControl>

                    
                    <FormControl my="10px">
                        <FormLabel>Order Person</FormLabel>
                        <SearchBar
                            ref={orderPersonRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setTypedInOrderPerson}
                        />
                    </FormControl>

                    
                    <FormControl my="10px">
                        <FormLabel>Purchase Order</FormLabel>
                        <SearchBar
                            ref={purchaseOrderRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setTypedInPurchaseOrder}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel htmlFor="status">Filter by Statuses</FormLabel>
                        <ReactSelect
                            placeholder="Select statuses"
                            isClearable
                            isMulti
                            options={OrderStatusOptions}
                            filterOption={OrderStatusSelectFilterOptions}
                            value={selectedStatuses}
                            onChange={setSelectedStatuses}
                        />
                    </FormControl>

                    <FormControl my="10px">
                        <FormLabel>Quantity</FormLabel>
                        {isFilterQty && (
                            <InputGroup gap="unset" flexWrap={{ base: "wrap", md: "nowrap" }}>
                                <InputLeftAddon px="0">
                                    <HStack {...radioGroupProps}>
                                        <RadioCard {...getRadioProps({ value: 'lt' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >&lt;</RadioCard>
                                        <RadioCard {...getRadioProps({ value: 'gt' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >&gt;</RadioCard>
                                        <RadioCard {...getRadioProps({ value: 'eq' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >=</RadioCard>
                                        <RadioCard {...getRadioProps({ value: 'bw' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >b/w</RadioCard>
                                    </HStack>
                                </InputLeftAddon>
                                <NumberInput
                                    value={typedInQty}
                                    onChange={(v) => setTypedInQty(Number(v))}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                {
                                    selectedQtyType === "bw" && (<NumberInput
                                        value={typedInQty2}
                                        onChange={(v) => setTypedInQty2(Number(v))}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>)
                                }
                            </InputGroup>
                        )}

                        <FormHelperText>
                            <Checkbox isChecked={isFilterQty} onChange={e => setIsFilterQty(e.target.checked)}>Search Quantity</Checkbox>
                        </FormHelperText>
                    </FormControl>



                    <FormControl>
                        <FormLabel>Order Delivery Dates Between</FormLabel>
                        {isfilterDeliveryDates && (<RangeDatepicker
                            selectedDates={selectedDates}
                            onDateChange={setSelectedDates}
                        />)}
                        <FormHelperText>
                            <Checkbox isChecked={isfilterDeliveryDates} onChange={e => setIsFilterDeliveryDates(e.target.checked)}>Search Delivery Dates</Checkbox>
                        </FormHelperText>
                    </FormControl>
                </SimpleGrid>


                <Flex
                    justifyContent="end"
                    alignItems="center"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                >
                    <Spacer />
                    <Button variant="outline" colorScheme='blue' px="30px" onClick={filter}>Search</Button>
                    <Button variant="outline" colorScheme='yellow' px="30px" type="reset" onClick={resetForm}>Clear</Button>
                </Flex>
            </Box>
        </form>
    )
}