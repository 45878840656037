import React, { useState, useEffect, useContext } from "react";

import {
  Box,
  Text,
  Flex,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

import { MdAttachFile } from "react-icons/md";

import moment from "moment";

import Card from "components/card/Card";
import Information from "components/admin/Information";

import UpdateLicenseModal from "./components/UpdateLicenseModal";

import {
  MomentFormatDateOnly,
  MomentFormatWithTimeZone,
  LicenseStatusTextWithIcons
} from "utils/Constants";

import { getOwnOrganisation } from "apis/organisation";

import { UserContext } from "App"
import { getUserData } from "apis/auth.js";
import AuthLink from "components/auth/AuthLink";

export default function OrganisationInfo() {
  const [_, setUserContext] = useContext(UserContext);

  const [organisation, setOrganisation] = useState(null);
  const FileURL = `${process.env.REACT_APP_API_URL}/file`;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardShadow = useColorModeValue(
    "0px 20px 40px rgba(112, 144, 176, 0.2)",
    "unset"
  );

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getOwnOrganisation()
      .then((res) => setOrganisation(res.data))
      .then((err) => console.log(err));
  }

  function refreshDataAfterLicenseUpdate() {
    getData();
    getUserData()
      .then(res => setUserContext(res))
      .catch(err => console.log(err))
  }

  return (
    organisation && (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "visible" }}
          overflowY={{ sm: "scroll", lg: "visible" }}
        >
          <Flex px="24px" my="24px" flexDirection={"column"}>
            <Text
              color={textColor}
              fontWeight="bold"
              fontSize="2xl"
              mt="10px"
              mb="4px"
            >
              General Details
            </Text>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
              <Information
                size="lg"
                boxShadow={cardShadow}
                title="Name"
                value={organisation.name}
              />
              <Information
                size="lg"
                boxShadow={cardShadow}
                title="Contact Number"
                value={organisation.contactNumber}
              />
              <Information
                size="lg"
                boxShadow={cardShadow}
                title="Email"
                value={organisation.email}
              />
              <Information
                size="lg"
                boxShadow={cardShadow}
                title="Address"
                value={organisation.address}
              />
            </SimpleGrid>
            <Text
              color={textColor}
              fontWeight="bold"
              fontSize="2xl"
              mt="10px"
              mb="4px"
            >
              Current License
            </Text>
            {organisation.currentLicense ? (
              <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                <Information
                  size="lg"
                  boxShadow={cardShadow}
                  title="Expiry Date"
                  value={moment(organisation.currentLicense.expiryDate).format(
                    MomentFormatDateOnly
                  )}
                />
                <Information
                  size="lg"
                  boxShadow={cardShadow}
                  title="License file"
                  value={
                    <AuthLink
                      href={organisation.currentLicense.fileInfo.name}
                      icon={MdAttachFile}
                      color="blue.400"
                      isExternal>
                      {organisation.currentLicense.fileInfo.originalName}
                    </AuthLink>
                  }
                />
                <Information
                  size="lg"
                  boxShadow={cardShadow}
                  title="Uploaded On"
                  value={moment(organisation.currentLicense.uploadedOn).format(
                    MomentFormatWithTimeZone
                  )}
                />
                <Information
                  size="lg"
                  boxShadow={cardShadow}
                  title="Approval Status"
                  value={
                    LicenseStatusTextWithIcons[organisation.currentLicense.status]
                  }
                />
              </SimpleGrid>
            ) : (
              <Information
                size="lg"
                boxShadow={cardShadow}
                value="No Uploaded License"
              />
            )}
          </Flex>

          <Flex justify="center">
            <UpdateLicenseModal
              colorScheme="brand"
              rounded="md"
              afterUpdateFunc={refreshDataAfterLicenseUpdate}
            >
              Update License
            </UpdateLicenseModal>
          </Flex>
        </Card>
      </Box>
    )
  );
}
