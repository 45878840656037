import { React, useEffect, useState } from "react";

import {
  Button,
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  createStandaloneToast,
  useColorModeValue,

} from "@chakra-ui/react";

import { Field } from "formik";
import { useParams, useHistory } from "react-router-dom";
import FormContainer from "components/admin/FormContainer";
import { createProductionTimeSlot, getProductionTimeSlotByID, updateProductionTimeSlot } from "apis/productionTimeSlot";
import { validateEmpty } from "utils/Constants";

export default function TimeSlotForm(props) {
  const toast = createStandaloneToast();
  const { action, id } = useParams();
  const viewOnly = action === "view";
  const history = useHistory();
  const backUrl = "/admin/timeslots";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [timeSlotData, setTimeSlot] = useState({});

  useEffect(() => {
    if (action && id) {
      setTimeSlot(null);
      getProductionTimeSlotByID(id)
        .then((res) => {
          setTimeSlot(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    (!action || timeSlotData) && (
      <FormContainer
        backUrl={backUrl}
        initialValues={
          action ? timeSlotData : { name: ""}
        }
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          if (!action) {
            createProductionTimeSlot(values)
              .then((res) => {
                toast({
                  name: "Successful",
                  description: `Time Slot "${values.name}" has been created`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                actions.setSubmitting(false);
                history.push(backUrl);
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (action === "edit") {
            updateProductionTimeSlot(values.id, values)
              .then((res) => {
                toast({
                  name: "Successful",
                  description: `Time Slot "${values.name}" has been updated`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                actions.setSubmitting(false);
                history.push(backUrl);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }}
      >
        <Flex px="25px" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Time Slot Information
          </Text>
        </Flex>

        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          gap={{ base: "24px", lg: "48px" }}
          px="24px"
          my="24px"
          alignItems={"start"}
        >
          <Field name="name" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="name"
                  isInvalid={form.errors.name && form.touched.name}
                >
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <Input readOnly={viewOnly} placeholder="" {...field} />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </Flex>
      </FormContainer>
    )
  );
}
