export const columnsDataComplex = [
    {
      Header: "Profile Picture",
      accessor: "imageUrl",
      isImage: true,
      altText: "name",
    },
    {
      Header: "Member Name",
      accessor: "name",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Notation",
      accessor: "notation",
    },
    {
      Header: "Actions",
      accessor: "id",
    },
];