import React, { useEffect, useState, useRef} from "react";

import {
    Flex,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    FormHelperText,
    Button,
    Checkbox,
    Spacer,
    SimpleGrid,
    Input
} from "@chakra-ui/react";
import SearchBar from 'components/admin/SearchBar';
export default function FilterBar(props) {
    const { data, setData } = props;

    // states for filtering
    const [careerSearchInput, setCareerSearchInput] = useState();
    const careerSearchRef = useRef(null);
    const [hasLinkedIn, setHasLinkedIn] = useState(false);
    const [noLinkedIn, setNoLinkedIn] = useState(false);
    const [hasIndeed, setHasIndeed] = useState(false);
    const [noIndeed, setNoIndeed] = useState(false);
    const [hasSeek, setHasSeek] = useState(false);
    const [notSeek, setNoSeek] = useState(false);
    const [hasCareerOne, setHasCareerOne] = useState(false);
    const [noCareerOne, setNoCareerOne] = useState(false);
    //

    const menuBg = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function resetForm() {
        setCareerSearchInput()
        careerSearchRef.current?.clear();
        setHasLinkedIn(false)
        setHasIndeed(false)
        setHasSeek(false)
        setHasCareerOne(false)
        setNoLinkedIn(false)
        setNoIndeed(false)
        setNoSeek(false)
        setNoCareerOne(false)
    }

    //// filter funtions
    function filter() {
        const filtered = data.filter(career =>
            filterCareers(career)
            &&filterLinkedIn(career)
            &&filterSeek(career)
            &&filterIndeed(career)
            &&filterCareerOne(career)
        )

        setData(filtered)
    }

    function filterCareers(career) {
        if (careerSearchInput){
            if (!career.title.toLowerCase().includes(careerSearchInput.toLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterLinkedIn(career){
        if (hasLinkedIn == true && !career.links.linkedIn){
            return false;
        }
        if (noLinkedIn == true && career.links.linkedIn){
            return false;
        }
        return true;
    }

    function filterIndeed(career){
        if (hasIndeed == true && !career.links.indeed){
            return false;
        }
        if (noIndeed == true && career.links.indeed){
            return false;
        }
        return true;
    }

    function filterSeek(career){
        if (hasSeek == true && !career.links.seek){
            return false;
        }
        if (notSeek == true && career.links.seek){
            return false;
        }
        return true;
    }

    function filterCareerOne(career){
        if (hasCareerOne == true && !career.links.careerOne){
            return false;
        }
        if (noCareerOne == true && career.links.careerOne){
            return false;
        }
        return true;
    }

    ////

    return (
        <form>
            <Box
                w={{ sm: "100%", lg: "auto" }}
                bg={menuBg}
                p="20px"
                borderRadius="10px"
                boxShadow={shadow}
                mb="10px"
            >
                <SimpleGrid columns={{ base: 1 }} gap='10px' mb='10px'>
                    <FormControl>
                        <FormLabel>Filter By Title</FormLabel>
                        <SearchBar
                            ref={careerSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setCareerSearchInput}
                        />
                    </FormControl>
                </SimpleGrid>


                <Flex
                    justifyContent="end"
                    alignItems="center"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                >
                    <FormControl maxW={{ base: "100%", lg: "700px" }} my={{ base: "10px", lg: "unset" }}>
                    <Flex 
                        justifyContent={{ base: "center", md: "start" }}
                        flexWrap={{ base: "wrap", md: "nowrap" }}
                        gap="36px"
                    >
                            <Box>
                                <FormLabel textAlign="center" w="100%">Has LinkedIn Link</FormLabel>
                                <FormHelperText>
                                    <Flex gap="24px" justify="center">
                                        <Checkbox colorScheme='green' isChecked={hasLinkedIn} onChange={(e) => {setHasLinkedIn(e.target.checked); setNoLinkedIn(false)}}>
                                            Yes
                                        </Checkbox>

                                        <Checkbox colorScheme='red' isChecked={noLinkedIn} onChange={(e) => {setNoLinkedIn(e.target.checked); setHasLinkedIn(false)}}>
                                            No
                                        </Checkbox>
                                    </Flex>
                                </FormHelperText>
                            </Box>

                            <Box>
                                <FormLabel textAlign="center" w="100%">Has Seek Link</FormLabel>
                                <FormHelperText>
                                    <Flex gap="24px" justify="center">
                                        <Checkbox colorScheme='green' isChecked={hasSeek} onChange={(e) => {setHasSeek(e.target.checked); setNoSeek(false)}}>
                                            Yes
                                        </Checkbox>

                                        <Checkbox colorScheme='red' isChecked={notSeek} onChange={(e) => {setNoSeek(e.target.checked); setHasSeek(false)}}>
                                            No
                                        </Checkbox>
                                    </Flex>
                                </FormHelperText>
                            </Box>

                            <Box>
                                <FormLabel textAlign="center" w="100%">Has Indeed Link</FormLabel>
                                <FormHelperText>
                                    <Flex gap="24px" justify="center">
                                        <Checkbox colorScheme='green' isChecked={hasIndeed} onChange={(e) => {setHasIndeed(e.target.checked); setNoIndeed(false)}}>
                                            Yes
                                        </Checkbox>

                                        <Checkbox colorScheme='red' isChecked={noIndeed} onChange={(e) => {setNoIndeed(e.target.checked); setHasIndeed(false)}}>
                                            No
                                        </Checkbox>
                                    </Flex>
                                </FormHelperText>
                            </Box>

                            <Box>
                                <FormLabel textAlign="center" w="100%">Has CareerOne Link</FormLabel>
                                <FormHelperText>
                                    <Flex gap="24px" justify="center">
                                        <Checkbox colorScheme='green' isChecked={hasCareerOne} onChange={(e) => {setHasCareerOne(e.target.checked); setNoCareerOne(false)}}>
                                            Yes
                                        </Checkbox>

                                        <Checkbox colorScheme='red' isChecked={noCareerOne} onChange={(e) => {setNoCareerOne(e.target.checked); setHasCareerOne(false)}}>
                                            No
                                        </Checkbox>
                                    </Flex>
                                </FormHelperText>
                            </Box>
                        </Flex>
                    </FormControl>
                    <Spacer />

                    <Button variant="outline" colorScheme='blue' px="30px" onClick={filter}>Search</Button>
                    <Button variant="outline" colorScheme='yellow' px="30px" type="reset" onClick={resetForm}>Clear</Button>
                </Flex>
            </Box>
        </form>
    )
}