import { React, useEffect, useState } from "react";

import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  createStandaloneToast,
  useColorModeValue,
} from "@chakra-ui/react";

import { Field } from "formik";
import { useParams, useHistory } from "react-router-dom";
import FormContainer from "components/admin/FormContainer";
import { createLink } from "apis/link";
import { updateLink } from "apis/link";
import { getLink } from "apis/link";
import { validateEmpty } from "utils/Constants";

export default function LinkForm(props) {
  const toast = createStandaloneToast();
  const { action, id } = useParams();
  const viewOnly = action === "view";
  const history = useHistory();
  const backUrl = "/admin/links";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [LinkData, setLink] = useState(null);

  useEffect(() => {
    if (action && id) {
      getLink(id)
        .then((res) => {
          setLink(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    (!action || LinkData) && (
      <FormContainer
        backUrl={backUrl}
        initialValues={action ? LinkData : { title: "", url: "" }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          if (!action) {
            createLink(values)
              .then(() => {
                toast({
                  title: "Successful",
                  description: `Link "${values.title}" has been created`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                actions.setSubmitting(false);
                history.push(backUrl);
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (action === "edit") {
            updateLink(values.id, values)
              .then(() => {
                toast({
                  title: "Successful",
                  description: `Link "${values.title}" has been updated`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                actions.setSubmitting(false);
                history.push(backUrl);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            toast({
              title: "Invalid submit action.",
              description: "An error has occurred, please contact developers.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            console.log("action not specified", action);
          }
        }}
      >
        <Flex px="25px" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Link Information
          </Text>
        </Flex>

        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          gap={{ base: "24px", lg: "48px" }}
          px="24px"
          my="24px"
          alignItems={"start"}
        >
          <Field name="title" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="title"
                  isInvalid={form.errors.title && form.touched.title}
                >
                  <FormLabel htmlFor="title">Link Text</FormLabel>
                  <Input readOnly={viewOnly} placeholder="Text being displayed for the link, e.g., Google" {...field} />
                  <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>

          <Field name="url" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="url"
                  isInvalid={form.errors.url && form.touched.url}
                >
                  <FormLabel htmlFor="url">Reference Link</FormLabel>
                  <Input readOnly={viewOnly} placeholder="The link itself, e.g., https://www.google.com" {...field} />
                  <FormErrorMessage>{form.errors.url}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </Flex>
      </FormContainer>
    )
  );
}
