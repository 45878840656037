import { React, useEffect, useState } from "react";
import {
  Flex,
  Text,
  useColorModeValue,
  createStandaloneToast,
  SimpleGrid,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import moment from "moment";

import Information from "components/admin/Information";
import FormContainer from "components/admin/FormContainer";
import ActionDialog from "components/admin/ActionDialogBtn";

import { MomentFormatWithTimeZone } from "utils/Constants";
import { getContact, updateContact } from "apis/contact";

export default function OrderView(props) {
  const { id } = useParams();
  const backUrl = "/admin/contacts";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [data, setData] = useState(null);
  const toast = createStandaloneToast();

  const cardShadow = useColorModeValue(
    "0px 20px 40px rgba(112, 144, 176, 0.2)",
    "unset"
  );

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, []);

  function getData() {
    getContact(id)
      .then((res) => setData(res.data))
      .catch((err) => {
        console.log(err);
      });
  }

  function updateSeenStatus() {
    data.isSeen = !data.isSeen;
    updateContact(data.id, data)
      .then(() => {
        getData();
        const toastMsg = `Status updated to ${
          data.isSeen ? '"Viewed"' : '"Not viewed"'
        }`;
        toast({
          title: "Successful",
          description: toastMsg,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    data && (
      <FormContainer backUrl={backUrl}>
        <Flex px="25px" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Details
          </Text>
        </Flex>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="20px">
          <Information
            boxShadow={cardShadow}
            title="Full name"
            value={data.fullName}
          />
          <Information
            boxShadow={cardShadow}
            title="Email"
            value={data.email}
          />
          <Information
            boxShadow={cardShadow}
            title="Phone Number"
            value={data.phone}
          />
          <Information
            boxShadow={cardShadow}
            title="Location"
            value={data.location.name}
          />

          <Information
            boxShadow={cardShadow}
            title="Contacted on"
            value={moment(data.contactedDateTime).format(
              MomentFormatWithTimeZone
            )}
          />

          <Information
            boxShadow={cardShadow}
            title="Already Viewed?"
            value={data.isSeen ? "Yes" : "No"}
          />
        </SimpleGrid>

        <Text
          color={textColor}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Message from the applicant
        </Text>

        <SimpleGrid columns={1} gap="20px">
          <Information boxShadow={cardShadow} value={data.message} />
        </SimpleGrid>

        <Flex justify="center" pt="25px">
          <ActionDialog
            btnBg="green.500"
            btnText={`Mark as ${data.isSeen ? "not viewed" : "viewed"}`}
            dialogHeader={`Mark as ${data.isSeen ? "not viewed" : "viewed"}`}
            dialogText={`This will be marked as  ${
              data.isSeen ? "not viewed" : "viewed"
            }, continue?`}
            confirmBtnText="Confirm"
            colorScheme="brand"
            onConfirm={() => updateSeenStatus()}
          />
        </Flex>
      </FormContainer>
    )
  );
}
