import React, { useEffect, useState, useRef } from "react";

import {
    Flex,
    Box,
    useColorModeValue,
    HStack,
    FormControl,
    FormLabel,
    FormHelperText,
    Button,
    CheckboxGroup,
    Stack,
    Checkbox,
    InputGroup,
    InputLeftAddon,
    Spacer,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    useRadioGroup,
    SimpleGrid,
    Text
} from "@chakra-ui/react";

import {
    getSelectOptionObj,
    DaysOfWeekSelectOptions
} from "utils/Constants";

import ReactSelect from "components/admin/DefaultStyleReactSelect"
import RadioCard from "components/radio/RadioCard";
import SearchBar from 'components/admin/SearchBar';
import TimeRangePicker from "components/admin/TimeRangePicker";

//API import
import { getProductSite } from "apis/productSite";
import { getOrganisations } from "apis/organisation";
import { getProductionTimeSlots } from "apis/productionTimeSlot";

export default function FilterBar(props) {
    const { data, setData } = props;
    const [productionSites, setProductionSites] = useState([]);
    const [organisations, setOrganisations] = useState([]);
    const [prodTimeSlots, setProdTimeSlots] = useState([]);

    // states for filtering
    const [typedInProductName, setTypedInProductName] = useState("");
    const productSearchRef = useRef(null);
    const [selectedProductionSites, setSelectedProductionSites] = useState([]);
    const [selectedOrganisations, setSelectedOrganisations] = useState([]);

    const [typedInQty, setTypedInQty] = useState(0);
    const [typedInQty2, setTypedInQty2] = useState(0);
    const [selectedQtyType, setSelectedQtyType] = useState("lt");
    const [isFilterQty, setIsFilterQty] = useState(false);

    const [isLimited, setIsLimited] = useState(false);
    const [notLimited, setNotLimited] = useState(false);
    const [isTakeEntireSlot, setTakeEntireSlot] = useState(false);
    const [notTakeEntireSlot, setNotTakeEntireSlot] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [notDisabled, setNotDisabled] = useState(false);

    const [selectedProdDays, setSelectedProdDays] = useState([]);
    const [selectedProdTimeSlots, setSelctedProdTimeSlots] = useState([]);

    const [selectedPickUpTimes, setSelectedPickUpTimes] = useState(["", ""]);
    const [selectedDeliveryTimes, setSelectedDeliveryTimes] = useState(["", ""]);
    //

    const { getRootProps, getRadioProps } = useRadioGroup({
        defaultValue: selectedQtyType,
        onChange: setSelectedQtyType
    })

    const radioGroupProps = getRootProps();

    const menuBg = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function resetForm() {
        setTypedInProductName("")
        productSearchRef.current?.clear();

        setSelectedProductionSites([])
        setSelectedOrganisations([])

        setTypedInQty(0)
        setTypedInQty2(0)
        setSelectedQtyType("lt")
        setIsFilterQty(false)

        setIsLimited(false)
        setNotLimited(false)
        setTakeEntireSlot(false)
        setNotTakeEntireSlot(false)
        setIsDisabled(false)
        setNotDisabled(false)

        setSelectedProdDays([])
        setSelctedProdTimeSlots([])

        setSelectedPickUpTimes(["", ""])
        setSelectedDeliveryTimes(["", ""])
    }

    //// filter funtions
    function filter() {
        const filtered = data.filter(product =>
            filterProductName(product)
            && filterQtyLimit(product)
            && filterIsLimited(product)
            && filterTakeEntireSlot(product)
            && filterDisabled(product)
            && filterRules(product.rules)
        )
        setData(filtered)
    }

    //product name
    function filterProductName(product) {
        return product.name.trim().toLocaleLowerCase().includes(typedInProductName.toLocaleLowerCase());
    }

    //qty
    function filterQtyLimit(product) {
        if (isFilterQty) {
            switch (selectedQtyType) {
                case "lt":
                    return product.quantityLimit < typedInQty
                case "gt":
                    return product.quantityLimit > typedInQty
                case "eq":
                    return product.quantityLimit == typedInQty
                case "bw":
                    const smallerVal = typedInQty < typedInQty2 ? typedInQty : typedInQty2
                    const biggerVal = typedInQty > typedInQty2 ? typedInQty : typedInQty2
                    return product.quantityLimit >= smallerVal && product.quantityLimit <= biggerVal
                default:
                    return true;
            }
        }
        return true;
    }

    //Limited
    function filterIsLimited(product) {
        if (isLimited && !product.isLimited) {
            return false;
        }
        if (notLimited && product.isLimited) {
            return false;
        }
        return true;
    }

    //EntireSlot
    function filterTakeEntireSlot(product) {
        if (isTakeEntireSlot && !product.takesUpEntireTimeSlot) {
            return false;
        }
        if (notTakeEntireSlot && product.takesUpEntireTimeSlot) {
            return false;
        }
        return true;
    }

    //Product Disabled
    function filterDisabled(product) {
        if (isDisabled == true && !product.isDisabled) {
            return false;
        }
        if (notDisabled == true && product.isDisabled) {
            return false;
        }
        return true;
    }


    //check if each array should be filtered
    //Rules array
    function shouldFilterRules() {
        return selectedProductionSites.length > 0 || selectedOrganisations.length > 0 || shouldFilterRuleDayTimes()

    }

    //DayTimes array in Rule
    function shouldFilterRuleDayTimes() {
        return selectedProdDays.length > 0 || shouldFilterTimesInRuleDayTimes()
    }

    //Times array in DayTime
    function shouldFilterTimesInRuleDayTimes() {
        return selectedProdTimeSlots.length > 0
            || selectedPickUpTimes.some(e => e != "")
            || selectedDeliveryTimes.some(e => e != "")
    }

    //rules
    function filterRules(rules) {
        if (shouldFilterRules()) {
            return rules.some(filterEachRule)
        }
        return true;
    }


    //filter each rule
    function filterEachRule(rule) {
        return filterProductionSites(rule)
            && filterOrganisations(rule)
            && filterRulesDayTimes(rule.dayTimes);
    }

    //production sites
    function filterProductionSites(rule) {
        if (selectedProductionSites.length > 0) {
            return selectedProductionSites.some(item => item.value == rule.productionSiteId)
        }
        return true;
    }

    //organisations
    function filterOrganisations(rule) {
        if (selectedOrganisations.length > 0) {
            return selectedOrganisations.some(item => item.value == rule.organisationId)
        }
        return true;
    }

    //rule's daytime
    function filterRulesDayTimes(dayTimes) {
        if (shouldFilterRuleDayTimes()) {
            return dayTimes.some(filterEachRulesDayTime)
        }
        return true;
    }

    function filterEachRulesDayTime(dayTime) {
        return filterProductionDays(dayTime)
            && filterTimesInDayTime(dayTime.times)
    }

    //Product day
    function filterProductionDays(dayTime) {
        if (selectedProdDays.length > 0) {
            return selectedProdDays.some(item => item.value == dayTime.dayOfWeek)
        }
        return true;
    }

    function filterTimesInDayTime(times) {
        if (shouldFilterTimesInRuleDayTimes()) {
            return times.some(filterTimesArray)
        }
        return true;
    }

    //daytime's time arrays
    function filterTimesArray(time) {
        return filterTimeSlots(time)
            && filterPickUpTimes(time)
            && filterDeliveryTimes(time)
    }

    //time slot
    function filterTimeSlots(time) {
        if (selectedProdTimeSlots.length > 0) {
            return selectedProdTimeSlots.some(item => item.value == time.timeSlotId);
        }
        return true;
    }

    //pick up time
    function filterPickUpTimes(time) {
        const time1 = selectedPickUpTimes[0]
        const time2 = selectedPickUpTimes[1]
        if (time1 != '') {
            if (time2 != '') {
                const smallerVal = time1 < time2 ? time1 : time2
                const biggerVal = time1 > time2 ? time1 : time2
                return time.pickUpTime >= smallerVal && time.pickUpTime <= biggerVal
            } else {

                return time.pickUpTime == time1;
            }
        } else if (time2 != '') {
            return time.pickUpTime == time2;
        }
        return true;
    }

    //delivery
    function filterDeliveryTimes(time) {
        const time1 = selectedDeliveryTimes[0]
        const time2 = selectedDeliveryTimes[1]
        if (time1 != '') {
            if (time2 != '') {
                const smallerVal = time1 < time2 ? time1 : time2
                const biggerVal = time1 > time2 ? time1 : time2
                return time.deliveryTime >= smallerVal && time.deliveryTime <= biggerVal
            } else {
                return time.deliveryTime == time1;
            }
        } else if (time2 != '') {
            return time.deliveryTime == time2;
        }
        return true;
    }


    ////

    useEffect(() => {
        getOrganisations().then((res) => setOrganisations(res.data)).catch(err => console.log(err));
        getProductSite().then((res) => setProductionSites(res.data)).catch(err => console.log(err));
        getProductionTimeSlots().then(res => setProdTimeSlots(res.data)).catch(err => console.log(err));
    }, [])

    return (
        <form>
            <Box
                w={{ sm: "100%", lg: "auto" }}
                bg={menuBg}
                p="20px"
                borderRadius="10px"
                boxShadow={shadow}
                mb="10px"
            >
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 4 }} gap='10px' mb='10px'>

                    <FormControl my="10px">
                        <FormLabel>Product Name</FormLabel>
                        <SearchBar
                            ref={productSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setTypedInProductName}
                        />
                    </FormControl>

                    <FormControl my="10px">
                        <FormLabel>Production Site</FormLabel>
                        <ReactSelect
                            placeholder="Select production sites"
                            isClearable
                            isMulti
                            options={productionSites.map(getSelectOptionObj)}
                            value={selectedProductionSites}
                            onChange={setSelectedProductionSites}
                        />
                    </FormControl>

                    <FormControl my="10px">
                        <FormLabel>Organisation</FormLabel>
                        <ReactSelect
                            placeholder="Select organisations"
                            isClearable
                            isMulti
                            options={organisations.map(getSelectOptionObj)}
                            value={selectedOrganisations}
                            onChange={setSelectedOrganisations}
                        />
                    </FormControl>

                    <Flex my="10px" gap={{ base: "10px", md: "unset" }} flexDirection={{ base: "column", md: "row" }}>
                        <FormControl>
                            <Flex alignItems="center" flexDirection="column">
                                <FormLabel textAlign="center">Quantity Limited</FormLabel>
                                <CheckboxGroup>
                                    <Stack direction="row">
                                        <Checkbox colorScheme='green' isChecked={isLimited} onChange={(e) => { setIsLimited(e.target.checked); setNotLimited(false) }}>
                                            Yes
                                        </Checkbox>

                                        <Checkbox colorScheme='red' isChecked={notLimited} onChange={(e) => { setNotLimited(e.target.checked); setIsLimited(false) }}>
                                            No
                                        </Checkbox>
                                    </Stack>
                                </CheckboxGroup>
                            </Flex>
                        </FormControl>

                        <FormControl>
                            <Flex alignItems="center" flexDirection="column">
                                <FormLabel textAlign="center">Takes Entire Time Slot</FormLabel>
                                <CheckboxGroup>
                                    <Stack direction="row">
                                        <Checkbox colorScheme='green' isChecked={isTakeEntireSlot} onChange={(e) => { setTakeEntireSlot(e.target.checked); setNotTakeEntireSlot(false) }}>
                                            Yes
                                        </Checkbox>
                                        <Checkbox colorScheme='red' isChecked={notTakeEntireSlot} onChange={(e) => { setNotTakeEntireSlot(e.target.checked); setTakeEntireSlot(false) }}>
                                            No
                                        </Checkbox>
                                    </Stack>
                                </CheckboxGroup>
                            </Flex>
                        </FormControl>
                    </Flex>

                    <FormControl my="10px">
                        <FormLabel>Quantity</FormLabel>
                        {isFilterQty && (
                            <InputGroup gap="unset" flexWrap={{ base: "wrap", md: "nowrap" }}>
                                <InputLeftAddon px="0">
                                    <HStack {...radioGroupProps}>
                                        <RadioCard {...getRadioProps({ value: 'lt' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >&lt;</RadioCard>
                                        <RadioCard {...getRadioProps({ value: 'gt' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >&gt;</RadioCard>
                                        <RadioCard {...getRadioProps({ value: 'eq' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >=</RadioCard>
                                        <RadioCard {...getRadioProps({ value: 'bw' })} m='unset' py='5px' px={{ base: '10px', lg: '8px' }} >b/w</RadioCard>
                                    </HStack>
                                </InputLeftAddon>
                                <NumberInput
                                    value={typedInQty}
                                    onChange={(v) => setTypedInQty(Number(v))}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                                {
                                    selectedQtyType === "bw" && (<NumberInput
                                        value={typedInQty2}
                                        onChange={(v) => setTypedInQty2(Number(v))}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>)
                                }
                            </InputGroup>
                        )}

                        <FormHelperText>
                            <Checkbox isChecked={isFilterQty} onChange={e => setIsFilterQty(e.target.checked)}>Search Quantity</Checkbox>
                        </FormHelperText>
                    </FormControl>

                    <FormControl my="10px">
                        <FormLabel>Production Day</FormLabel>
                        <ReactSelect
                            placeholder="Select a date"
                            isClearable
                            isMulti
                            value={selectedProdDays}
                            options={DaysOfWeekSelectOptions}
                            onChange={setSelectedProdDays}
                        />
                    </FormControl>

                    <FormControl my="10px">
                        <FormLabel>Time Slot</FormLabel>
                        <ReactSelect
                            placeholder="Select time slot"
                            isClearable
                            isMulti
                            options={prodTimeSlots.map(getSelectOptionObj)}
                            value={selectedProdTimeSlots}
                            onChange={setSelctedProdTimeSlots}
                        />
                    </FormControl>

                    <FormControl my="10px">
                        <FormLabel>Pick up time</FormLabel>
                        <TimeRangePicker
                            flexDirection={{ base: "column", md: "row" }}
                            gap={{ base: "10px", md: "0" }}
                            values={selectedPickUpTimes}
                            onChange={setSelectedPickUpTimes}
                        />
                    </FormControl>

                    <FormControl my="10px">
                        <FormLabel>Delivery time</FormLabel>
                        <TimeRangePicker
                            flexDirection={{ base: "column", md: "row" }}
                            gap={{ base: "10px", md: "0" }}
                            values={selectedDeliveryTimes}
                            onChange={setSelectedDeliveryTimes}
                        />
                    </FormControl>
                </SimpleGrid>


                <Flex
                    justifyContent="end"
                    alignItems="center"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                >
                    <FormControl>
                        <Flex gap="12px" fontSize="medium" >
                            <Text fontWeight="medium" >Product is Disabled</Text>
                            <Checkbox colorScheme='green' textColor="gray.500" isChecked={isDisabled} onChange={(e) => { setIsDisabled(e.target.checked); setNotDisabled(false) }}>
                                Yes
                            </Checkbox>
                            <Checkbox colorScheme='red' textColor="gray.500" isChecked={notDisabled} onChange={(e) => { setNotDisabled(e.target.checked); setIsDisabled(false) }}>
                                No
                            </Checkbox>
                        </Flex>
                    </FormControl>
                    <Spacer />
                    <Button variant="outline" colorScheme='blue' px="30px" onClick={filter}>Search</Button>
                    <Button variant="outline" colorScheme='yellow' px="30px" type="reset" onClick={resetForm}>Clear</Button>
                </Flex>
            </Box>
        </form>
    )
}