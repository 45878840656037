import React from "react";

import { Accordion, Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdHistory,
  MdAddShoppingCart,
  MdLogout,
  MdOutlineSupervisorAccount,
  MdArchive,
  MdLocalHospital,
  MdFactory,
  MdGroup,
  MdNewspaper,
  MdHandshake,
  MdInsertLink,
  MdContacts,
  MdBusinessCenter,
  MdSettings,
  MdWeb,
  MdEditLocationAlt,
} from "react-icons/md";

import { FaHandHoldingMedical } from "react-icons/fa6";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import AdminDashboard from "views/admin/dashboard";
//User
import UserList from "views/admin/user";
import UserForm from "views/admin/user/form";
//Organisation
import Organisation from "views/admin/organisation";
import OrganisationForm from "views/admin/organisation/form";
import OrganisationView from "views/admin/organisation/view";

//Product Time Slot
import TimeSlot from "views/admin/timeslot";
import TimeSlotForm from "views/admin/timeslot/form"

//Product
import Product from "views/admin/product";
import ProductForm from "views/admin/product/form"

// Auth Imports
import SignInCentered from "views/auth/signIn";
// Customer Order Imports
import Order from "views/customer/order/place";
import OrderHistory from "views/customer/order/history";
import OrderView from "views/customer/order/view";
import OrderEdit from "views/customer/order/edit";
// Customer Organisation Licensing Import
import CustomerOrganisationInfo from "views/customer/organisation";
// Order Admin Imports
import OrderList from "views/admin/order";
import OrderForm from "views/admin/order/form";
import AdminOrderView from "views/admin/order/view"
//Production site
import Site from "views/admin/productionSite";
import SiteForm from "views/admin/productionSite/form";
//Website Editor
//Team Member
import TeamMember from "views/admin/teamMember";
import TeamForm from "views/admin/teamMember/form";
//Partnership
import Partnership from "views/admin/partnership";
import PartnershipForm from "views/admin/partnership/form";
//News
import News from "views/admin/news";
import NewsForm from "views/admin/news/form";
//Link
import Link from "views/admin/link";
import LinkForm from "views/admin/link/form";
//Contact Us
import Contact from "views/admin/contact";
import ContactView from "views/admin/contact/view";
//Career
import Career from "views/admin/career";
import CareerForm from "views/admin/career/form";
//Provided Services
import Service from "views/admin/providedServices";
import ServiceForm from "views/admin/providedServices/form";
//Provided Services
import Location from "views/admin/geoLocation";
import LocationForm from "views/admin/geoLocation/form";

const routes = [
  //driver
  {
    name: "Delivery",
    layout: "/delivery",
    path: "/orders",
    icon: <Icon as={MdHistory} width='20px' height='20px' color='inherit' />,
    component: OrderList,
  },
  {
    name: "Order Details",
    layout: "/delivery",
    path: "/order/view/:id",
    icon: <Icon as={MdHistory} width='20px' height='20px' color='inherit' />,
    component: AdminOrderView,
    childComponent: true
  },
  {
    name: "Organisations",
    layout: "/delivery",
    path: "/organisations",
    icon: <Icon as={MdLocalHospital} width='20px' height='20px' color='inherit' />,
    component: Organisation,
  },
  {
    name: "View Organisation",
    layout: "/delivery",
    path: "/organisation/view/:id",
    component: OrganisationView,
    childComponent: true
  },
  //staff
  {
    name: "Order List",
    layout: "/staff",
    path: "/orders",
    icon: <Icon as={MdHistory} width='20px' height='20px' color='inherit' />,
    component: OrderList,
  },
  {
    name: "Order Details",
    layout: "/staff",
    path: "/order/view/:id",
    icon: <Icon as={MdHistory} width='20px' height='20px' color='inherit' />,
    component: AdminOrderView,
    childComponent: true
  },
  {
    name: "Organisations",
    layout: "/staff",
    path: "/organisations",
    icon: <Icon as={MdLocalHospital} width='20px' height='20px' color='inherit' />,
    component: Organisation,
  },
  {
    name: "View Organisation",
    layout: "/staff",
    path: "/organisation/view/:id",
    component: OrganisationView,
    childComponent: true
  },
  //admin
  {
    name: "Admin Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: AdminDashboard,
  },
  //admin order
  {
    name: "Order List",
    layout: "/admin",
    path: "/orders",
    icon: <Icon as={MdHistory} width='20px' height='20px' color='inherit' />,
    component: OrderList,
  },
  {
    name: "Order Details",
    layout: "/admin",
    path: "/order/edit/:id",
    icon: <Icon as={MdHistory} width='20px' height='20px' color='inherit' />,
    component: OrderForm,
    childComponent: true
  },
  {
    name: "Order Details",
    layout: "/admin",
    path: "/order/add",
    icon: <Icon as={MdHistory} width='20px' height='20px' color='inherit' />,
    component: OrderForm,
    childComponent: true
  },
  {
    name: "Order Details",
    layout: "/admin",
    path: "/order/view/:id",
    icon: <Icon as={MdHistory} width='20px' height='20px' color='inherit' />,
    component: AdminOrderView,
    childComponent: true
  },
  //User
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: <Icon as={MdOutlineSupervisorAccount} width='20px' height='20px' color='inherit' />,
    component: UserList,
  },
  {
    name: "User Info",
    layout: "/admin",
    path: "/user/:action/:id",
    component: UserForm,
    childComponent: true
  },
  {
    name: "Create User",
    layout: "/admin",
    path: "/user/create",
    component: UserForm,
    childComponent: true
  },
  //Organisation
  {
    name: "Organisations",
    layout: "/admin",
    path: "/organisations",
    icon: <Icon as={MdLocalHospital} width='20px' height='20px' color='inherit' />,
    component: Organisation,
  },
  {
    name: "Edit Organisation",
    layout: "/admin",
    path: "/organisation/edit/:id",
    component: OrganisationForm,
    childComponent: true
  },
  {
    name: "View Organisation",
    layout: "/admin",
    path: "/organisation/view/:id",
    component: OrganisationView,
    childComponent: true
  },
  {
    name: "Create Organisation",
    layout: "/admin",
    path: "/organisation/create",
    // icon: <Icon as={MdLocalHospital} width='20px' height='20px' color='inherit' />,
    component: OrganisationForm,
    childComponent: true
  },
  //Production Site
  {
    name: "Production Site",
    layout: "/admin",
    path: "/sites",
    icon: <Icon as={MdFactory} width='20px' height='20px' color='inherit' />,
    component: Site,
  },
  {
    name: "Production Site",
    layout: "/admin",
    path: "/site/create",
    icon: <Icon as={MdFactory} width='20px' height='20px' color='inherit' />,
    component: SiteForm,
    childComponent: true
  },
  {
    name: "Production Site",
    layout: "/admin",
    path: "/site/:action/:id",
    icon: <Icon as={MdFactory} width='20px' height='20px' color='inherit' />,
    component: SiteForm,
    childComponent: true
  },
  //Time Slot
  {
    name: "Time Slot",
    layout: "/admin",
    path: "/timeslots",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: TimeSlot,
  },
  {
    name: "Create Time Slot",
    layout: "/admin",
    path: "/timeslot/create",
    component: TimeSlotForm,
    childComponent: true
  },
  {
    name: "View Time Slot",
    layout: "/admin",
    path: "/timeslot/:action/:id",
    component: TimeSlotForm,
    childComponent: true
  },
  //Product
  {
    name: "Products",
    layout: "/admin",
    path: "/products",
    icon: <Icon as={MdArchive} width='20px' height='20px' color='inherit' />,
    component: Product,
  },
  {
    name: "Create Product",
    layout: "/admin",
    path: "/product/create",
    component: ProductForm,
    childComponent: true
  },
  {
    name: "View Product",
    layout: "/admin",
    path: "/product/:action/:id",
    component: ProductForm,
    childComponent: true
  },
  //customer order
  {
    name: "Order History",
    layout: "/order",
    path: "/history",
    icon: <Icon as={MdHistory} width='20px' height='20px' color='inherit' />,
    component: OrderHistory,
  },
  {
    name: "Place An Order",
    layout: "/order",
    path: "/place",
    icon: <Icon as={MdAddShoppingCart} width='20px' height='20px' color='inherit' />,
    component: Order,
  },
  {
    name: "Order Details",
    layout: "/order",
    path: "/view/:id",
    icon: <Icon as={MdHistory} width='20px' height='20px' color='inherit' />,
    component: OrderView,
    childComponent: true
  },
  {
    name: "Order Detail Change Request",
    layout: "/order",
    path: "/edit/:id",
    icon: <Icon as={MdHistory} width='20px' height='20px' color='inherit' />,
    component: OrderEdit,
    childComponent: true
  },
  //Customer Organisation Info
  {
    name: "Organisation Info",
    layout: "/organisation",
    path: "",
    icon: <Icon as={MdAddShoppingCart} width='20px' height='20px' color='inherit' />,
    component: CustomerOrganisationInfo,
  },
  {
    name: "Website Editor",
    category: true,
    icon: <Icon as={MdWeb} width='20px' height='20px' color='inherit' />,
    items: [
      //Website Editor
      // Team Members
      {
        name: "Team Members",
        layout: "/admin",
        path: "/team-members",
        icon: <Icon as={MdGroup} width='20px' height='20px' color='inherit' />,
        component: TeamMember,
        childComponent: false
      },
      {
        name: "View Member",
        layout: "/admin",
        path: "/team-member/:action/:id",
        component: TeamForm,
        childComponent: true
      },
      {
        name: "Add Member",
        layout: "/admin",
        path: "/team-member/create",
        component: TeamForm,
        childComponent: true
      },
      // Partnership
      {
        name: "Partnerships",
        layout: "/admin",
        path: "/partnerships",
        icon: <Icon as={MdHandshake} width='20px' height='20px' color='inherit' />,
        component: Partnership,
        childComponent: false
      },
      {
        name: "View Partnerships",
        layout: "/admin",
        path: "/partnership/:action/:id",
        component: PartnershipForm,
        childComponent: true
      },
      {
        name: "Add Partnership",
        layout: "/admin",
        path: "/partnership/create",
        component: PartnershipForm,
        childComponent: true
      },
      // News
      {
        name: "News",
        layout: "/admin",
        path: "/news",
        icon: <Icon as={MdNewspaper} width='20px' height='20px' color='inherit' />,
        component: News,
        childComponent: false
      },
      {
        name: "View News",
        layout: "/admin",
        path: "/new/:action/:id",
        component: NewsForm,
        childComponent: true
      },
      {
        name: "Add News",
        layout: "/admin",
        path: "/new/create",
        component: NewsForm,
        childComponent: true
      },
      // Links
      {
        name: "Links",
        layout: "/admin",
        path: "/links",
        icon: <Icon as={MdInsertLink} width='20px' height='20px' color='inherit' />,
        component: Link,
        childComponent: false
      },
      {
        name: "View Link",
        layout: "/admin",
        path: "/link/:action/:id",
        component: LinkForm,
        childComponent: true
      },
      {
        name: "Add Link",
        layout: "/admin",
        path: "/link/create",
        component: LinkForm,
        childComponent: true
      },
      //Contact Us
      {
        name: "Contacts",
        layout: "/admin",
        path: "/contacts",
        icon: <Icon as={MdContacts} width='20px' height='20px' color='inherit' />,
        component: Contact,
        childComponent: false
      },
      {
        name: "View Contact",
        layout: "/admin",
        path: "/contact/view/:id",
        component: ContactView,
        childComponent: true
      },
      // Career
      {
        name: "Careers",
        layout: "/admin",
        path: "/careers",
        icon: <Icon as={MdBusinessCenter} width='20px' height='20px' color='inherit' />,
        component: Career,
        childComponent: false
      },
      {
        name: "View Career",
        layout: "/admin",
        path: "/career/:action/:id",
        component: CareerForm,
        childComponent: true
      },
      {
        name: "Add Career",
        layout: "/admin",
        path: "/career/create",
        component: CareerForm,
        childComponent: true
      },
      {
        name: "View Career",
        layout: "/admin",
        path: "/career/:action/:id",
        component: CareerForm,
        childComponent: true
      },
      {
        name: "Add Career",
        layout: "/admin",
        path: "/career/create",
        component: CareerForm,
        childComponent: true
      },
      // Services
      {
        name: "Services",
        layout: "/admin",
        path: "/services",
        icon: <Icon as={FaHandHoldingMedical} width='20px' height='20px' color='inherit' />,
        component: Service,
        childComponent: false
      },
      {
        name: "View Service",
        layout: "/admin",
        path: "/service/:action/:id",
        component: ServiceForm,
        childComponent: true
      },
      {
        name: "Add Service",
        layout: "/admin",
        path: "/service/create",
        component: ServiceForm,
        childComponent: true
      },
      // Services
      {
        name: "Locations",
        layout: "/admin",
        path: "/locations",
        icon: <Icon as={MdEditLocationAlt} width='20px' height='20px' color='inherit' />,
        component: Location,
        childComponent: false
      },
      {
        name: "View Location",
        layout: "/admin",
        path: "/location/:action/:id",
        component: LocationForm,
        childComponent: true
      },
      {
        name: "Add Location",
        layout: "/admin",
        path: "/location/create",
        component: LocationForm,
        childComponent: true
      }]
  },
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "/default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: MainDashboard,
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
  {
    name: "Sign In",
    layout: "",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
];

export default routes;
