import { Box} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { columnsDataComplex } from "views/admin/career/components/columnsData";
import { getCareers, removeCareer } from "apis/career";
import ItemsTable from "components/admin/ItemsTable";
import FilterBar from "views/admin/career/components/filterBar";

export default function Career() {
  useEffect(() => {
    refreshCareers();
  }, []);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  function prepareData(data) {
    const preparedData = data.map((career) => {
      return career;
    });
    setData(preparedData);
  }

  function refreshCareers() {
    getCareers()
      .then((res) => {
        setUnfilteredData(res.data);
        prepareData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deleteCareer(id) {
    removeCareer(id)
      .then(() => {
        refreshCareers();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={prepareData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="career/view"
        createUrl="career/create"
        disableCallback={deleteCareer}
        disableBtnText="Delete"
        disableDialogHeader="Delete "
        disableDialogText="Are you sure you want to delete "
        disableConfirmBtnText="Delete"
        displayField="title"
      />
    </Box>
  );
}
