import { background, useColorModeValue } from "@chakra-ui/system";
import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {
  colors: {
    brand: {
      50: '#d6edc9',
      100: '#b9e0a3',
      200: '#abd990',
      300: '#9cd37d',
      400: '#8ecc6a',
      500: '#71bf44',
      600: '#5a9b35',
      700: '#4f882f',
      800: '#447528',
      900: '#2e4f1b',
    },
    brandScheme: {
      50: '#d6edc9',
      100: '#b9e0a3',
      200: '#abd990',
      300: '#9cd37d',
      400: '#8ecc6a',
      500: '#71bf44',
      600: '#5a9b35',
      700: '#4f882f',
      800: '#447528',
      900: '#2e4f1b',
    },
    brandTabs: {
      50: '#d6edc9',
      100: '#b9e0a3',
      200: '#abd990',
      300: '#9cd37d',
      400: '#8ecc6a',
      500: '#71bf44',
      600: '#5a9b35',
      700: '#4f882f',
      800: '#447528',
      900: '#2e4f1b',
    },
    secondaryGray: {
      100: "#E0E5F2",
      200: "#E1E9F8",
      300: "#F4F7FE",
      400: "#E9EDF7",
      500: "#8F9BBA",
      600: "#A3AED0",
      700: "#707EAE",
      800: "#707EAE",
      900: "#1B2559",
    },
    red: {
      100: "#FEEFEE",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      500: "#FFB547",
    },
    green: {
      100: "#E6FAF5",
      500: "#01B574",
    },
    navy: {
      50: '#5a85ee',
      100: '#2b62e9',
      200: '#1853e3',
      300: '#154acc',
      400: '#1342b5',
      500: '#0e3187',
      600: '#092059',
      700: '#071842',
      800: '#040f2b',
      900: '#000000',
    },
    gray: {
      100: "#FAFCFE",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("secondaryGray.300", "navy.900")(props),
        fontFamily: "DM Sans",
        letterSpacing: "-0.5px",
      },
      input: {
        color: mode("gray.900","gray.300")(props),
      },
      option: {
        color: mode("gray.600","gray.300")(props),
      },
      html: {
        fontFamily: "DM Sans",
      },
    }),
  },
};
