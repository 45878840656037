import React, { useContext } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import OrderForm from "./components/OrderForm";
import { UserContext } from "App"
import { LicenseStatuses } from "utils/Constants"

//design UI here
function CenteredText(props) {
  const { children } = props;
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex pt="10" justify="center" align="center">
        <Text fontSize="4xl">{children}</Text>
      </Flex>
    </Box>
  );
}

export default function CustomerPlaceOrder() {
  const [userContext, _] = useContext(UserContext);

  function getBody() {
    switch (userContext.organisationLicenseStatus) {
      case LicenseStatuses.Approved:
        return <OrderForm />
      case LicenseStatuses.Pending:
        return (<CenteredText>Please have your license approved</CenteredText>)
      case LicenseStatuses.Rejected:
      case LicenseStatuses.Expired:
        return (<CenteredText>Please update your license</CenteredText>)
      case LicenseStatuses.NotUploaded:
        return (<CenteredText>Please upload your license</CenteredText>)
      default:
        return (<CenteredText>Something went wrong, please contact support!</CenteredText>)
    }
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {getBody()}
    </Box>
  );
}
