import React from "react";
import {
    Flex,
    Input,
} from "@chakra-ui/react";

export default function TimeRangePicker(props) {
    const { values, onChange, ...rest } = props;

    function handleChange(startTimeEvent, endTimeEvent) {
        if (onChange) {
            if (startTimeEvent) {
                onChange([startTimeEvent.target.value, values[1]])
            }
            if (endTimeEvent) {
                onChange([values[0], endTimeEvent.target.value])
            }
        }
    }

    return (<Flex {...rest}>
        <Input
            type="time"
            onChange={(event) => handleChange(event, null)} />
        <Input
            type="time"
            onChange={(event) => handleChange(null, event)} />
    </Flex>);
}

TimeRangePicker.defaultProps = {
    values: ["", ""]
}