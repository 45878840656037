import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

export default function ActionDialog(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    btnText,
    btnBg,
    dialogHeader,
    dialogText,
    confirmBtnText,
    onConfirm,
    colorScheme,
    isLoading,
  } = props;
  const cancelRef = React.useRef();

  return (
    <>
      <Button
        colorScheme={colorScheme}
        bg={btnBg}
        _hover={{ bg: "red" }}
        _focus="none"
        rounded="md"
        px="24px"
        isLoading={isLoading}
        onClick={onOpen}
      >
        {btnText}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {dialogHeader}
            </AlertDialogHeader>

            <AlertDialogBody>{dialogText}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme={colorScheme}
                onClick={() => {
                  onClose();
                  onConfirm();
                }}
                ml={3}
              >
                {confirmBtnText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

ActionDialog.defaultProps = {
  btnBg: "red.500"
}