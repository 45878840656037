import axios from 'axios';
import { createStandaloneToast } from "@chakra-ui/react";

const API_URL = process.env.REACT_APP_API_URL;
const toast = createStandaloneToast();

const api = axios.create({
  baseURL: API_URL
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');

    if (!token) {
      window.location.reload();
    }

    config.headers["Content-Type"] = "application/json";
    config.headers["token"] = token;

    return config

  },
  error => {
    toast({
      title: 'An error occurred.',
      description: error?.response?.data?.message ?? error?.response?.data?.title,
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
    console.log("response error", error);
    return Promise.reject(error?.response?.data);
  }
);

api.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    toast({
      title: 'An error occurred.',
      description: error?.response?.data?.message ?? error?.response?.data?.title,
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
    console.log("response error", error);
    return Promise.reject(error?.response?.data);
  }
);

export default api;
