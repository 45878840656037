import {
  Flex,
  Box,
  Icon,
  Text,
  useColorModeValue
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { columnsDataComplex } from "./components/columnsData";

import moment from "moment";

import ItemsTable from "components/admin/ItemsTable";

import FilterBar from "./components/filterBar";

import { getOrderHistory } from "apis/order"

import { MomentFormatWithTimeZone, OrderStatusMsg, OrderStatusIcon, OrderStatusColor } from "utils/Constants"

export default function OrderHistory() {
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  function refreshData() {
    getOrderHistory().then(res => {
      setUnfilteredData(res.data)
      setData(res.data.map(product => {
        const [hour, min, sec] = product.deliveryTime.split(":");
        let requiredDateTime = moment(product.deliveryDate).add({ hours: hour, minutes: min, seconds: sec }).format(MomentFormatWithTimeZone)
        let deliveryDateTime = moment(product.deliveryDate).add({ hours: hour, minutes: min, seconds: sec })
        product.deliveryDateTime = (
          <Flex flexDirection="column" textAlign="center">
              <Text textColor={textColor}>{requiredDateTime}</Text>
              <Text textAlign="center" color={moment(new Date()).diff(deliveryDateTime, 'hour') > 0 ? "red.500": "green.500"} fontSize='sm' fontWeight='700'>
                {moment(new Date()).diff(deliveryDateTime, 'hour') > 0 ? 
                `${moment(new Date()).to(deliveryDateTime)}`
                : 
                `${moment(deliveryDateTime).from(new Date())}`}
              </Text>
            </Flex>
        )
        product.statusDisplay = (<Flex justify='center'>
          <Icon
            w='24px'
            h='24px'
            me='5px'
            color={
              OrderStatusColor[product.status]
            }
            as={
              OrderStatusIcon[product.status]
            }
          />
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {OrderStatusMsg[product.status]}
          </Text>
        </Flex>)
        return product;
      }))
    }).catch(err => {
      console.log("err", err);
    })
  }

  useEffect(() => {
    refreshData()
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={setData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="view"
      />
    </Box>
  );
}
