export const columnsDataComplex = [
    {
      Header: "Product Name",
      accessor: "name",
    },
    {
      Header: "Limited",
      accessor: "isLimited",
    },
    {
      Header: "Quantity Limit",
      accessor: "quantityLimit",
    },
    {
      Header: "Takes Entire Slot",
      accessor: "takesUpEntireTimeSlot",
    },
    {
      Header: "Actions"
    },
];