// Chakra imports
import {
  Button, Flex, Input, useColorModeValue, List, ListItem, ListIcon, Text
} from "@chakra-ui/react";
// Assets
import React from "react";
import { useDropzone } from "react-dropzone";
import Card from "components/card/Card.js";
import { MdCheckCircle, } from "react-icons/md";
import { MaxFilesUpload } from "utils/Constants";

function Dropzone(props) {
  const { onSelected, files, content, fileTypes, multiple, title, max, ...rest } = props;
  const { getRootProps, getInputProps } = useDropzone({ maxFiles: max, onDropAccepted: onSelected, accept: fileTypes });
  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const roundTo2DecPlace = (num) => Math.round((num + Number.EPSILON) * 100) / 100
  const getFileSize = (fileSizeInBytes) => {
    let sizeInKb = fileSizeInBytes / 1024
    return `${roundTo2DecPlace(sizeInKb)} KB`;
  }
  return (
    <Card>
      <Flex
        align='center'
        justify='center'
        bg={bg}
        border='1px dashed'
        borderColor={borderColor}
        borderRadius='16px'
        w='100%'
        h='max-content'
        minH='100%'
        cursor='pointer'
        {...getRootProps({ className: "dropzone" })}
        {...rest}>
        <Input variant='main' {...getInputProps()} multiple={multiple} />
        <Button variant='no-effects'>{content}</Button>

      </Flex>

      {files.length > 0 && (<Text
        // color={textColor}
        fontSize={{
          base: "xl",
          md: "lg",
          lg: "lg",
          xl: "lg",
          "2xl": "md",
          "3xl": "lg",
        }}
        mb='5px'
        fontWeight='bold'
        me='14px'>
        {title}
      </Text>)}
      <List spacing={3}>
        {files.map(file => (
          <ListItem key={file.path}>
            <ListIcon as={MdCheckCircle} color="green.500" />
            {file.path} - {getFileSize(file.size)}
          </ListItem>
        ))}
      </List>
    </Card>
  );
}

Dropzone.defaultProps = {
  max: MaxFilesUpload
}

export default Dropzone;
