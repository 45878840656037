import api from "./api";

const API_URL = `${process.env.REACT_APP_API_URL}/api/User`;

export const getUsers = async () => {
  const res = await api.get(`${API_URL}`);
  return res;
};

export const getUser = async (id) => {
  const res = await api.get(`${API_URL}/${id}`);
  return res;
};

export const createUser = async (form) => {
  const res = await api.post(`${API_URL}`, form);
  return res;
}

export const updateUser = async (id, form) => {
  const res = await api.put(`${API_URL}/${id}`, form);
  return res;
}

export const banUser = async (id, isBanned) => {
  const res = await api.put(`${API_URL}/ban/${id}`, isBanned);
  return res;
}

export const forceResetPassword = async (id, form) => {
  const res = await api.put(`${API_URL}/force-reset-password/${id}`, form);
  return res;
}