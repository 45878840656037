export const columnsDataComplex = [
    {
      Header: "Icon Image",
      accessor: "iconUrl",
      isImage: true,
      altText: "iconUrl",
    },
    {
      Header: "Service Name",
      accessor: "name",
    },
    {
      Header: "Actions",
      accessor: "id",
    },
];