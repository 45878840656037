import { React, useEffect, useState } from "react";

import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  createStandaloneToast,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";

import { Field } from "formik";
import { useParams, useHistory } from "react-router-dom";
import FormContainer from "components/admin/FormContainer";
import Upload from "components/admin/Upload";
import {
  createPartnership,
  getPartnership,
  updatePartnership,
} from "apis/partnership";
import { imageUpload, deleteImage } from "apis/files";
import { ImageUploadFileTypes, validateEmpty } from "utils/Constants";
import AuthImage from "components/auth/AuthImage";

export default function PartnershipForm(props) {
  const toast = createStandaloneToast();
  const { action, id } = useParams();
  const viewOnly = action === "view";
  const history = useHistory();
  const backUrl = "/admin/partnerships";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [PartnershipData, setPartnerships] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (action && id) {
      getPartnership(id)
        .then((res) => {
          setPartnerships(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  function submit(values) {
    if (!action) {
      createPartnership(values)
        .then(() => {
          toast({
            title: "Successful",
            description: `Partnership "${values.title}" has been created`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push(backUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (action === "edit") {
      updatePartnership(values.id, values)
        .then(() => {
          toast({
            title: "Successful",
            description: `Partnership "${values.title}" has been updated`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push(backUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast({
        title: "Invalid submit action.",
        description: "An error has occurred, please contact developers.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log("action not specified", action);
    }
  }

  return (
    (!action || PartnershipData) && (
      <FormContainer
        backUrl={backUrl}
        initialValues={
          action ? PartnershipData : { title: "", url: "", files: "" }
        }
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          if (files.length > 0) {
            let form = new FormData();
            form.append("image", files[0]);
            imageUpload(form)
              .then((res) => {
                if (PartnershipData?.imageUrl) {
                  deleteImage(PartnershipData.imageUrl)
                    .then(() => {
                      values.imageUrl = res.data.name;
                      submit(values);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  values.imageUrl = res.data.name;
                  submit(values);
                }
              })
              .catch((err) => {
                console.log("image upload error", err);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          } else {
            submit(values);
            actions.setSubmitting(false);
          }
        }}
      >
        <Flex px="25px" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Partnership Information
          </Text>
        </Flex>

        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          gap={{ base: "24px", lg: "48px" }}
          px="24px"
          my="24px"
          alignItems={"start"}
        >
          <Field name="title" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="title"
                  isInvalid={form.errors.title && form.touched.title}
                >
                  <FormLabel htmlFor="title">Partnership Title</FormLabel>
                  <Input
                    readOnly={viewOnly}
                    placeholder="Title for the article"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>

          <Field name="url" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="url"
                  isInvalid={form.errors.url && form.touched.url}
                >
                  <FormLabel htmlFor="url">Partnership Link</FormLabel>
                  <Input
                    readOnly={viewOnly}
                    placeholder="Link to the partner's website"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.url}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </Flex>

        {action != "view" && (
          <Flex px="25px" mt="24px" align="center" justify="center">
            <Field
              name="files"
              validate={() => {
                if (action != "edit") {
                  if (!files[0]) {
                    return "Please upload an image file";
                  }
                }
              }}
            >
              {({ field, form }) => {
                return (
                  <FormControl
                    id="files"
                    isInvalid={!!(form.touched.files && files.length == 0)}
                  >
                    <Upload
                      minH={{
                        base: "auto",
                      }}
                      onSelected={(selectedFiles) => {
                        setFiles(selectedFiles);
                      }}
                      files={files}
                      title={
                        PartnershipData?.imageUrl || files.length > 0
                          ? "Change Photo"
                          : "Upload Photo"
                      }
                      description="Only image file is allowed"
                      fileTypes={ImageUploadFileTypes}
                    ></Upload>
                    <FormErrorMessage>{form.errors.files}</FormErrorMessage>
                  </FormControl>
                );
              }}
            </Field>
          </Flex>
        )}

        {files.map((file, i) => (
          <Flex key={i} justify="center" align="center">
            <AuthImage width="400px" src={file} isNotUploaded/>
          </Flex>
        ))}

        {PartnershipData?.imageUrl && !files[0] && (
          <Flex px="25px" mt="24px" align="center" justify="center">
            <AuthImage width="400px" src={PartnershipData.imageUrl} />
          </Flex>
        )}
      </FormContainer>
    )
  );
}
