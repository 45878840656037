export const columnsDataComplex = [
    {
      Header: "Site Name",
      accessor: "name",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Actions",
    },
];