import React, { useState, useEffect, useMemo } from "react";

import { Link, ListIcon, Icon, Flex, Spinner } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { getFile, checkFileExistence } from "apis/files";

function ListLink(props) {
    const { iconProps, linkProps, isLoading, children } = props;
    return (<>
        {isLoading ? <Spinner size='xs' me={3}/> : <ListIcon {...iconProps} />}
        <Link {...linkProps}>
            {children}
        </Link>
    </>)
}

function StandAloneLink(props) {
    const { iconProps, linkProps, isLoading, children } = props;
    return (<Flex align="center" gap={2}>
        {isLoading ? <Spinner size='xs' me={3}/> : <Icon {...iconProps} />}
        <Link {...linkProps}>
            {children}
        </Link>
    </Flex>)
}

export default function AuthLink(props) {
    const { href, icon, color, children, isList, ...rest } = props;

    const [fileExists, setFileExists] = useState(true);
    const [fileBlob, setFileBlob] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const notExistColor = "red.400";

    const iconProps = useMemo(
        () =>
            fileExists ? {
                as: icon,
                color: color
            } : {
                as: MdClose,
                color: notExistColor
            },
        [fileExists, icon, color]
    );

    const linkProps = useMemo(
        () =>
            fileExists ? {
                href: fileBlob,
                onClick: onClick,
                color: color,
                ...rest
            } : {
                href: null,
                color: notExistColor,
                ...rest
            },
        [fileExists, fileBlob, color]
    );

    async function onClick(e) {
        if (fileExists) {

            if(!fileBlob) {
                setIsLoading(true)
                await getFile(href).then(async res => {
                    const blob = await URL.createObjectURL(res);
                    setFileBlob(blob);
                    e.target.click();
                    setIsLoading(false)
                }).catch(() => {
                    setFileExists(false)
                })
            }
        }
    }

    useEffect(() => {
        if (href) {
            checkFileExistence(href).then(res => {
                setFileExists(res.data)
            })
        }
    }, [href]);

    return isList
        ? <ListLink isLoading={isLoading} iconProps={iconProps} linkProps={linkProps}>{children}</ListLink>
        : <StandAloneLink isLoading={isLoading} iconProps={iconProps} linkProps={linkProps}>{children}</StandAloneLink>
}