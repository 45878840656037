import React, { useState, useRef } from "react";

import {
    Flex,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    Button,
    Spacer,
    FormHelperText,
    Checkbox,
    Input,
    Text,
} from "@chakra-ui/react";

import SearchBar from 'components/admin/SearchBar';
import moment from "moment";
import RangeDatepicker from "components/admin/DefaultStyleRangeDatepicker"

export default function FilterBar(props) {
    const { data, setData } = props;

    // states for filtering
    const [siteNameSearchInput, setSiteNameSearchInput] = useState();
    const siteNameSearchRef = useRef(null);
    const [addressSearchInput, setAddressSearchInput] = useState();
    const addressSearchRef = useRef(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [isFilterExceptionDates, setIsFilterExceptionDates] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [notDisabled, setNotDisabled] = useState(false);

    //

    const menuBg = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function resetForm() {
        setSiteNameSearchInput()
        siteNameSearchRef.current?.clear();
        setAddressSearchInput()
        addressSearchRef.current?.clear();
        setIsFilterExceptionDates(false)
        setSelectedDates([])
        setIsDisabled(false)
        setNotDisabled(false)        
    }

    //// filter funtions
    function filter() {
        const filtered = data.filter(site =>
            filterName(site)
            && filterAddress(site)
            && filterExceptionDates(site)
            && filterDisabled(site)
        )

        setData(filtered)
    }

    function filterExceptionDates(site) {
        if (isFilterExceptionDates) {
            let dates = site.exceptionDates;
            let toFilter;
            dates.forEach(date => {
                filter = moment(date).isSameOrAfter(selectedDates[0], 'day') &&
                moment(date).isSameOrBefore(selectedDates[1], 'day')
            });
            return toFilter;
        }
        return true;
    }

    function filterName(site) {
        if (siteNameSearchInput){
            if (!site.name.toLowerCase().includes(siteNameSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterAddress(site) {
        if (addressSearchInput){
            if (!site.address.toLowerCase().includes(addressSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterDisabled(site){
        if (isDisabled == true && !site.isDisabled){
            return false;
        }
        if (notDisabled == true && site.isDisabled){
            return false;
        }
        return true;
    }

    ////

    return (
        <form>
            <Box
                w={{ sm: "100%", lg: "auto" }}
                bg={menuBg}
                p="20px"
                borderRadius="10px"
                boxShadow={shadow}
                mb="10px"
            >
                <Flex
                    justifyContent="end"
                    alignItems="start"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                >
                    <FormControl>
                        <FormLabel>Filter By Site Name</FormLabel>
                        <SearchBar
                            ref={siteNameSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setSiteNameSearchInput}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Address</FormLabel>
                        <SearchBar
                            ref={addressSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setAddressSearchInput}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Exception Dates Between</FormLabel>
                        {isFilterExceptionDates && (<RangeDatepicker
                            selectedDates={selectedDates}
                            onDateChange={setSelectedDates}
                        />)}
                        <FormHelperText>
                            <Checkbox isChecked={isFilterExceptionDates} onChange={e => setIsFilterExceptionDates(e.target.checked)}>Search Exception Dates</Checkbox>
                        </FormHelperText>
                    </FormControl>
                </Flex>

                <Flex
                    justifyContent="end"
                    alignItems="center"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                    mt="10px"
                >

                    <FormControl>
                        <Flex gap="12px" fontSize="medium" >
                            <Text fontWeight="medium" >Production is Disabled</Text>
                            <Checkbox colorScheme='green' textColor="gray.500" isChecked={isDisabled} onChange={(e) => {setIsDisabled(e.target.checked); setNotDisabled(false)}}>
                                Yes
                            </Checkbox>
                            <Checkbox colorScheme='red' textColor="gray.500" isChecked={notDisabled} onChange={(e) => {setNotDisabled(e.target.checked); setIsDisabled(false)}}>
                                No
                            </Checkbox>
                        </Flex>
                    </FormControl>
                    
                    <Spacer />

                    <Button variant="outline" colorScheme='blue' px="30px" onClick={filter}>Search</Button>
                    <Button variant="outline" colorScheme='yellow' px="30px" type="reset" onClick={resetForm}>Clear</Button>
                </Flex>
            </Box>
        </form>
    )
}