import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { columnsDataComplex } from "views/admin/teamMember/components/columnsData";
import { getMembers, removeMember } from "apis/teamMember";
import { deleteImage } from "apis/files";
import ItemsTable from "components/admin/ItemsTable";
import FilterBar from "views/admin/teamMember/components/filterBar";

export default function TeamMember() {
  useEffect(() => {
    refreshMembers();
  }, []);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  // function prepareData(data) {
  //   const preparedData = data.map((teamMember) => {
  //     return teamMember;
  //   });
  //   setData(preparedData);
  // }

  function refreshMembers() {
    getMembers()
      .then((res) => {
        for (const data of res.data) {
          data.altText = data.name;
        }
        setUnfilteredData(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deleteMember(id) {
    removeMember(id)
      .then(() => {
        let imageUrl = data.find((element) => element.id == id).imageUrl;
        deleteImage(imageUrl)
          .then(() => {
            refreshMembers();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={setData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="team-member/view"
        createUrl="team-member/create"
        disableCallback={deleteMember}
        disableBtnText="Delete"
        disableDialogHeader="Delete "
        disableDialogText="Are you sure you want to delete "
        disableConfirmBtnText="Delete"
      />
    </Box>
  );
}
