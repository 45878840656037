/*eslint-disable*/
import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  // Button,
  // useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

import SupportModalListItem from "./SupportModalListItem";

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "white");
  // const { toggleColorMode } = useColorMode();
  const landingPageURL = process.env.REACT_APP_LANDING_PAGE_URL;

  return (
    <Flex
      zIndex='3'
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent='space-between'
      px={{ base: "30px", md: "50px" }}
      pb='30px'>
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}>
        {" "}
        &copy; {1900 + new Date().getYear()}
        <Text as='span' fontWeight='500' ms='4px'>
          <Link
            mx='3px'
            color={textColor}
            href={landingPageURL}
            target='_blank'
            fontWeight='700'>
            Quantum Pharma.
          </Link>
          All Rights Reserved.
        </Text>
      </Text>
      <List display='flex'>
        <SupportModalListItem linkColor={textColor} />
        {/* <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={textColor}
            href='#'>
          </Link>
            Support
        </ListItem> */}
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={textColor}
            isExternal
            href={`${landingPageURL}/privacy`}>
            Privacy Policy
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={textColor}
            isExternal
            href={`${landingPageURL}/terms`}>
            Terms of Use
          </Link>
        </ListItem>
        {/* <ListItem>
          <Link
            fontWeight='500'
            color={textColor}
            href='#'>
            Blog
          </Link>
        </ListItem> */}
      </List>
    </Flex>
  );
}
