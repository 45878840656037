import React, { useState, useRef } from "react";

import {
    Flex,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    Button,
    Spacer,
    Input
} from "@chakra-ui/react";

import SearchBar from 'components/admin/SearchBar';

export default function FilterBar(props) {
    const { data, setData } = props;

    // states for filtering
    const [titleSearchInput, setTitleSearchInput] = useState();
    const titleSearchRef = useRef(null);
    const [linkSearchInput, setLinkSearchInput] = useState();
    const linkSearchRef = useRef(null);
    //

    const menuBg = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function resetForm() {
        setTitleSearchInput()
        titleSearchRef.current?.clear();
        setLinkSearchInput()
        linkSearchRef.current?.clear();
    }

    //// filter funtions
    function filter() {
        const filtered = data.filter(link =>
            filterTitle(link)
            && filterLink(link)
        )

        setData(filtered)
    }

    function filterTitle(link) {
        if (titleSearchInput){
            if (!link.title.toLowerCase().includes(titleSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterLink(link) {
        if (linkSearchInput){
            if (!link.url.toLowerCase().includes(linkSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    ////

    return (
        <form>
            <Box
                w={{ sm: "100%", lg: "auto" }}
                bg={menuBg}
                p="20px"
                borderRadius="10px"
                boxShadow={shadow}
                mb="10px"
            >

                <Flex
                    justifyContent="end"
                    alignItems="end"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                >
                    <FormControl>
                        <FormLabel>Filter By Title</FormLabel>
                        <SearchBar
                            ref={titleSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setTitleSearchInput}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Link</FormLabel>
                        <SearchBar
                            ref={titleSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setTitleSearchInput}
                        />
                    </FormControl>
                    <Spacer />

                    <Button variant="outline" colorScheme='blue' px="30px" onClick={filter}>Search</Button>
                    <Button variant="outline" colorScheme='yellow' px="30px" type="reset" onClick={resetForm}>Clear</Button>
                </Flex>
            </Box>
        </form>
    )
}