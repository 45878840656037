import React, { useState, useEffect } from "react";

import { AppRouter } from "./AppRouter";
import { AuthProvider } from "utils/AuthContext";
import { BrowserRouter } from "react-router-dom";


export const UserContext = React.createContext();

function App() {
  const userState = useState(() => {
    const userInSessionStorage = sessionStorage.getItem("user");
    return userInSessionStorage ? JSON.parse(userInSessionStorage) : null;
  });

  useEffect(() => {
    userState[0] && sessionStorage.setItem("user", JSON.stringify(userState[0]))
  }, userState);

  return (
    <BrowserRouter>
      <AuthProvider>
        <UserContext.Provider value={userState}>
          <AppRouter />
        </UserContext.Provider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
