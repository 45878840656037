import { Box} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  columnsDataComplex,
} from "views/admin/product/components/columnsData";

import { getProducts, disableProduct } from "apis/product";

import ItemsTable from "components/admin/ItemsTable";

import FilterBar from "views/admin/product/components/filterBar";

export default function Product() {
  useEffect(() => {
    refreshProducts();
  }, []);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  function refreshProducts() {
    getProducts().then(res => {
      setUnfilteredData(res.data);
      setData(res.data);
    }).catch(err => {
      console.log(err);
    })
  }

  function disableProductCallback(id, isDisabled) {
    disableProduct(id, !isDisabled).then(res => {
      refreshProducts();
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={setData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="product/view"
        createUrl="product/create"
        disableCallback={disableProductCallback}
      />
    </Box>
  );
}
