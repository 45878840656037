import api from "./api";

const API_URL = `${process.env.REACT_APP_API_URL}/api/Organisation`;

export const getOrganisations = async () => {
    const res = await api.get(`${API_URL}`);
    return res;
};

export const getOrganisation = async (id) => {
    const res = await api.get(`${API_URL}/${id}`);
    return res;
};

export const createOrganisation = async (form) => {
  const res = await api.post(`${API_URL}`, form);
  return res;
}

export const updateOrganisation = async (id, form) => {
  const res = await api.put(`${API_URL}/${id}`, form);
  return res;
}

export const disableOrganisation = async (id, isDisabled) => {
  const res = await api.put(`${API_URL}/disable/${id}`, isDisabled);
  return res;
}

export const getOwnOrganisation = async () => {
    const res = await api.get(`${API_URL}/own`);
    return res;
};

export const updateLicense = async (license) => {
    const res = await api.put(`${API_URL}/update-license`, license);
    return res;
};

export const approveLicense = async (id, isApproved) => {
    const res = await api.put(`${API_URL}/approve-license/${id}`, isApproved);
    return res;
};