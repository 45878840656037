import React, { createContext, useState } from 'react';

// Create the authentication context
const AuthContext = createContext();

// Create the authentication provider
const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || null);

  // Define functions to handle login and logout
  const login = (userToken) => {
    // Perform your login logic here
    setToken(userToken);
    localStorage.setItem('token', userToken);
  };

  const logout = () => {
    // Perform your logout logic here
    setToken(null);
    sessionStorage.removeItem("user")
    localStorage.removeItem('token');
  };

  // Provide the state and functions to the children components
  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
