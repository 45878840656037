export const columnsDataComplex = [
    {
      Header: "Location Name",
      accessor: "name",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Working Hours",
      accessor: "workingHours",
    },
    {
      Header: "Actions",
      accessor: "id",
    },
];