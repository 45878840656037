import React, { useState, useRef } from "react";

import {
    Flex,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    FormHelperText,
    Button,
    Checkbox,
    Spacer,
    SimpleGrid,
    Input,
    Select
} from "@chakra-ui/react";
import SearchBar from 'components/admin/SearchBar';
import moment from "moment";
import RangeDatepicker from "components/admin/DefaultStyleRangeDatepicker"

export default function FilterBar(props) {
    const { data, setData } = props;


    // states for filtering
    const [nameSearchInput, setNameSearchInput] = useState();
    const nameSearchRef = useRef(null);
    const [emailSearchInput, setEmailSearchInput] = useState();
    const emailSearchRef = useRef(null);
    const [phoneSearchInput, setPhoneSearchInput] = useState();
    const phoneSearchRef = useRef(null);
    const [locationInput, setLocationInput] = useState();
    const [selectedDates, setSelectedDates] = useState([]);
    const [isViewed, setIsViewed] = useState(false);
    const [notViewed, setNotViewed] = useState(false);
    const [isFilterSubmittedDates, setIsFilterSubmittedDates] = useState(false);

    //

    const menuBg = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function resetForm() {
        setNameSearchInput()
        nameSearchRef.current?.clear();
        setEmailSearchInput()
        emailSearchRef.current?.clear();
        setPhoneSearchInput()
        phoneSearchRef.current?.clear();
        setLocationInput()
        setIsViewed(false)
        setNotViewed(false)
        setIsFilterSubmittedDates(false)
        setSelectedDates([])
    }

    //// filter funtions
    function filter() {
        const filtered = data.filter(contact =>
            filterName(contact)
            && filterEmail(contact)
            && filterPhone(contact)
            && filterViewed(contact)
            && filterLocation(contact)
            && filterSubmittedDate(contact)
        )

        setData(filtered)
    }

    function filterSubmittedDate(contact) {
        if (isFilterSubmittedDates) {
            return moment(contact.contactedDateTime).isSameOrAfter(selectedDates[0], 'day') &&
                moment(contact.contactedDateTime).isSameOrBefore(selectedDates[1], 'day')
        }
        return true;
    }

    function filterName(contact) {
        if (nameSearchInput){
            if (!contact.fullName.toLowerCase().includes(nameSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterEmail(contact) {
        if (emailSearchInput){
            if (!contact.email.toLowerCase().includes(emailSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterPhone(contact) {
        if (phoneSearchInput){
            if (!contact.phone.toLowerCase().includes(phoneSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterViewed(contact){
        if (isViewed == true && !contact.isSeen){
            return false;
        }
        if (notViewed == true && contact.isSeen){
            return false;
        }
        return true;
    }

    function filterLocation(contact){
        if (locationInput){
            if (contact.location != locationInput){
                return false;
            }
        }
        return true;
    }
    
    ////

    return (
        <form>
            <Box
                w={{ sm: "100%", lg: "auto" }}
                bg={menuBg}
                p="20px"
                borderRadius="10px"
                boxShadow={shadow}
                mb="10px"
            >

                <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap='10px' mb='10px'>
                    <FormControl>
                        <FormLabel>Filter By Name</FormLabel>
                        <SearchBar
                            ref={nameSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setNameSearchInput}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Email</FormLabel>
                        <SearchBar
                            ref={emailSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setEmailSearchInput}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Phone</FormLabel>
                        <SearchBar
                            ref={phoneSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setPhoneSearchInput}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Location</FormLabel>
                        <Select onChange={(e)=>setLocationInput(e.target.value)}>
                            <option value="">All</option>
                            <option value="0">Melbourne</option>
                            <option value="1">Singapore</option>
                        </Select>
                    </FormControl>


                </SimpleGrid>

                <Flex
                    justifyContent="end"
                    alignItems="end"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                >

                    <FormControl maxW={{ base: "100%", lg: "700px" }} my={{ base: "10px", lg: "unset" }}>
                        <Flex 
                            justifyContent={{ base: "center", md: "start" }}
                            flexWrap={{ base: "wrap", md: "nowrap" }}
                            gap="36px"
                        >
                            <Box>
                                <FormLabel textAlign="center" w="100%">Already Viewed</FormLabel>
                                <FormHelperText>
                                    <Flex gap="24px" justify="center">
                                        <Checkbox colorScheme='green' isChecked={isViewed} onChange={(e) => {setIsViewed(e.target.checked); setNotViewed(false)}}>
                                            Yes
                                        </Checkbox>

                                        <Checkbox colorScheme='red' isChecked={notViewed} onChange={(e) => {setNotViewed(e.target.checked); setIsViewed(false)}}>
                                            No
                                        </Checkbox>
                                    </Flex>
                                </FormHelperText>
                            </Box>

                            <FormControl>
                                <FormLabel>Submitted Dates Between</FormLabel>
                                {isFilterSubmittedDates && (<RangeDatepicker
                                    selectedDates={selectedDates}
                                    onDateChange={setSelectedDates}
                                />)}
                                <FormHelperText>
                                    <Checkbox isChecked={isFilterSubmittedDates} onChange={e => setIsFilterSubmittedDates(e.target.checked)}>Search Submitted Dates</Checkbox>
                                </FormHelperText>
                            </FormControl>
                        </Flex>
                    </FormControl>
                    
                    
                    <Spacer />

                    <Button variant="outline" colorScheme='blue' px="30px" onClick={filter}>Search</Button>
                    <Button variant="outline" colorScheme='yellow' px="30px" type="reset" onClick={resetForm}>Clear</Button>
                </Flex>
            </Box>
        </form>
    )
}