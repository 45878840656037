import { React, useEffect, useState } from "react";
import {
    Button,
    Flex,
    Text,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    createStandaloneToast,
    useColorModeValue,
    SimpleGrid,
} from "@chakra-ui/react";
import { Field } from 'formik';
import { useParams, useHistory } from "react-router-dom";
import { getProductSiteID, createProductSite, updateProductSite } from "apis/productSite";
import FormContainer from "components/admin/FormContainer";
import moment from "moment";
import { MomentFormatDateOnly } from "utils/Constants"

export default function SiteForm(props) {
    const toast = createStandaloneToast();
    const { action, id } = useParams();
    const viewOnly = action === "view"
    const history = useHistory();
    const backUrl = "/admin/sites";
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [productSite, setProductSite] = useState(null);
    const [exceptionDates, setExceptionDates] = useState([]);
    const [currentExceptionDate, setCurrentExceptionDate] = useState('');
    const [hover, setHover] = useState();

    const exceptionDateBtnBg = useColorModeValue("white", "secondaryGray.900");

    const initValues = {
        name: "",
        address: "",
        exceptionDates: [],
    };

    useEffect(() => {
        if (action && id) {
            getProductSiteID(id).then(res => {
                setProductSite(res.data)
                setExceptionDates(res.data.exceptionDates)
            }).catch(err => {
                console.log(err);
            })
        }
    }, []);

    function validateName(value) {
        let error
        if (!value) {
            error = "Name is required"
        }
        return error
    }

    function validateAddress(value) {
        let error
        if (!value) {
            error = "Address is required"
        }
        return error
    }

    return ((!action || productSite) && (
        <FormContainer
            backUrl={backUrl}
            initialValues={action ? productSite : initValues}
            onSubmit={(values, actions) => {
                actions.setSubmitting(true)
                values.exceptionDates = exceptionDates
                if (!action) {
                    createProductSite(values).then(res => {
                        toast({
                            title: 'Successful',
                            description: `Production Site "${values.name}" has been created`,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        })
                        actions.setSubmitting(false)
                        history.push(backUrl)
                    }).catch(err => {
                        console.log(err);
                    })
                } else if (action === "edit") {
                    updateProductSite(values.id, values).then(res => {
                        toast({
                            title: 'Successful',
                            description: `Production Site ${values.name} has been updated`,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        })
                        actions.setSubmitting(false)
                        history.push(backUrl)
                    }).catch(err => {
                        console.log(err);
                    })
                }
            }}
        >
            <Flex px='25px' mb='20px' align='center'>
                <Text
                    color={textColor}
                    fontSize='22px'
                    fontWeight='700'
                    lineHeight='100%'>
                    Production Site
                </Text>
            </Flex>

            <SimpleGrid columns={{ base: 1, md: 2}} gap="20px" my="24px">
                <Field name="name" validate={validateName} alignItems={"end"}>
                    {({ field, form }) => (
                        <FormControl px='24px' isInvalid={form.errors.name && form.touched.name}>
                            <FormLabel htmlFor="name">Site Name</FormLabel>
                            <Input
                                {...field}
                                id="name"
                                placeholder=""
                                readOnly={viewOnly}
                            />
                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                        </FormControl>
                    )}
                </Field>

                <Field name="address" validate={validateAddress} alignItems={"end"}>
                    {({ field, form }) => (
                        <FormControl px='24px' isInvalid={form.errors.address && form.touched.address}>
                            <FormLabel htmlFor="address">Address</FormLabel>
                            <Input
                                {...field}
                                id="address"
                                placeholder=""
                                readOnly={viewOnly}
                            />
                            <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                        </FormControl>
                    )}
                </Field>
            </SimpleGrid>

            <Flex px='25px' my='24px' align='center'>
                <Text
                    color={textColor}
                    fontSize='18px'
                    fontWeight='500'
                    lineHeight='100%'>
                    Exception Dates
                </Text>
            </Flex>
            
            {!viewOnly && (
                <Flex px='24px' gap={{ base: "24px", md: "48px" }}  flexDirection={{ base: "column", md: "row" }}>
                    <FormControl>
                        <Input
                            type="date"
                            value={currentExceptionDate}
                            onChange={e => setCurrentExceptionDate(e.target.value)}
                            readOnly={viewOnly}
                        />
                    </FormControl>
                    <Flex width={{ base: "100%", md: "20%" }} mb="24px">
                        <Button
                            variant="brand"
                            width={{ base: "100%" }}
                            onClick={() => {
                                if (currentExceptionDate) {
                                    setExceptionDates([currentExceptionDate, ...exceptionDates.filter(existingTime => existingTime !== currentExceptionDate)].sort());
                                }
                            }}>
                            Add Time
                        </Button>
                    </Flex>

                </Flex>
            )}

            <SimpleGrid columns={{ base: 1, md: 2, xl: 4, '2xl': 6, '3xl': 8 }} px='24px' my="24px" gap="20px" >
                {
                    exceptionDates.map((date, index) => (
                        <Button
                            key={index}
                            readOnly={viewOnly}
                            minWidth="150px"
                            onClick={() => setExceptionDates(exceptionDates.filter(item => item !== date))}
                            variant='brand'
                            onMouseEnter={() => { setHover(index) }}
                            onMouseLeave={() => { setHover(null) }}
                            bg={exceptionDateBtnBg}
                            color={textColor}
                            outlineColor={"gray.200"}
                            _hover={{ bg: 'red.600', color: 'white', outlineColor: "red.600" }}
                            pointerEvents={(viewOnly) && "none"}
                        >
                            {hover === index ? "Delete" : moment(date).format(MomentFormatDateOnly)}
                        </Button>
                    ))
                }
            </SimpleGrid>
        </FormContainer>
    )
    )
}