import React from "react";
import PropTypes from "prop-types";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "assets/css/MiniCalendar.css";
import { Text, Icon } from "@chakra-ui/react";
// Chakra imports
import { MdChevronLeft, MdChevronRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
// Custom components
import Card from "components/card/Card.js";
import moment from "moment";

export default function MiniCalendar(props) {
  const { selectRange, value, onChange, allowDays, excludedDays, minDate, isInvalid, ...rest } = props;
  function isDisabled(date, view) {
    return view === "month"
      && (!allowDays.includes(date.getDay())
        || excludedDays.includes(moment(date).format("YYYY-MM-DD"))
      )
  }
  return (
    <Card
      align='center'
      direction='column'
      w='100%'
      // maxW='max-content'
      p='20px 15px'
      h='max-content'
      aria-invalid={isInvalid}
      {...rest}>
      <Calendar
        onChange={onChange}
        value={value}
        selectRange={selectRange}
        defaultView={"month"}
        minDate={minDate}
        // tileContent={<Text color='brand.500'></Text>}
        prevLabel={<Icon as={MdChevronLeft} w='24px' h='24px' mt='4px' />}
        nextLabel={<Icon as={MdChevronRight} w='24px' h='24px' mt='4px' />}
        prev2Label={<Icon as={MdKeyboardDoubleArrowLeft} w='24px' h='24px' mt='4px' />}
        next2Label={<Icon as={MdKeyboardDoubleArrowRight} w='24px' h='24px' mt='4px' />}
        tileDisabled={({ date, view }) => isDisabled(date, view)}
      />
    </Card>
  );
}

MiniCalendar.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
  selectRange: PropTypes.bool,
  excludedDays: PropTypes.array,
  minDate: PropTypes.object,
  allowDays: PropTypes.array,
};

MiniCalendar.defaultProps = {
  selectRange: false,
  allowDays: [],
  excludedDays: [],
  minDate: new Date(),
  isInvalid: false
};
