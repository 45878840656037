import { React, useEffect, useState } from "react";

import {
  Button,
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  createStandaloneToast,
  useColorModeValue,
  Image,
  Textarea,
  Tooltip,
  Container,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/react";

import { Field, useFormikContext } from "formik";
import { useParams, useHistory } from "react-router-dom";
import FormContainer from "components/admin/FormContainer";
import { createCareer } from "apis/career";
import { updateCareer } from "apis/career";
import { getCareer } from "apis/career";
import linkedinIcon from "assets/img/icons/linkedin.png";
import seekIcon from "assets/img/icons/seek.png";
import indeedIcon from "assets/img/icons/indeed.png";
import careerOneIcon from "assets/img/icons/careerone.png";
import { validateEmpty } from "utils/Constants";

const FormFields = (props) => {
  const { viewOnly } = props;

  const { values } = useFormikContext();
  const { links } = values;

  const textColor = useColorModeValue("secondaryGray.900", "white");

  function validateAtLeastOneLink() {
    const keys = Object.keys(links).map(key => key.trim())
    return keys.every((key) => links[key] == "")
      ? "There must be at least one link"
      : null;
  }

  return (
    <>
      <Flex px="25px" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Career Information
        </Text>
      </Flex>

      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        gap={{ base: "24px", lg: "48px" }}
        px="24px"
        my="24px"
        alignItems={"start"}
      >
        <Field name="title" validate={validateEmpty}>
          {({ field, form }) => {
            return (
              <FormControl
                id="title"
                isInvalid={form.errors.title && form.touched.title}
              >
                <FormLabel htmlFor="title">Job Title</FormLabel>
                <Input
                  readOnly={viewOnly}
                  placeholder="Title for the position"
                  {...field}
                />
                <FormErrorMessage>{form.errors.title}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>
      </Flex>

      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        gap={{ base: "24px", lg: "48px" }}
        px="24px"
        my="24px"
        alignItems={"start"}
      >
        <Field name="links.linkedIn" validate={validateAtLeastOneLink}>
          {({ field, form }) => {
            return (
              <FormControl
                id="links.linkedIn"
                isInvalid={
                  form.errors.links?.linkedIn && form.touched.links?.linkedIn
                }
              >
                <FormLabel htmlFor="links.linkedIn">LinkedIn Link</FormLabel>
                <InputGroup>
                  <Input
                    readOnly={viewOnly}
                    placeholder="Link for this position at LinkedIn's website"
                    {...field}
                  />
                  <InputRightElement display="flex" alignItems="center">
                    <Image src={linkedinIcon} alt="LinkedIn Icon" boxSize={6} />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {form.errors.links?.linkedIn}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>

        <Field name="links.seek" validate={validateAtLeastOneLink}>
          {({ field, form }) => {
            return (
              <FormControl
                id="links.seek"
                isInvalid={form.errors.links?.seek && form.touched.links?.seek}
              >
                <FormLabel htmlFor="links.seek">Seek Link</FormLabel>
                <InputGroup>
                  <Input
                    readOnly={viewOnly}
                    placeholder="Link for this position at Seek's website"
                    {...field}
                  />
                  <InputRightElement display="flex" alignItems="center">
                    <Image src={seekIcon} alt="Seek Icon" boxSize={6} />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.links?.seek}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>
      </Flex>

      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        gap={{ base: "24px", lg: "48px" }}
        px="24px"
        my="24px"
        alignItems={"start"}
      >
        <Field name="links.indeed" validate={validateAtLeastOneLink}>
          {({ field, form }) => {
            return (
              <FormControl
                id="links.indeed"
                isInvalid={
                  form.errors.links?.indeed && form.touched.links?.indeed
                }
              >
                <FormLabel htmlFor="links.indeed">Indeed Link</FormLabel>
                <InputGroup>
                  <Input
                    readOnly={viewOnly}
                    placeholder="Link for this position at Indeed's website"
                    {...field}
                  />
                  <InputRightElement display="flex" alignItems="center">
                    <Image src={indeedIcon} alt="Indeed Icon" boxSize={6} />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{form.errors.links?.indeed}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>

        <Field name="links.careerOne" validate={validateAtLeastOneLink}>
          {({ field, form }) => {
            return (
              <FormControl
                id="links.careerOne"
                isInvalid={
                  form.errors.links?.careerOne && form.touched.links?.careerOne
                }
              >
                <FormLabel htmlFor="links.careerOne">CareerOne Link</FormLabel>
                <InputGroup>
                  <Input
                    readOnly={viewOnly}
                    placeholder="Link for this position at CareerOne's website"
                    {...field}
                  />
                  <InputRightElement display="flex" alignItems="center">
                    <Image src={careerOneIcon} alt="CareerOne Icon" />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {form.errors.links?.careerOne}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>
      </Flex>

      <Flex px="25px" mt="24px" align="center">
        <Text
          color={textColor}
          fontSize="18px"
          fontWeight="500"
          lineHeight="100%"
        >
          Description
        </Text>
      </Flex>

      <Flex px="25px" mt="24px" align="center">
        <Field name="description" validate={validateEmpty}>
          {({ field, form }) => {
            return (
              <FormControl
                id="description"
                isInvalid={form.errors.description && form.touched.description}
              >
                <Textarea
                  readOnly={viewOnly}
                  placeholder="Description for this position"
                  {...field}
                />
                <FormErrorMessage>{form.errors.description}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>
      </Flex>
    </>
  );
};

export default function CareerForm(props) {
  const toast = createStandaloneToast();
  const { action, id } = useParams();
  const viewOnly = action === "view";
  const history = useHistory();
  const backUrl = "/admin/careers";

  const [CareerData, setCareer] = useState({});

  const initValues = {
    title: "",
    description: "",
    links: {
      linkedIn: "",
      seek: "",
      indeed: "",
      careerOne: "",
    },
  };

  useEffect(() => {
    if (action && id) {
      setCareer(null);
      getCareer(id)
        .then((res) => {
          setCareer(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    (!action || CareerData) && (
      <FormContainer
        backUrl={backUrl}
        initialValues={action ? CareerData : initValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          if (!action) {
            createCareer(values)
              .then(() => {
                toast({
                  title: "Successful",
                  description: `Career "${values.title}" has been created`,
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                });
                actions.setSubmitting(false);
                history.push(backUrl);
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (action === "edit") {
            updateCareer(values.id, values)
              .then(() => {
                toast({
                  title: "Successful",
                  description: `Career "${values.title}" has been updated`,
                  status: "success",
                  duration: 9000,
                  isClosable: true,
                });
                actions.setSubmitting(false);
                history.push(backUrl);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            toast({
              title: "Invalid submit action.",
              description: "An error has occurred, please contact developers.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            console.log("action not specified", action);
          }
        }}
      >
        <FormFields viewOnly={viewOnly} />
      </FormContainer>
    )
  );
}
