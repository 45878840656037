export const columnsDataComplex = [
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Linkedin",
      accessor: "links.linkedIn",
      isLink: true,
      linkTitle: "Link to post",
    },
    {
      Header: "Seek",
      accessor: "links.seek",
      isLink: true,
      linkTitle: "Link to post",
    },
    {
      Header: "Indeed",
      accessor: "links.indeed",
      isLink: true,
      linkTitle: "Link to post",
    },
    {
      Header: "Career One",
      accessor: "links.careerOne",
      isLink: true,
      linkTitle: "Link to post",
    },
    {
      Header: "Actions",
      accessor: "id",
    },
];