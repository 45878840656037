import axios from "axios";
import api from "./api";
import { createStandaloneToast } from "@chakra-ui/react";

const API_URL = `${process.env.REACT_APP_API_URL}/api/auth`;
const toast = createStandaloneToast();

export const userLogin = async (form) => {
  try {
    const res = await axios.post(`${API_URL}/login`, form);
    return res.data.data;
  } catch (error) {
    toast({
      title: 'An error occurred.',
      description: error.response.data.message ?? error.response.data.title,
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
    console.log("login error", error);
    return Promise.reject(error.response.data);
  }
};

export const getUserData = async () => {
  const userData = await api.get(`${API_URL}/get-user-data`);
  return userData;
};

export const  resetPassword = async (form) => {
  const res = await api.put(`${API_URL}/reset-password`, form);
  return res;
}