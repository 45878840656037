
import React from "react";

import {
    Button,
    ListItem,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useColorModeValue
} from "@chakra-ui/react";

export default function SupportModalListItem(props) {
    const { linkColor } = props;
    const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
    const emailColor = useColorModeValue("blue.600", "teal.400");
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (<ListItem
        me={{
            base: "20px",
            md: "44px",
        }}>
        <Link
            fontWeight='500'
            color={linkColor}
            href='#'
            onClick={onOpen}
        >
            Support
        </Link>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Support requests</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    For support requests, please send an email to <Link
                        fontWeight='500'
                        color={emailColor}
                        href={'mailto:' + supportEmail}
                        onClick={onOpen}
                    >
                        {supportEmail}
                    </Link>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='brand' mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </ListItem>)
}