import api from "./api";

const API_URL = `${process.env.REACT_APP_API_URL}/api/product`;

export const getProducts = async () => {
  const res = await api.get(`${API_URL}`);
  return res;
};

export const getProductsWithoutRules = async () => {
  const res = await api.get(`${API_URL}/without-rules`);
  return res;
};

export const getProductById = async (id) => {
  const res = await api.get(`${API_URL}/${id}`);
  return res;
};

export const createProduct = async (form) => {
  const res = await api.post(`${API_URL}/`, form);
  return res;
}

export const updateProduct = async (id, form) => {
  const res = await api.put(`${API_URL}/${id}`, form);
  return res;
}

export const disableProduct = async (id, isDisabled) => {
  const res = await api.put(`${API_URL}/disable/${id}`, isDisabled);
  return res;
};