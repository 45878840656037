import { React, useEffect, useState } from "react";

import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  createStandaloneToast,
  useColorModeValue,
  Image,
  Textarea,
} from "@chakra-ui/react";

import { Field } from "formik";
import { useParams, useHistory } from "react-router-dom";
import FormContainer from "components/admin/FormContainer";
import Upload from "components/admin/Upload";
import { createGeoLocation, getGeoLocation, updateGeoLocation } from "apis/geoLocation";
import { validateEmpty } from "utils/Constants";

export default function GeoLocationForm(props) {
  const toast = createStandaloneToast();
  const { action, id } = useParams();
  const viewOnly = action === "view";
  const history = useHistory();
  const backUrl = "/admin/locations";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [GeoLocationData, setGeoLocations] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (action && id) {
      getGeoLocation(id)
        .then((res) => {
          setGeoLocations(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  function submit(values) {
    if (!action) {
      createGeoLocation(values)
        .then(() => {
          toast({
            title: "Successful",
            description: `GeoLocation "${values.name}" has been created`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push(backUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (action === "edit") {
      updateGeoLocation(values.id, values)
        .then(() => {
          toast({
            title: "Successful",
            description: `Location "${values.name}" has been updated`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push(backUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast({
        address: "Invalid submit action.",
        workingHours: "An error has occurred, please contact developers.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log("action not specified", action);
    }
  }

  return (
    (!action || GeoLocationData) && (
      <FormContainer
        backUrl={backUrl}
        initialValues={
          action
            ? GeoLocationData
            : { name: "", address: "", phone: "", location: "", workingHours: "" }
        }
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          submit(values);
        }}
      >
        <Flex px="25px" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Location Information
          </Text>
        </Flex>

        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          gap={{ base: "24px", lg: "48px" }}
          px="24px"
          my="24px"
          alignItems={"start"}
        >
          <Field name="name" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="name"
                  isInvalid={form.errors.name && form.touched.name}
                >
                  <FormLabel htmlFor="name">Location name</FormLabel>
                  <Input
                    readOnly={viewOnly}
                    placeholder="The Location's name"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
          <Field name="phone">
            {({ field, form }) => {
              return (
                <FormControl
                  id="phone"
                  isInvalid={form.errors.phone && form.touched.phone}
                >
                  <FormLabel htmlFor="phone">Phone</FormLabel>
                  <Input
                    readOnly={viewOnly}
                    placeholder="The Location's phone"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
          <Field name="address" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="address"
                  isInvalid={form.errors.address && form.touched.address}
                >
                  <FormLabel htmlFor="address">Address</FormLabel>
                  <Input
                    readOnly={viewOnly}
                    placeholder="The Location's address"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </Flex>

        <Flex px="25px" mt="24px" align="center">
          <Text
            color={textColor}
            fontSize="18px"
            fontWeight="500"
            lineHeight="100%"
          >
            Working Hours
          </Text>
        </Flex>

        <Flex px="25px" mt="24px" align="center">
          <Field name="workingHours" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="workingHours"
                  isInvalid={
                    form.errors.workingHours && form.touched.workingHours
                  }
                >
                  <Textarea
                    readOnly={viewOnly}
                    placeholder="Please Describe the working hours"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.workingHours}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </Flex>
      </FormContainer>
    )
  );
}
