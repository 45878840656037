import React from "react";
import {
    Text,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Button,
} from "@chakra-ui/react";

export default function RequestChangeDialog(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isLoading,
        form
    } = props;
    const cancelRef = React.useRef();

    function onBtnClick() {
        if (form.isValid) {
            onOpen()
        }
    }

    return (
        <>
            <Button
                colorScheme="red"
                bg="red.500"
                _hover={{ bg: "red" }}
                _focus="none"
                rounded="md"
                px="24px"
                isLoading={isLoading}
                onClick={onBtnClick}
            >
                Request Change
            </Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Request Detail Change
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Text>
                                A change request with the new order details will be sent.
                            </Text>
                            <Text>
                                For further enquiries, please contact Quantum Pharma Australia.
                            </Text>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={() => {
                                    onClose();
                                    form.submitForm();
                                }}
                                ml={3}
                            >
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}