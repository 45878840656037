import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import ProtectedRoute from "utils/ProtectedRoute";
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { AuthContext } from "utils/AuthContext";
import { UserContext } from "App"
import { getUserData } from "apis/auth";
import { UserRoles } from "utils/Constants"

function redirectTo(roles) {
    if (roles?.includes(UserRoles.Admin)) {
        return "/admin/dashboard"
    }
    if (roles?.includes(UserRoles.Customer)) {
        return "/order/history"
    }
    if (roles?.includes(UserRoles.ProductionStaff)) {
        return "/staff/orders"
    }
    if (roles?.includes(UserRoles.Driver)) {
        return "/delivery/orders"
    }
    return "/sign-in";
}

export const AppRouter = () => {
    const { token, setToken } = useContext(AuthContext);
    const [userContext, setUserContext] = useContext(UserContext);
    const history = useHistory();

    function handleUserContext(token) {
        if (token) {
            if (!userContext) {
                getUserData()
                    .then((res) => {
                        setUserContext(res);
                        if (history.location.pathname == "/sign-in") {
                            history.push(redirectTo(res.roles))
                        }
                    })
                    .catch(() => {
                        localStorage.removeItem("token");
                        if (history.location.pathname != "/sign-in") {
                            history.push('/sign-in')
                        }
                    });
            } else {
                if (history.location.pathname == "/sign-in") {
                    history.push(redirectTo(userContext.roles))
                }
            }
        } else {
            setToken(null);
            setUserContext(null);
            sessionStorage.removeItem('user')
            if (history.location.pathname != "/sign-in") {
                history.push('/sign-in')
            }
        }
    }

    useEffect(() => {
        window.addEventListener('storage', () => {
            const tokenInStorage = localStorage.getItem("token");
            handleUserContext(tokenInStorage)
            history.go(0)
        });
    }, []);

    useEffect(() => {
        handleUserContext(token);
    }, [token]);

    return (<Router>
        <Switch>
            <Route path={`/sign-in`}
                render={() => (token && userContext ? <Redirect to={redirectTo(userContext?.roles)} /> : <AuthLayout />)} />
            {userContext?.roles.includes(UserRoles.Admin) && (<ProtectedRoute path={`/admin`} component={AdminLayout} />)}
            {userContext?.roles.includes(UserRoles.Admin) && (<ProtectedRoute path={`/rtl`} component={RtlLayout} />)}
            {userContext?.roles.includes(UserRoles.Customer) && (<ProtectedRoute path={`/order`} component={AdminLayout} />)}
            {userContext?.roles.includes(UserRoles.Customer) && (<ProtectedRoute path={`/organisation`} component={AdminLayout} />)}
            {userContext?.roles.includes(UserRoles.Driver) && (<ProtectedRoute path={`/delivery`} component={AdminLayout} />)}
            {userContext?.roles.includes(UserRoles.ProductionStaff) && (<ProtectedRoute path={`/staff`} component={AdminLayout} />)}
            {token ? (userContext && <Redirect from={'/'} to={redirectTo(userContext?.roles)} />) : <Redirect from='*' to='/sign-in' />}
        </Switch>
    </Router>);
}