import { React, useEffect, useState } from "react";

import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  createStandaloneToast,
  useColorModeValue,
  Image,
  Textarea,
} from "@chakra-ui/react";

import { Field } from "formik";
import { useParams, useHistory } from "react-router-dom";
import FormContainer from "components/admin/FormContainer";
import Upload from "components/admin/Upload";
import { createService, getService, updateService } from "apis/providedServices";
import { imageUpload, deleteImage } from "apis/files";
import { ImageUploadFileTypes, validateEmpty } from "utils/Constants";
import AuthImage from "components/auth/AuthImage"

export default function TeamForm(props) {
  const toast = createStandaloneToast();
  const { action, id } = useParams();
  const viewOnly = action === "view";
  const history = useHistory();
  const backUrl = "/admin/services";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [serviceData, setServices] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (action && id) {
      getService(id)
        .then((res) => {
          setServices(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  function submit(values) {
    if (!action) {
      createService(values)
        .then(() => {
          toast({
            title: "Successful",
            description: `Service "${values.name}" has been created`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push(backUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (action === "edit") {
      updateService(values.id, values)
        .then(() => {
          toast({
            title: "Successful",
            description: `S ervice "${values.name}" has been updated`,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          history.push(backUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast({
        title: "Invalid submit action.",
        description: "An error has occurred, please contact developers.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log("action not specified", action);
    }
  }

  return (
    (!action || serviceData) && (
      <FormContainer
        backUrl={backUrl}
        initialValues={
          action
            ? serviceData
            : { name: "", description: "", iconUrl: "", files: "" }
        }
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          if (files.length > 0) {
            let form = new FormData();
            form.append("image", files[0]);
            imageUpload(form)
              .then((res) => {
                if (serviceData?.iconUrl) {
                  deleteImage(serviceData.iconUrl)
                    .then(() => {
                      values.iconUrl = res.data.name;
                      submit(values);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  values.iconUrl = res.data.name;
                  submit(values);
                }
              })
              .catch((err) => {
                console.log("image upload error", err);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          } else {
            submit(values);
            // actions.setSubmitting(false);
          }
        }}
      >
        <Flex px="25px" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Service Information
          </Text>
        </Flex>

        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          gap={{ base: "24px", lg: "48px" }}
          px="24px"
          my="24px"
          alignItems={"start"}
        >
          <Field name="name" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="name"
                  isInvalid={form.errors.name && form.touched.name}
                >
                  <FormLabel htmlFor="name">Service name</FormLabel>
                  <Input
                    readOnly={viewOnly}
                    placeholder="The service's name"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </Flex>

        <Flex px="25px" mt="24px" align="center">
          <Text
            color={textColor}
            fontSize="18px"
            fontWeight="500"
            lineHeight="100%"
          >
            Description
          </Text>
        </Flex>

        <Flex px="25px" mt="24px" align="center">
          <Field name="description" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="description"
                  isInvalid={
                    form.errors.description && form.touched.description
                  }
                >
                  <Textarea
                    readOnly={viewOnly}
                    placeholder="Please describe the service"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </Flex>

        {action != "view" && (
          <Flex px="25px" mt="24px" align="center" justify="center">
            <Field
              name="files"
            >
              {({ field, form }) => {
                return (
                  <FormControl
                    id="files"
                  >
                    <Upload
                      minH={{
                        base: "auto",
                      }}
                      onSelected={(selectedFiles) => {
                        setFiles(selectedFiles);
                      }}
                      multiple={false}
                      files={files}
                      title={
                        serviceData?.iconUrl || files.length > 0
                          ? "Change Icon"
                          : "Upload Icon"
                      }
                      description="Only icon image file is allowed"
                      fileTypes={ImageUploadFileTypes}
                    ></Upload>
                  </FormControl>
                );
              }}
            </Field>
          </Flex>
        )}

        {files.map((file, index) => (
          <Flex justify="center" align="center" key={index}>
            <AuthImage width="400px" src={file} isNotUploaded/>
          </Flex>
        ))}

        {serviceData?.iconUrl && !files[0] && (
          <Flex px="25px" mt="24px" align="center" justify="center">
            <AuthImage width="400px" src={serviceData.iconUrl} />
          </Flex>
        )}
      </FormContainer>
    )
  );
}
