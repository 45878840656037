import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,

} from '@chakra-ui/react'

export default function DeleteDialog(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { btnText, dialogHeader, dialogText, confirmBtnText, onConfirm, colorScheme } = props;
  const cancelRef = React.useRef()

  return (
    <>
      <Button colorScheme={colorScheme} onClick={onOpen}>
        {btnText}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {dialogHeader}
            </AlertDialogHeader>

            <AlertDialogBody>
              {dialogText}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme={colorScheme} onClick={() => {
                onClose()
                onConfirm()
              }} ml={3}>
                {confirmBtnText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}