import React from "react";

import { Select } from "chakra-react-select";

export default function DefaultStyleReactSelect(props) {
    const  { menuIsOpen, isReadOnly, ...rest } = props
    return <Select {...rest} isSearchable={!isReadOnly} menuIsOpen={isReadOnly ? false: menuIsOpen}/>
}

DefaultStyleReactSelect.defaultProps = {
    isReadOnly: false,
    chakraStyles: {
        dropdownIndicator: (provided) => ({
            ...provided,
            bg: "transparent",
            px: 2,
            cursor: "inherit"
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none"
        })
    }
}