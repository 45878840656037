import React from "react"

import {
    Flex,
    Select,
    Button,
    useBreakpoint
} from "@chakra-ui/react";

import moment from "moment";

import Information from "components/admin/Information";
import LicenseView from "./LicenseView";

import { MomentFormatDateOnly, MomentFormatShortDate, LicenseStatuses } from "utils/Constants";

export default function AdminLicenseInfo(props) {
    const { licenseHistory, license, setLicense, ApproveLicenseClick } = props;
    const breakPoint = useBreakpoint()

    function getSelectOption(data, i) {
        switch (breakPoint) {
            // case "md":
            case "sm":
            case "2sm":
            case "base":
                return (<option key={i} value={i}>
                    {i == 0 ? "*" : ""} {moment(data.uploadedOn).format(MomentFormatShortDate)} - {data.uploaderName}
                </option>)

            default:
                return (<option key={i} value={i}>
                    {i == 0 ? "(Current) " : ""} {moment(data.uploadedOn).format(MomentFormatDateOnly)} | Uploaded by - {data.uploaderName}
                </option>)
        }
    }

    if (!license) {
        return <Information value="No license uploaded" />
    }

    return (<>
        <Flex flexDirection={{ base: "column", md: "row" }} gap="24px" mb="20px">
            <Select
                onChange={(e) => { setLicense(licenseHistory[e.target.value]) }}
            >
                {licenseHistory.map((data, i) => getSelectOption(data, i))}
            </Select>
            {((license.status != LicenseStatuses.Approved) && license == licenseHistory[0]) && (
                <Button onClick={() => ApproveLicenseClick(true)} rounded="md" minW="120px" colorScheme="brand">Approve</Button>
            )}

            {((license.status != LicenseStatuses.Rejected) && license == licenseHistory[0]) && (
                <Button onClick={() => ApproveLicenseClick(false)} rounded="md" minW="120px" colorScheme="red">Reject</Button>
            )}
        </Flex>
        <LicenseView license={license} />
    </>);
}