// Chakra Imports
import {
	Avatar,
	Button,
	Flex,
	Icon,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
	useColorMode,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useDisclosure,
	FormControl,
	FormLabel,
	FormErrorMessage,
	FormHelperText,
	createStandaloneToast,
	InputGroup,
	InputRightElement,
	Input
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
// Custom Icons
import { IoMdMoon, IoMdSunny } from "react-icons/io";
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes.js';
import { ThemeEditor } from './ThemeEditor';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import { UserContext } from 'App';
import { resetPassword } from 'apis/auth';
import { validateEmpty } from "utils/Constants";
import { AuthContext } from "utils/AuthContext";


function validatePassword(value) {
	value = value.trim();
	return !value ? "This field cannot be empty"
		: value.length < 8 ? "Password must be at least 8 characters long" : null;
}

function validateConfirmPassword(pass, value) {

	let error = validatePassword(value);
	if (pass && value) {
		if (pass !== value) {
			error = "New Password and Confirm Password must match";
		}
	}
	return error;
};

export default function HeaderLinks(props) {
	const toast = createStandaloneToast()
	const history = useHistory()
	const { logout } = useContext(AuthContext);
	// Show Password
	const [showOld, setShowOld] = React.useState(false)
	const [showNew, setShowNew] = React.useState(false)
	const [showConfirm, setShowConfirm] = React.useState(false)

	// Dialog
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = React.useRef()

	// User Context
	const [userContext, setUserContext] = useContext(UserContext);

	const { secondary } = props;
	// Chakra Color Mode
	const { colorMode, toggleColorMode } = useColorMode();
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

	function singOut() {
		logout();
		setUserContext(null);
		history.push('/sign-in');
	}
	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p="10px"
			borderRadius="30px"
			boxShadow={shadow}>
			<>
				<AlertDialog
					isOpen={isOpen}
					leastDestructiveRef={cancelRef}
					onClose={onClose}
				>
					<Formik
						initialValues={{}}
						onSubmit={(values, actions) => {
							resetPassword(values).then(() => {
								toast({
									title: 'Successful',
									description: `Your password has been updated`,
									status: 'success',
									duration: 5000,
									isClosable: true,
								})
								onClose();
								singOut();
								actions.setSubmitting(false)
							}).catch(err => {
								console.log(err);
							})
						}}
					>
						{(props) => (

							<AlertDialogOverlay>
								<AlertDialogContent>
									<AlertDialogHeader fontSize='lg' fontWeight='bold'>
										<Link width="100%" onClick={() => { setShowConfirm(false); setShowNew(false); setShowOld(false); }}>Change Password</Link>
									</AlertDialogHeader>

									<AlertDialogBody>
										<Form>
											<Field name="oldPassword" validate={validateEmpty}>
												{({ field, form }) => (
													<FormControl isInvalid={form.errors.oldPassword && form.touched.oldPassword}>
														<FormLabel htmlFor="oldPassword">Current Password</FormLabel>
														<InputGroup>
															<Input
																{...field}
																id="oldPassword"
																placeholder=""
																type={showOld ? "text" : "password"}
															/>
															<InputRightElement
																display="flex"
																alignItems="center"
															>
																<Icon
																	as={showOld ? RiEyeCloseLine : MdOutlineRemoveRedEye}
																	onClick={() => { setShowOld(!showOld) }}
																/>
															</InputRightElement>

														</InputGroup>
														<FormErrorMessage>{form.errors.oldPassword}</FormErrorMessage>
													</FormControl>
												)}
											</Field>

											<Field type="password" name="newPassword" validate={validatePassword}>
												{({ field, form }) => (
													<FormControl isInvalid={form.errors.newPassword && form.touched.newPassword}>
														<FormLabel htmlFor="newPassword">New Password</FormLabel>
														<InputGroup>
															<Input
																{...field}
																id="newPassword"
																placeholder=""
																type={showNew ? "text" : "password"}
															/>
															<InputRightElement
																display="flex"
																alignItems="center"
															>
																<Icon
																	as={showNew ? RiEyeCloseLine : MdOutlineRemoveRedEye}
																	onClick={() => { setShowNew(!showNew) }}
																/>
															</InputRightElement>
														</InputGroup>
														<FormErrorMessage>{form.errors.newPassword}</FormErrorMessage>
													</FormControl>
												)}
											</Field>

											<Field type="password" name="confirmNewPassword" validate={value => validateConfirmPassword(props.values.newPassword, value)}>
												{({ field, form }) => (
													<FormControl isInvalid={form.errors.confirmNewPassword && form.touched.confirmNewPassword}>
														<FormLabel htmlFor="confirmNewPassword">Confirm New Password</FormLabel>
														<InputGroup>
															<Input
																{...field}
																id="confirmNewPassword"
																placeholder=""
																type={showConfirm ? "text" : "password"}
															/>
															<InputRightElement
																display="flex"
																alignItems="center"
															>
																<Icon
																	as={showConfirm ? RiEyeCloseLine : MdOutlineRemoveRedEye}
																	onClick={() => { setShowConfirm(!showConfirm) }}
																/>
															</InputRightElement>
														</InputGroup>
														<FormErrorMessage>{form.errors.confirmNewPassword}</FormErrorMessage>
													</FormControl>
												)}
											</Field>
										</Form>
									</AlertDialogBody>

									<AlertDialogFooter>
										<Button ref={cancelRef} onClick={onClose}>
											Cancel
										</Button>
										<Button colorScheme='red' onClick={() => props.submitForm()} ml={3}>
											Change Password
										</Button>
									</AlertDialogFooter>
								</AlertDialogContent>
							</AlertDialogOverlay>

						)}
					</Formik>
				</AlertDialog>
			</>
			{/* <SearchBar mb={secondary ? { base: '10px', md: 'unset' } : 'unset'} me="10px" borderRadius="30px" /> */}
			<Flex
				bg={ethBg}
				display={secondary ? 'flex' : 'none'}
				borderRadius="30px"
				ms="auto"
				p="6px"
				align="center"
				me="6px">
				<Flex align="center" justify="center" bg={ethBox} h="29px" w="29px" borderRadius="30px" me="7px">
					<Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
				</Flex>
				<Text w="max-content" color={ethColor} fontSize="sm" fontWeight="700" me="6px">
					1,924
					<Text as="span" display={{ base: 'none', md: 'unset' }}>
						{' '}
						ETH
					</Text>
				</Text>
			</Flex>
			<SidebarResponsive routes={routes} />

			{/* <Menu>
				<MenuButton p="0px">
					<Icon mt="6px" as={MdNotificationsNone} color={navbarIcon} w="18px" h="18px" me="10px" />
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p="20px"
					borderRadius="20px"
					bg={menuBg}
					border="none"
					mt="22px"
					me={{ base: '30px', md: 'unset' }}
					minW={{ base: 'unset', md: '400px', xl: '450px' }}
					maxW={{ base: '360px', md: 'unset' }}>
					<Flex jusitfy="space-between" w="100%" mb="20px">
						<Text fontSize="md" fontWeight="600" color={textColor}>
							Notifications
						</Text>
						<Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer">
							Mark all read
						</Text>
					</Flex>
					<Flex flexDirection="column">
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
							<ItemContent info="Horizon UI Dashboard PRO" aName="Alicia" />
						</MenuItem>
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
							<ItemContent info="Horizon Design System Free" aName="Josh Henry" />
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>

			<Menu>
				<MenuButton p='0px'>
					<Icon
						mt='6px'
						as={MdInfoOutline}
						color={navbarIcon}
						w='18px'
						h='18px'
						me='10px'
					/>
				</MenuButton>
				<MenuList
					boxShadow={shadow}
					p='20px'
					me={{ base: "30px", md: "unset" }}
					borderRadius='20px'
					bg={menuBg}
					border='none'
					mt='22px'
					minW={{ base: "unset" }}
					maxW={{ base: "360px", md: "unset" }}>

					<Text>General Information to be filled</Text>
				</MenuList>
			</Menu> */}

			{/* <ThemeEditor navbarIcon={navbarIcon} /> */}

			<Menu>
				{/* Toggle Dark Lightmode */}
				<Button
					w="20px"
					mr="10px"
					border="1px solid"
					onClick={toggleColorMode}
					borderRadius="50%">
					<Icon
						color={useColorModeValue("gray", "white")}
						as={colorMode === "light" ? IoMdMoon : IoMdSunny}
					/>
				</Button>

				<MenuButton p="0px">
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color="white"
						name={userContext.displayName}
						bg="#11047A"
						w="40px"
						h="40px"
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
					<Flex w="100%" mb="0px">
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							w="100%"
							borderBottom="1px solid"
							borderColor={borderColor}
							fontSize="sm"
							fontWeight="700"
							color={textColor}>
							👋&nbsp; Hey, {userContext.displayName}
						</Text>
					</Flex>
					<Flex flexDirection="column" p="10px">
						<MenuItem onClick={onOpen} fontSize="sm" _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
							Change Password
						</MenuItem>
						{/* <Link href="/sign-out" fontSize="sm"> */}
						<MenuItem
							onClick={singOut}
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color="red.400"
							borderRadius="8px"
							px="14px">
							Log out
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
