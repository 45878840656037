import { React, useEffect, useState, useContext, useMemo } from "react";

import {
  Button,
  List,
  ListItem,
  ListIcon,
  Flex,
  Text,
  FormControl,
  FormLabel,
  createStandaloneToast,
  useColorModeValue,
  Select,
  Icon,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react";

import Information from "components/admin/Information";
import { useParams } from "react-router-dom";
import { adminGetOrder } from "apis/order";
import { getProductsWithoutRules } from "apis/product";
import { getOrganisations } from "apis/organisation";
import { getProductionTimeSlots } from "apis/productionTimeSlot";
import { getProductSite } from "apis/productSite";
import { getUsers } from "apis/user";
import FormContainer from "components/admin/FormContainer";
import moment from "moment";
import {
  MomentFormatDateOnly,
  MomentFormatWithTimeZone,
  OrderStatusMsg,
  OrderStatusIcon,
  OrderStatusColor,
  OrderStatus,
  UserRoles,
} from "utils/Constants";
import ActionDialog from "components/admin/ActionDialogBtn";
import { MdAttachFile, MdCheck } from "react-icons/md";
import { updateOrderStatus } from "apis/order";
import { NavLink } from "react-router-dom";
import Card from "components/card/Card.js";
import { UserContext } from "App";
import StaffDriverUpdateDetailsModal from "./components/StaffDriverUpdateDetailsModal";
import AuthImage from "components/auth/AuthImage";

import AuthLink from "components/auth/AuthLink";

export default function OrderView() {
  const [userContext, _] = useContext(UserContext);
  const isAdmin = userContext.roles.includes(UserRoles.Admin);
  const isStaff = userContext.roles.includes(UserRoles.ProductionStaff);
  const orgViewStartUrl = isAdmin ? "/admin" : isStaff ? "/staff" : "/delivery";

  const toast = createStandaloneToast();
  const { id } = useParams();
  const backUrl = "/admin/orders";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const changeLogTextColor = useColorModeValue("yellow.700", "yellow.400");
  const [orderData, setData] = useState(null);
  const [selectedChangeLog, setSelectedChangeLog] = useState(null);
  const [selectedChangeLogIndex, setSelectedChangeLogIndex] = useState(null);
  const [changeLogs, setChangeLogs] = useState([]);

  const [products, setProducts] = useState({});
  const [organisations, setOrganisations] = useState({});
  const [productionSites, setProductionSites] = useState({});
  const [timeSlots, setTimeSlots] = useState({});
  const [users, setUsers] = useState({});

  const cardShadow = useColorModeValue(
    "0px 20px 40px rgba(112, 144, 176, 0.2)",
    "unset"
  );

  const uploadedFilesCardBg = useColorModeValue("white", "navy.700");

  function changeStatus(id, status, message) {
    updateOrderStatus(id, status).then(() => {
      getData();
      toast({
        title: "Successful",
        description: message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    });
  }

  function arrayToKeyVal(data, namefield = "name") {
    const keyValPair = {};
    for (const item of data) {
      keyValPair[item.id] = item[namefield];
    }
    return keyValPair;
  }

  const showStaffUpdateBtn = useMemo(
    () =>
      isStaff ||
      orderData?.status == OrderStatus.Confirmed ||
      orderData?.status == OrderStatus.ReadyForPickUp ||
      orderData?.status == OrderStatus.OnTheWay,
    [orderData]
  );

  useEffect(() => {
    getProductsWithoutRules().then((res) =>
      setProducts(arrayToKeyVal(res.data))
    );
    getOrganisations().then((res) => setOrganisations(arrayToKeyVal(res.data)));
    getProductionTimeSlots().then((res) =>
      setTimeSlots(arrayToKeyVal(res.data))
    );
    getProductSite().then((res) => setProductionSites(arrayToKeyVal(res.data)));
    getUsers().then((res) => setUsers(arrayToKeyVal(res.data, "displayName")));
    getData();
  }, []);

  function getData() {
    adminGetOrder(id)
      .then((res) => {
        const order = res.data;
        setData(order);
        setChangeLogs(order.changeLogs.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function changeLogSelectionChanged(e) {
    if (e.target.value === "") {
      setSelectedChangeLog(null);
      setSelectedChangeLogIndex(null);
    } else {
      setSelectedChangeLog(changeLogs[e.target.value]);
      setSelectedChangeLogIndex(e.target.value);
    }
  }

  function getChangeLogVal(key, key2) {
    if (selectedChangeLog != null && orderData[key] != selectedChangeLog[key]) {
      if (key2) {
        if (orderData[key][key2] != selectedChangeLog[key][key2]) {
          return selectedChangeLog[key][key2];
        }
        return null;
      }
      return selectedChangeLog[key];
    }
    return null;
  }

  function changeLogFileList(fileKey) {
    if (selectedChangeLog == null) {
      return;
    }

    if (selectedChangeLog[fileKey].length == orderData?.[fileKey].length) {
      if (
        selectedChangeLog[fileKey].every(
          (value, index) => value.name === orderData?.[fileKey][index].name
        )
      ) {
        return;
      }
    }

    if (selectedChangeLog[fileKey].length > 0) {
      return (
        <List mt="3" spacing={3}>
          {selectedChangeLog[fileKey].map((file, i) => (
            <ListItem key={i}>
              <AuthLink
                href={file.name}
                icon={MdAttachFile}
                color={changeLogTextColor}
                isExternal
                isList
              >
                {file.originalName}
              </AuthLink>
            </ListItem>
          ))}
        </List>
      );
    }

    return (
      <Text as="i" mt="3" color={changeLogTextColor}>
        No files uploaded
      </Text>
    );
  }

  return (
    orderData && (
      <FormContainer backUrl={backUrl}>
        {isAdmin && (
          <Flex align="end" justify="end">
            <FormControl maxW={{ base: "100%", lg: "75%" }}>
              <FormLabel htmlFor="changeLog">Change logs</FormLabel>
              <Select
                id="changeLog"
                name="changeLog"
                size="lg"
                variant="filled"
                placeholder={
                  "(Lastest) " +
                  `${orderData.changedBy} [${moment(
                    orderData.changedTime
                  ).format(MomentFormatWithTimeZone)}]`
                }
                onChange={changeLogSelectionChanged}
              >
                {changeLogs.map((o, i) => (
                  <option key={i} value={i}>{`${o.changedBy} [${moment(
                    o.changedTime
                  ).format(MomentFormatWithTimeZone)}]`}</option>
                ))}
              </Select>
            </FormControl>
          </Flex>
        )}

        <Flex
          align={{ sm: "start", md: "end" }}
          flexDirection={{ sm: "column", md: "row" }}
          my="12px"
        >
          <Text
            color={textColor}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            Order Details
          </Text>

          <Flex
            justify="end"
            flexDirection={{ sm: "row" }}
            flexGrow={{ sm: "1" }}
            gap="24px"
          >
            {isAdmin ? (
              <>
                <NavLink
                  to={`/admin/order/edit/${id}?changeLog=${selectedChangeLogIndex}`}
                >
                  <Button colorScheme="red" rounded="md">
                    Edit Detail
                  </Button>
                </NavLink>

                <ActionDialog
                  btnText="Cancel Order"
                  dialogHeader="Cancel Order"
                  dialogText="Are you sure you want to cancel this order?"
                  confirmBtnText="Confirm"
                  colorScheme="red"
                  onConfirm={() =>
                    changeStatus(
                      orderData.id,
                      OrderStatus.Cancelled,
                      "This order has been canceled."
                    )
                  }
                />
              </>
            ) : (
              showStaffUpdateBtn && (
                <StaffDriverUpdateDetailsModal
                  colorScheme="brand"
                  rounded="md"
                  afterUpdateFunc={getData}
                  initialValues={{
                    id: orderData.id,
                    status: orderData.status,
                    sharedFiles: orderData.sharedFiles,
                    internalNotes: orderData.internalNotes,
                    internalFiles: orderData.internalFiles,
                    deliveredImageUrl: orderData.deliveredImageUrl,
                  }}
                >
                  Update Details
                </StaffDriverUpdateDetailsModal>
              )
            )}
          </Flex>
        </Flex>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap="20px">
          <Information
            boxShadow={cardShadow}
            title="Product"
            value={products[orderData.productId]}
            secondValue={products[getChangeLogVal("productId")]}
          />

          <Information
            boxShadow={cardShadow}
            title="Production Site"
            value={productionSites[orderData.productionSiteId]}
            secondValue={productionSites[getChangeLogVal("productionSiteId")]}
          />

          <Information
            boxShadow={cardShadow}
            title="Organisation"
            value={
              <NavLink
                to={`${orgViewStartUrl}/organisation/view/${orderData.orderOrgainsationId}`}
              >
                <Text color={textColor}>
                  {organisations[orderData.orderOrgainsationId]}
                </Text>
              </NavLink>
            }
            secondValue={
              <NavLink
                to={`${orgViewStartUrl}/organisation/view/${getChangeLogVal(
                  "orderOrgainsationId"
                )}`}
              >
                <Text color={textColor}>
                  {organisations[getChangeLogVal("orderOrgainsationId")]}
                </Text>
              </NavLink>
            }
          />

          <Information
            boxShadow={cardShadow}
            title="Ordered User (the user that logged in to order)"
            value={users[orderData.orderUserId]}
            secondValue={users[getChangeLogVal("orderUserId")]}
          />

          <Information
            boxShadow={cardShadow}
            title="Ordered Quantity"
            value={orderData.quantity}
            secondValue={getChangeLogVal("quantity")}
          />

          <Information
            boxShadow={cardShadow}
            title="Ordered Date + Time"
            value={moment(orderData.orderedDateTime).format(
              MomentFormatWithTimeZone
            )}
          />

          <Information
            boxShadow={cardShadow}
            title="Delivery Date"
            value={moment(orderData.deliveryDate).format(MomentFormatDateOnly)}
            secondValue={
              getChangeLogVal("deliveryDate")
                ? moment(getChangeLogVal("deliveryDate")).format(
                    MomentFormatDateOnly
                  )
                : ""
            }
          />

          <Information
            boxShadow={cardShadow}
            title="Pick Up Time"
            value={orderData.pickUpTime}
            secondValue={getChangeLogVal("pickUpTime")}
          />

          <Information
            boxShadow={cardShadow}
            title="Delivery Time"
            value={orderData.deliveryTime}
            secondValue={getChangeLogVal("deliveryTime")}
          />

          <Information
            boxShadow={cardShadow}
            title="Ordered Time Slot"
            value={timeSlots[orderData.timeSlotId]}
            secondValue={timeSlots[getChangeLogVal("timeSlotId")]}
          />

          <Information
            boxShadow={cardShadow}
            title="Order Person"
            value={orderData.orderPerson}
            secondValue={getChangeLogVal("orderPerson")}
          />

          <Information
            boxShadow={cardShadow}
            title="Purchase Order"
            value={orderData.purchaseOrder || "N/A"}
            secondValue={
              getChangeLogVal("purchaseOrder") === ""
                ? "N/A"
                : getChangeLogVal("purchaseOrder")
            }
          />

          <Information
            boxShadow={cardShadow}
            title="Invoice Number"
            value={orderData.invoiceNumber || "N/A"}
            secondValue={
              getChangeLogVal("invoiceNumber") === ""
                ? "N/A"
                : getChangeLogVal("invoiceNumber")
            }
          />

          <Information
            boxShadow={cardShadow}
            title="Status"
            value={
              <Flex align="center">
                <Icon
                  w="24px"
                  h="24px"
                  me="5px"
                  color={OrderStatusColor[orderData.status]}
                  as={OrderStatusIcon[orderData.status]}
                />
                <Text color={textColor} fontSize="sm" fontWeight="700">
                  {OrderStatusMsg[orderData.status]}
                </Text>
              </Flex>
            }
            secondValue={
              selectedChangeLog != null &&
              orderData.status != selectedChangeLog.status && (
                <Flex align="center">
                  <Icon
                    w="24px"
                    h="24px"
                    me="5px"
                    color={changeLogTextColor}
                    as={OrderStatusIcon[selectedChangeLog.status]}
                  />
                  <Text as="i" fontSize="sm" color={changeLogTextColor}>
                    {OrderStatusMsg[selectedChangeLog.status]}
                  </Text>
                </Flex>
              )
            }
          />
        </SimpleGrid>

        <Text
          color={textColor}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Patient's Details
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} gap="20px">
          <Information
            boxShadow={cardShadow}
            title="Name"
            value={orderData.patientDetails.name}
            secondValue={getChangeLogVal("patientDetails", "name")}
          />
          <Information
            boxShadow={cardShadow}
            title="Weight"
            value={orderData.patientDetails.weightInKG}
            secondValue={getChangeLogVal("patientDetails", "weightInKG")}
          />
          <Information
            boxShadow={cardShadow}
            title="Date of Birth"
            value={moment(orderData.patientDetails.dateOfBirth).format(
              MomentFormatDateOnly
            )}
            secondValue={
              getChangeLogVal("patientDetails", "dateOfBirth") &&
              moment(getChangeLogVal("patientDetails", "dateOfBirth")).format(
                MomentFormatDateOnly
              )
            }
          />
          <Information
            boxShadow={cardShadow}
            title="Doctor Name"
            value={orderData.patientDetails.doctorName}
            secondValue={getChangeLogVal("patientDetails", "doctorName")}
          />
        </SimpleGrid>

        <Text
          color={textColor}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Additional Information
        </Text>

        <SimpleGrid columns={1} gap="20px">
          <Information
            boxShadow={cardShadow}
            title="Customer notes"
            value={orderData.additionalInformation || "None"}
            secondValue={
              getChangeLogVal("additionalInformation") === ""
                ? "None"
                : getChangeLogVal("additionalInformation")
            }
          />

          <Information
            boxShadow={cardShadow}
            title="Internal notes"
            value={orderData.internalNotes || "None"}
            secondValue={
              getChangeLogVal("internalNotes") === ""
                ? "None"
                : getChangeLogVal("internalNotes")
            }
          />
        </SimpleGrid>

        <Text
          color={textColor}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Files
        </Text>

        <Card mb="10px" bg={uploadedFilesCardBg}>
          <Text fontWeight="500" color="gray.400" fontSize="sm">
            Customer's files
          </Text>
          {orderData?.files.length > 0 ? (
            <>
              <List spacing={3}>
                {orderData?.files.map((file, i) => (
                  <ListItem key={i}>
                    <AuthLink
                      href={file.name}
                      icon={MdAttachFile}
                      color="blue.400"
                      isExternal
                      isList
                    >
                      {file.originalName}
                    </AuthLink>
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            <>No files uploaded</>
          )}

          {changeLogFileList("files")}
        </Card>

        <Card mb="10px" bg={uploadedFilesCardBg}>
          <Text fontWeight="500" color="gray.400" fontSize="sm">
            Shared files
          </Text>
          {orderData?.sharedFiles.length > 0 ? (
            <List spacing={3}>
              {orderData?.sharedFiles.map((file, i) => (
                <Tooltip
                  key={i}
                  hasArrow
                  placement="bottom-start"
                  label={
                    file.isReadOn
                      ? `Read on ${moment(file.isReadOn).format(
                          MomentFormatWithTimeZone
                        )}`
                      : null
                  }
                >
                  <ListItem>
                    {file.isRead && (
                      <ListIcon as={MdCheck} color={"green.400"} />
                    )}
                    <AuthLink
                      href={file.name}
                      icon={MdAttachFile}
                      color={file.isRead ? "green.400" : "blue.400"}
                      isExternal
                      isList
                    >
                      {file.originalName}
                    </AuthLink>
                  </ListItem>
                </Tooltip>
              ))}
            </List>
          ) : (
            <>No files uploaded</>
          )}

          {changeLogFileList("sharedFiles")}
        </Card>

        <Card mb="10px" bg={uploadedFilesCardBg}>
          <Text fontWeight="500" color="gray.400" fontSize="sm">
            Internal files
          </Text>
          {orderData?.internalFiles.length > 0 ? (
            <>
              <List spacing={3}>
                {orderData?.internalFiles.map((file, i) => (
                  <ListItem key={i}>
                    <AuthLink
                      href={file.name}
                      icon={MdAttachFile}
                      color="blue.400"
                      isExternal
                      isList
                    >
                      {file.originalName}
                    </AuthLink>
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            <>No files uploaded</>
          )}

          {changeLogFileList("internalFiles")}
        </Card>

        <Information
          boxShadow={cardShadow}
          title="Delivery Image"
          value={
            orderData?.deliveredImageUrl && (
              <AuthImage
                maxH="300px"
                alt="Delivery image"
                src={orderData.deliveredImageUrl}
              />
            )
          }
          secondValue={
            selectedChangeLog != null &&
            orderData.deliveredImageUrl !=
              selectedChangeLog.deliveredImageUrl &&
            (selectedChangeLog.deliveredImageUrl ? (
              <AuthImage
                pt="5px"
                maxH="300px"
                alt="Delivery image"
                src={selectedChangeLog.deliveredImageUrl}
              />
            ) : (
              "No image"
            ))
          }
        />
      </FormContainer>
    )
  );
}
