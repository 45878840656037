import React from "react";

import Select from "./DefaultStyleReactSelect";
import { useField } from "formik"

export default function ReactSelectFormik(props) {
    const { name, options, onChange, ...rest } = props;
    const [field, _, helpers] = useField(name)
    const { setValue } = helpers

    return (<Select
        {...rest}
        options={options}
        value={options ? options.find((option) => option.value == field.value) : ""}
        onChange={(option) => {
            setValue(option?.value || "");
            if (onChange) {
                onChange(option?.value || "")
            }
        }}
    />)

}
