import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { columnsDataComplex } from "views/admin/link/components/columnsData";
import { getLinks, removeLink } from "apis/link";
import ItemsTable from "components/admin/ItemsTable";
import FilterBar from "views/admin/link/components/filterBar";

export default function Link() {
  useEffect(() => {
    refreshLinks();
  }, []);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  function prepareData(data) {
    const preparedData = data.map((link) => {
      return link;
    });
    setData(preparedData);
  }

  function refreshLinks() {
    getLinks()
      .then((res) => {
        setUnfilteredData(res.data);
        prepareData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deleteLink(id) {
    removeLink(id)
      .then(() => {
        refreshLinks();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={prepareData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="link/view"
        createUrl="link/create"
        disableCallback={deleteLink}
        disableBtnText="Delete"
        disableDialogHeader="Delete "
        disableDialogText="Are you sure you want to delete "
        disableConfirmBtnText="Delete"
        displayField="title"
      />
    </Box>
  );
}
