import api from "./api";

const API_URL = `${process.env.REACT_APP_API_URL}/api/TeamMember`;

export const getMembers = async () => {
    const res = await api.get(`${API_URL}`);
    return res;
};

export const getMember = async (id) => {
    const res = await api.get(`${API_URL}/${id}`);
    return res;
};

export const createMember = async (form) => {
  const res = await api.post(`${API_URL}`, form);
  return res;
}

export const updateMember = async (id, form) => {
  const res = await api.put(`${API_URL}/${id}`, form);
  return res;
}

export const removeMember = async (id) => {
  const res = await api.delete(`${API_URL}/${id}`);
  return res;
}