import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { columnsDataComplex } from "views/admin/partnership/components/columnsData";
import { getPartnerships, removePartnership } from "apis/partnership";
import { deleteImage } from "apis/files";
import ItemsTable from "components/admin/ItemsTable";
import FilterBar from "views/admin/partnership/components/filterBar";

export default function Partnership() {
  useEffect(() => {
    refreshPartnership();
  }, []);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  function prepareData(data) {
    const preparedData = data.map((partnership) => {
      return partnership;
    });
    setData(preparedData);
  }

  function refreshPartnership() {
    getPartnerships()
      .then((res) => {
        setUnfilteredData(res.data);
        prepareData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deletePartner(id) {
    removePartnership(id)
      .then(() => {
        let imageUrl = data.find((element) => element.id == id).imageUrl;
        deleteImage(imageUrl)
          .then(() => {
            refreshPartnership();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={prepareData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="partnership/view"
        createUrl="partnership/create"
        disableCallback={deletePartner}
        disableBtnText="Delete"
        disableDialogHeader="Delete "
        disableDialogText="Are you sure you want to delete "
        disableConfirmBtnText="Delete"
      />
    </Box>
  );
}
