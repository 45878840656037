import api from "./api";

const API_URL = `${process.env.REACT_APP_API_URL}/api/Partnership`;

export const getPartnerships = async () => {
    const res = await api.get(`${API_URL}`);
    return res;
};

export const getPartnership = async (id) => {
    const res = await api.get(`${API_URL}/${id}`);
    return res;
};

export const createPartnership = async (form) => {
  const res = await api.post(`${API_URL}`, form);
  return res;
}

export const updatePartnership = async (id, form) => {
  const res = await api.put(`${API_URL}/${id}`, form);
  return res;
}

export const removePartnership = async (id) => {
  const res = await api.delete(`${API_URL}/${id}`);
  return res;
}