import React, { useState, forwardRef, useImperativeHandle, useRef } from "react";
import {
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";

const SearchBar = forwardRef((props, ref) => {
    const [value, setValue] = useState('')
    // Pass the computed styles into the `__css` prop
    const { variant, background, children, placeholder, width, borderRadius, onChange, clearable, ...rest } =
        props;
    // Chakra Color Mode
    const searchIconColor = useColorModeValue("gray.700", "white");
    const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
    const inputText = useColorModeValue("gray.700", "gray.100");

    const inputRef = useRef(null);

    function handleChange(event) {
        var val = event?.target?.value || "";
        setValue(val)
        if (onChange) {
            onChange(val)
        }
    }

    function handleFocus(event) {
        event.stopPropagation();
        if (inputRef?.current) {
            inputRef?.current.focus();
        }
    };

    function clear() {
        var dummyEvent = {
            target: { value: "" },
        };
        handleChange(dummyEvent);
    }

    useImperativeHandle(ref, () => {
        return {
            clear,
        };
    });

    return (
        <InputGroup w={width} {...rest} zIndex={0}>
            <InputLeftElement>
                <IconButton
                    bg='inherit'
                    borderRadius='inherit'
                    onClick={handleFocus}
                    _hover={{
                        cursor: "unset"
                    }}
                    _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                    }}
                    _focus={{
                        boxShadow: "none",
                    }}
                    icon={
                        <SearchIcon color={searchIconColor} w='15px' h='15px' />
                    }
                />
            </InputLeftElement>
            <Input
                ref={inputRef}
                variant='search'
                fontSize='sm'
                bg={background ? background : inputBg}
                color={inputText}
                fontWeight='500'
                value={value}
                onChange={handleChange}
                _placeholder={{ color: "gray.400", fontSize: "14px" }}
                borderRadius={borderRadius}
                placeholder={placeholder}
            />
            {clearable && value.length > 0 && (<InputRightElement>
                <IconButton
                    onClick={clear}
                    bg='inherit'
                    borderRadius='inherit'
                    _hover={{
                        cursor: "pointer"
                    }}
                    _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                    }}
                    _focus={{
                        boxShadow: "none",
                    }}
                    icon={
                        <CloseIcon color={searchIconColor} w='10px' h='10px' />
                    }
                />
            </InputRightElement>)}
        </InputGroup>
    );
})

SearchBar.defaultProps = {
    width: { base: "100%", md: "200px" },
    borderRadius: "30px",
    placeholder: "Search...",
    clearable: false
}

export default SearchBar;