import { React, useEffect, useState } from "react";

import {
  Spacer,
  Flex,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  createStandaloneToast,
  useColorModeValue,
  Checkbox,
  Stack,
} from "@chakra-ui/react";

import { Field } from "formik";
import { useParams, useHistory } from "react-router-dom";

import { getUser, createUser, updateUser } from "apis/user";
import { getOrganisations } from "apis/organisation";

import FormContainer from "components/admin/FormContainer";
import ReactSelect from "components/admin/ReactSelectFormik";

import { getSelectOptionObj, validateEmpty } from "utils/Constants";

import ResetPasswordDialog from "./components/reset-password-dialog";

export default function UserForm(props) {
  const toast = createStandaloneToast();
  const { action, id } = useParams();
  const viewOnly = action === "view";
  const history = useHistory();
  const backUrl = "/admin/users";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [userData, setData] = useState(null);
  const [organisations, setOrganisations] = useState([]);

  const initValues = {
    username: "",
    displayName: "",
    roles: [],
    organisationId: "",
    password: "",
  };

  useEffect(() => {
    getOrganisations()
      .then((res) => {
        setOrganisations(res.data);
        if (action && id) {
          getUser(id)
            .then((user) => {
              user.data.roles = user.data.roles.map((r) => r.toString());
              setData(user.data);
            })
            .catch((err2) => {
              console.log(err2);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function validatePassword(value) {
    return !value
      ? "This field cannot be empty"
      : value.length < 8
      ? "Password must be at least 8 characters long"
      : null;
  }

  function validateRoles(roles) {
    return !roles.length ? "Please select at least one role" : null;
  }

  return (
    (!action || userData) && (
      <FormContainer
        backUrl={backUrl}
        initialValues={action ? userData : initValues}
        onSubmit={(values, actions) => {
          values.roles = values.roles.map((r) => Number(r));
          actions.setSubmitting(true);
          if (!action) {
            createUser(values)
              .then(() => {
                toast({
                  title: "Successful",
                  description: `User "${values.displayName}" has been created`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                actions.setSubmitting(false);
                history.push(backUrl);
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (action === "edit") {
            updateUser(values.id, values)
              .then(() => {
                toast({
                  title: "Successful",
                  description: `User "${values.displayName}" has been updated`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                actions.setSubmitting(false);
                history.push(backUrl);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }}
      >
        <Flex px="25px" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            User information
          </Text>
        </Flex>

        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          flexWrap={{ base: "wrap", lg: "nowrap" }}
          gap="10px"
          px="24px"
          my="20px"
          alignItems={"start"}
        >
          <Field name="username" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="username"
                  isInvalid={form.errors.username && form.touched.username}
                >
                  <FormLabel htmlFor="username">Username</FormLabel>
                  <Input
                    isReadOnly={viewOnly}
                    placeholder="user123"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>

          <Spacer />

          <Field name="displayName" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="displayName"
                  isInvalid={
                    form.errors.displayName && form.touched.displayName
                  }
                >
                  <FormLabel htmlFor="displayName">Display Name</FormLabel>
                  <Input
                    isReadOnly={viewOnly}
                    placeholder="User 123"
                    {...field}
                  />
                  <FormErrorMessage>{form.errors.displayName}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>

          <Spacer />

          <Field name="organisationId" validate={validateEmpty}>
            {({ field, form }) => {
              return (
                <FormControl
                  id="organisationId"
                  isInvalid={
                    form.errors.organisationId && form.touched.organisationId
                  }
                >
                  <FormLabel htmlFor="organisationId">Organisation</FormLabel>
                  <ReactSelect
                    {...field}
                    isReadOnly={viewOnly}
                    placeholder="Please select a orgainsation"
                    options={organisations.map(getSelectOptionObj)}
                  />
                  <FormErrorMessage>
                    {form.errors.organisationId}
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>

          {!id && (
            <>
              <Spacer />

              <Field name="password" validate={validatePassword}>
                {({ field, form }) => {
                  return (
                    <FormControl
                      id="password"
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <Input
                        readOnly={viewOnly}
                        placeholder="Min. 8"
                        min={8}
                        {...field}
                      />
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>
            </>
          )}
        </Flex>

        <Flex px="25px" mt="24px" align="center">
          <Text
            color={textColor}
            fontSize="18px"
            fontWeight="500"
            lineHeight="100%"
          >
            Account Roles/Permissions
          </Text>
        </Flex>

        <Flex px="25px" mt="24px" align="center">
          <Field type="checkbox" name="roles" validate={validateRoles}>
            {({ field, form }) => {
              return (
                <FormControl
                  isInvalid={form.errors.roles && form.touched.roles}
                >
                  <Stack
                    spacing={{ base: "12px", lg: "24px" }}
                    direction={{ base: "column", lg: "row" }}
                  >
                    <Checkbox
                      {...field}
                      isReadOnly={viewOnly}
                      id="admin"
                      defaultChecked={field.value.includes("0")}
                      value={0}
                    >
                      Admin
                    </Checkbox>
                    <Checkbox
                      {...field}
                      isReadOnly={viewOnly}
                      id="customer"
                      defaultChecked={field.value.includes("1")}
                      value={1}
                    >
                      Customer
                    </Checkbox>
                    <Checkbox
                      {...field}
                      isReadOnly={viewOnly}
                      id="production_staff"
                      defaultChecked={field.value.includes("2")}
                      value={2}
                    >
                      Production Staff
                    </Checkbox>
                    <Checkbox
                      {...field}
                      isReadOnly={viewOnly}
                      id="driver"
                      defaultChecked={field.value.includes("3")}
                      value={3}
                    >
                      Driver
                    </Checkbox>
                  </Stack>
                  <FormErrorMessage>{form.errors.roles}</FormErrorMessage>
                </FormControl>
              );
            }}
          </Field>
        </Flex>

        {viewOnly && (
          <Flex px="25px" mt="24px" alignItems="center" justifyContent="center">
            <ResetPasswordDialog userId={id} />
          </Flex>
        )}
      </FormContainer>
    )
  );
}
