import React, { useState } from "react";

import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Input,
    FormErrorMessage
} from "@chakra-ui/react";

import { Formik, Form, Field } from 'formik';
import moment from "moment";

import { filesUpload } from "apis/files";
import { updateLicense } from "apis/organisation";

import { PdfUploadFileType } from "utils/Constants";

import Upload from "components/admin/Upload";

export default function UpdateLicenseModal(props) {

    const { children, afterUpdateFunc, ...rest } = props
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [files, setFiles] = useState([]);

    const initValues = {
        file: "",
        expiryDate: ""
    }

    function onModelClose() {
        setFiles([]);
        onClose();
    }

    function validateExpiryDate(date) {
        return !date ? "Please select a date" : moment(date).isBefore(moment(), "day") ? "Cannot select an already expired date" : null;
    }

    return (
        <>
            <Button onClick={onOpen} {...rest}>{children}</Button>

            <Modal size="xl" isOpen={isOpen} onClose={onModelClose}>
                <ModalOverlay />
                <ModalContent>
                    <Formik
                        initialValues={initValues}
                        onSubmit={async (values, actions) => {
                            actions.setSubmitting(true);
                            try {

                                let fileForm = new FormData();
                                fileForm.append("files", files[0]);
                                var fileInfoRes = await filesUpload(fileForm)
                                values.fileInfo = fileInfoRes.data[0];

                                await updateLicense(values)

                                if (afterUpdateFunc) {
                                    afterUpdateFunc()
                                }

                                onModelClose();

                            } catch (error) {
                                console.log(error);
                            } finally {
                                actions.setSubmitting(false);
                            }

                        }}
                    >
                        <Form>
                            <ModalHeader>Radiation Management License</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Field name="expiryDate" validate={validateExpiryDate}>
                                    {({ field, form }) => (
                                        <FormControl id="expiryDate" py="10px" isInvalid={form.errors.expiryDate && form.touched.expiryDate}>
                                            <FormLabel htmlFor="expiryDate">Expiry Date</FormLabel>
                                            <Input type="date" {...field} min={moment().format("YYYY-MM-DD")} />
                                            <FormErrorMessage>{form.errors.expiryDate}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="file" validate={() => {
                                    if (files.length == 0) {
                                        return "Please upload a file";
                                    }
                                    return null;
                                }}>
                                    {({ form }) => (
                                        <FormControl id="file" isInvalid={!!(form.touched.file && files.length == 0)}>
                                            <Upload
                                                files={files}
                                                onSelected={(selectedFiles) => setFiles(selectedFiles)}
                                                multiple={false}
                                                selectedFileListTitles="Selected File"
                                                title={
                                                    files.length > 0
                                                        ? "Change File"
                                                        : "Upload File"
                                                }
                                                description="One PDF file only"
                                                fileTypes={PdfUploadFileType}
                                            ></Upload>
                                            <FormErrorMessage>{form.errors.file}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                            </ModalBody>

                            <ModalFooter>
                                <Button type="submit" colorScheme="brand" mr={3}>Submit</Button>
                                <Button colorScheme='blue' variant="outline" onClick={onModelClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Formik>
                </ModalContent>
            </Modal>
        </>
    )
}