import React, { useState, useEffect } from "react";

import { Image, Flex } from "@chakra-ui/react";
import { MdImageNotSupported } from "react-icons/md";
import { getImage } from "apis/files";
import { Spinner } from '@chakra-ui/react'

export default function AuthImage(props) {
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
    const { src, alt, isNotUploaded, ...rest } = props;

    const [imageSrc, setImageSrc] = useState(null);
    const [isMissing, setIsMissing] = useState(null);

    const notFoundColor = "red.400"
    useEffect(() => {
        if (isNotUploaded) {
            const blob = URL.createObjectURL(src);
            setImageSrc(blob);
        } 
        else {
            if (src) {
                setImageSrc(`${IMAGE_URL}${src}`);
                // getImage(src).then(res => {
                //     const blob = URL.createObjectURL(res);
                //     setImageSrc(blob);
                // }).catch(() => {
                //     setIsMissing(true)
                // })
            }
        }
    }, [src]);

    // if (imageSrc){
        return <Image src={imageSrc} {...rest} />
    // } else if (isMissing){
    //     return <Flex gap="12px" alignItems={"center"} color={notFoundColor}>
    //         <MdImageNotSupported/>
    //         <Image alt={alt}/>
    //         </Flex>
    // } 
    // else {
    //     return <Spinner />
    // }
}