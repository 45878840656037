/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useContext } from "react";
// import { NavLink } from "react-router-dom";
import { userLogin, getUserData } from "apis/auth.js";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";
// Custom components

import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { Formik, Form, Field } from "formik";
import { AuthContext } from "utils/AuthContext";
import { UserContext } from "App";


function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const brandStars = useColorModeValue("brand.500", "brand.400");

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const { login } = useContext(AuthContext);
  const [_, setUserContext] = useContext(UserContext);


  function validateUsername(username) {
    return !username ? "Username is required" : "";
  }

  function validatePassword(password) {
    return !password ? "Password is required" : "";
  }

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your username and password to sign in!
          </Text>
        </Box>
        <Formik
          initialValues={{ username: "", password: "" }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            userLogin(values)
              .then((token) => {
                login(token)
                getUserData().then(res => {
                  setUserContext(res)
                }).catch(err2 => {
                  console.log(err2)
                })
              })
              .catch((err) => console.log(err));
            actions.setSubmitting(false);
          }}
        >
          {(props) => (
            <Form>
              <Flex
                zIndex="2"
                direction="column"
                w={{ base: "100%", md: "420px" }}
                maxW="100%"
                background="transparent"
                borderRadius="15px"
                mx={{ base: "auto", lg: "unset" }}
                me="auto"
                mb={{ base: "20px", md: "auto" }}
              >
                <Field name="username" validate={validateUsername}>
                  {({ field, form }) => (
                    <FormControl
                      id="username"
                      isInvalid={form.errors.username && form.touched.username}
                      mb="24px"
                    >
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                        htmlFor="username"
                      >
                        Username<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        ms={{ base: "0px", md: "0px" }}
                        type="text"
                        placeholder="Your provided username, e.g. johnson"
                        fontWeight="500"
                        size="lg"
                        {...field}
                      />
                      <FormErrorMessage>
                        {form.errors.username}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="password" validate={validatePassword}>
                  {({ field, form }) => (
                    <FormControl
                      id="password"
                      isInvalid={form.errors.password && form.touched.password}
                      mb="24px"
                    >
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="8px"
                        htmlFor="password"
                      >
                        Password<Text color={brandStars}>*</Text>
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          isRequired={true}
                          fontSize="sm"
                          placeholder="Your password"
                          size="lg"
                          type={show ? "text" : "password"}
                          variant="auth"
                          {...field}
                        />
                        <InputRightElement
                          display="flex"
                          alignItems="center"
                          mt="4px"
                        >
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: "pointer" }}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={handleClick}
                          />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.password}
                      </FormErrorMessage>

                      {/* <Flex
                        justifyContent="space-between"
                        align="center"
                        my="24px"
                      >
                        <FormControl display="flex" alignItems="center">
                          <Checkbox
                            id="remember-login"
                            colorScheme="brandScheme"
                            me="10px"
                          />
                          <FormLabel
                            htmlFor="remember-login"
                            mb="0"
                            fontWeight="normal"
                            color={textColor}
                            fontSize="sm"
                          >
                            Keep me logged in
                          </FormLabel>
                        </FormControl>
                      </Flex> */}

                      <Button
                        fontSize="sm"
                        variant="brand"
                        fontWeight="500"
                        w="100%"
                        h="50"
                        my="24px"
                        isLoading={props.isSubmitting}
                        loadingText="Signing In"
                        type="submit"
                      >
                        Sign In
                      </Button>
                    </FormControl>
                  )}
                </Field>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
