import api from "./api";

const API_URL = `${process.env.REACT_APP_API_URL}/api/order`;

export const getProductAvailableTimes = async (productId) => {
  const res = await api.get(`${API_URL}/available-times/${productId}`);
  return res;
};

export const getAvailableProducts = async () => {
  const res = await api.get(`${API_URL}/available-products`);
  return res;
};

export const getOrgAvailableProducts = async (orgId) => {
  const res = await api.get(`${API_URL}/org-available-products/${orgId}`);
  return res;
};

export const getOrderHistory = async () => {
  const res = await api.get(`${API_URL}/history`);
  return res;
};

export const getOrderList = async () => {
  const res = await api.get(`${API_URL}`);
  return res;
};

export const customerGetOrder = async (id) => {
  const res = await api.get(`${API_URL}/customer/${id}`);
  return res;
};

export const adminGetOrder = async (id) => {
  const res = await api.get(`${API_URL}/admin/${id}`);
  return res;
};

export const placeOrder = async (form) => {
  const res = await api.post(`${API_URL}/place`, form);
  return res;
}

export const adminAddOrder = async (form) => {
  const res = await api.post(`${API_URL}/add`, form);
  return res;
}

export const updateOrder = async (id, form) => {
  const res = await api.put(`${API_URL}/${id}`, form);
  return res;
}

export const customerRequestChange = async (id, form) => {
  const res = await api.put(`${API_URL}/request-change/${id}`, form);
  return res;
}


export const updateOrderStatus = async (id, form) => {
  const res = await api.put(`${API_URL}/status/${id}`, form);
  return res;
}

export const staffUpdateOrder = async (id, form) => {
  const res = await api.put(`${API_URL}/staff/${id}`, form);
  return res;
}

export const markFileAsRead = async (id, fileName) => {
  const res = await api.put(`${API_URL}/read-file/${id}/${fileName}`);
  return res;
}