import React, { useState, useRef} from "react";

import {
    Flex,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    Button,
    Spacer,
    Input,
    Text,
    Checkbox
} from "@chakra-ui/react";

import SearchBar from 'components/admin/SearchBar';

export default function FilterBar(props) {
    const { data, setData } = props;

    // states for filtering
    const [timeSlotSearchInput, setTimeSlotSearchInput] = useState();
    const timeSlotSearchRef = useRef(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [notDisabled, setNotDisabled] = useState(false);

    //

    const menuBg = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function resetForm() {
        setTimeSlotSearchInput()
        timeSlotSearchRef.current?.clear();
        setIsDisabled(false)
        setNotDisabled(false)        
    }

    //// filter funtions
    function filter() {
        const filtered = data.filter(timeSlot =>
            filterTimeSlot(timeSlot)
            && filterDisabled(timeSlot)
        )

        setData(filtered)
    }

    //products
    function filterTimeSlot(timeSlot) {
        if (timeSlotSearchInput){
            if (!timeSlot.name.toLowerCase().includes(timeSlotSearchInput.toLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterDisabled(timeSlot){
        if (isDisabled == true && !timeSlot.isDisabled){
            return false;
        }
        if (notDisabled == true && timeSlot.isDisabled){
            return false;
        }
        return true;
    }

    ////

    return (
        <form>
            <Box
                w={{ sm: "100%", lg: "auto" }}
                bg={menuBg}
                p="20px"
                borderRadius="10px"
                boxShadow={shadow}
                mb="10px"
            >

                <Flex
                    justifyContent="end"
                    alignItems="start"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                >
                    <FormControl>
                        <FormLabel>Filter By Time Slot Name</FormLabel>
                        <SearchBar
                            ref={timeSlotSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setTimeSlotSearchInput}
                        />
                    </FormControl>
                
                </Flex>

                <Flex
                    justifyContent="end"
                    alignItems="center"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                    mt="10px"
                >

                    <FormControl>
                        <Flex gap="12px" fontSize="medium" >
                            <Text fontWeight="medium" >Time Slot is Disabled</Text>
                            <Checkbox colorScheme='green' textColor="gray.500" isChecked={isDisabled} onChange={(e) => {setIsDisabled(e.target.checked); setNotDisabled(false)}}>
                                Yes
                            </Checkbox>
                            <Checkbox colorScheme='red' textColor="gray.500" isChecked={notDisabled} onChange={(e) => {setNotDisabled(e.target.checked); setIsDisabled(false)}}>
                                No
                            </Checkbox>
                        </Flex>
                    </FormControl>
                    
                    <Spacer />

                    <Button variant="outline" colorScheme='blue' px="30px" onClick={filter}>Search</Button>
                    <Button variant="outline" colorScheme='yellow' px="30px" type="reset" onClick={resetForm}>Clear</Button>
                </Flex>
            </Box>
        </form>
    )
}