import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  FormErrorMessage,
  Checkbox,
  Button,
  Input,
  createStandaloneToast,
  FormLabel,
  FormControl,
  Container,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Field } from "formik";
import moment from "moment";

import ReactSelect from "components/admin/DefaultStyleReactSelect";
import FormContainer from "components/admin/FormContainer";

import { getProductSite } from "apis/productSite";
import { getProductionTimeSlots } from "apis/productionTimeSlot";
import { createProduct, getProductById, updateProduct } from "apis/product";
import { getOrganisations } from "apis/organisation";

import { validateEmpty, getSelectOptionObj, DaysOfWeekSelectOptions } from "utils/Constants";

export default function ProductForm(props) {
  const toast = createStandaloneToast();
  const { action, id } = useParams();
  const viewOnly = action === "view";
  const backUrl = "/admin/products";
  const history = useHistory();
  const [productData, setData] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [prodSites, setProdSites] = useState([]);
  const [prodTimeSlots, setProdTimeSlots] = useState([]);
  const [prodTimeSlotNames, setProdTimeSlotNames] = useState({});

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [rules, setRules] = useState([]);
  const [selectedDay, setSelectionDay] = useState();
  const [selectedOrg, setSelectionOrg] = useState();
  const [selectedSite, setSelectionSite] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [deliveryTime, setDeliveryTime] = useState();
  const [pickUpTime, setPickUpTime] = useState();
  const [inputTimeTouched, setInputTimeTouched] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [hover, setHover] = useState([null, null, null]);

  const rulesIndex = useMemo(
    () =>
      rules.findIndex(
        (rule) =>
          rule.productionSiteId == selectedSite &&
          rule.organisationId == selectedOrg
      ),
    [selectedSite, selectedOrg, rules, reRender]
  );

  const timeInputValidationMessage = useMemo(() => {
    if (!deliveryTime) {
      return "Please input delivery time!";
    } else if (!pickUpTime) {
      return "Please input pickup time!";
    } else if (pickUpTime > deliveryTime) {
      return "Delivery Pick Up Time cannot be larger than Arrival Time!";
    } else if (!selectedTimeSlot) {
      return "Please select a Time Slot!";
    } else if (!selectedDay) {
      return "Please select a production day!";
    } else {
      return "";
    }
  }, [deliveryTime, pickUpTime, selectedDay]);

  useEffect(() => {
    getProductSite().then((res) => {
      setProdSites(res.data);
      getOrganisations().then((res2) => {
        setOrgs(res2.data);
        getProductionTimeSlots().then((timeSlotsRes) => {
          setProdTimeSlots(timeSlotsRes.data);
          const timeSlotsObj = {};
          for (const timeSlot of timeSlotsRes.data) {
            timeSlotsObj[timeSlot.id] = timeSlot.name;
          }
          setProdTimeSlotNames(timeSlotsObj);
          if (action && id) {
            getProductById(id).then((res3) => {
              setData(res3.data);
              setRules(res3.data.rules);
            });
          }
        });
      });
    });
  }, []);

  function getTimes(dayOfWeek) {
    if (rulesIndex != -1) {
      let found = rules[rulesIndex].dayTimes.find(
        (dt) => dt.dayOfWeek == dayOfWeek
      );

      return found?.times
        .sort((a, b) =>
          a.pickUpTime > b.pickUpTime ? 1 : b.pickUpTime > a.pickUpTime ? -1 : 0
        )
        .map((t, i) => (
          <Button
            key={i}
            minWidth="200px"
            minHeight="65px"
            fontWeight="normal"
            variant="brand"
            bg="white"
            color="gray.600"
            outlineColor="gray.200"
            _focus="none"
            fontSize={{base: "small", md: "medium"}}
            onClick={() => {
              if (action != "view") {
                removeTime(t, i, dayOfWeek);
              }
            }}
            onMouseEnter={() => {
              setHover([t.pickUpTime, t.deliveryTime, dayOfWeek]);
            }}
            onMouseLeave={() => {
              setHover([null, null, null]);
            }}
            _hover={{
              bg: action == "view" ? "#71bf44" : "red.600",
              color: "white",
              outlineColor: action == "view" ? "#9ccb3b" : "red.600",
            }}
          >
            {!viewOnly &&
            t.pickUpTime == hover[0] &&
            t.deliveryTime == hover[1] &&
            dayOfWeek == hover[2] ? (
              "Delete"
            ) : (
              <Text>
                <strong>Time Slot: </strong> {prodTimeSlotNames[t.timeSlotId]}
                <br />
                <strong>Pickup: </strong> {moment(t.pickUpTime, ["HH:mm"]).format("h:mm A")}
                <br />
                <strong>Arrival : </strong> {moment(t.deliveryTime, ["HH:mm"]).format("h:mm A")}
              </Text>
            )}
          </Button>
        ));
    }
  }

  function removeTime(t, i, dayOfWeek) {
    let newTime = rules[rulesIndex].dayTimes
      .find((dt) => dt.dayOfWeek == dayOfWeek)
      .times.filter((time, index) => {
        return index !== i;
      });
    if (newTime.length == 0) {
      rules[rulesIndex].dayTimes = rules[rulesIndex].dayTimes.filter(
        (dt, index) =>
          index !==
          rules[rulesIndex].dayTimes.findIndex(
            (dt) => dt.dayOfWeek == dayOfWeek
          )
      );
      setRules([...rules.filter((r) => r.dayTimes.length > 0)]);
    } else {
      rules[rulesIndex].dayTimes[
        rules[rulesIndex].dayTimes.findIndex((dt) => dt.dayOfWeek == dayOfWeek)
      ].times = newTime;
      setRules([...rules]);
    }
  }

  function AddTime() {
    if (!inputTimeTouched) setInputTimeTouched(true);
    if (!timeInputValidationMessage) {
      if (rulesIndex == -1) {
        setRules([
          ...rules,
          {
            dayTimes: [
              {
                dayOfWeek: Number(selectedDay),
                times: [
                  {
                    timeSlotId: selectedTimeSlot,
                    pickUpTime: pickUpTime,
                    deliveryTime: deliveryTime,
                  },
                ],
              },
            ],
            productionSiteId: selectedSite,
            organisationId: selectedOrg,
          },
        ]);
      } else {
        let dtIndex = rules[rulesIndex].dayTimes.findIndex(
          (dt) => dt.dayOfWeek == Number(selectedDay)
        );
        if (dtIndex == -1) {
          rules[rulesIndex].dayTimes.push({
            dayOfWeek: Number(selectedDay),
            times: [
              {
                timeSlotId: selectedTimeSlot,
                pickUpTime: pickUpTime,
                deliveryTime: deliveryTime,
              },
            ],
          });
        } else {
          let time = rules[rulesIndex].dayTimes[dtIndex].times.find(
            (t) => t.pickUpTime == pickUpTime && t.deliveryTime == deliveryTime
          );
          if (!time) {
            rules[rulesIndex].dayTimes[dtIndex].times.push({
              timeSlotId: selectedTimeSlot,
              pickUpTime: pickUpTime,
              deliveryTime: deliveryTime,
            });
          }
        }
      }
    }
    setReRender(!reRender);
  }

  function validateProductQuantity(quantity) {
    return (!quantity && isNaN(quantity)) || Number(quantity) < 1
      ? "Please input a valid positive number"
      : "";
  }

  return (
    (!action || productData) && (
      <FormContainer
        backUrl="/admin/products"
        initialValues={
          action
            ? productData
            : {
                name: "",
                quantityLimit: 1,
                isLimited: false,
                takesUpEntireTimeSlot: false,
              }
        }
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          if (!action) {
            let newValue = values;
            newValue.rules = rules;
            createProduct(newValue)
              .then((res) => {
                toast({
                  title: "Successful",
                  description: `Product "${newValue.name}" has been created`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                history.push(backUrl);
              })
              .catch((err) => {
                actions.setSubmitting(false);
                console.log(err);
              });
          } else if (action === "edit") {
            let newValue = values;
            newValue.rules = rules;
            updateProduct(id, newValue)
              .then((res) => {
                toast({
                  title: "Successful",
                  description: `Product "${newValue.name}" has been updated`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
                history.push(backUrl);
              })
              .catch((err) => {
                actions.setSubmitting(false);
                console.log(err);
              });
          }
        }}
      >
        <Flex px="25px" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Product Information
          </Text>
        </Flex>

        <Flex
          my="24px"
          px="24px"
          gap={{ base: "12px", md: "48px" }}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Field name="name" validate={validateEmpty}>
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.name && form.touched.name}>
                <FormLabel htmlFor="name">Product Name</FormLabel>
                <Input
                  readOnly={viewOnly}
                  {...field}
                  id="name"
                  placeholder="Product 1"
                />
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="quantityLimit" validate={validateProductQuantity}>
            {({ field, form }) => (
              <FormControl
                id="quantityLimit"
                isInvalid={
                  form.errors.quantityLimit && form.touched.quantityLimit
                }
              >
                <FormLabel htmlFor="quantityLimit">
                  Quantity limit per order
                </FormLabel>
                <NumberInput
                  min={1}
                  {...field}
                  onChange={(v) => form.setFieldValue("quantityLimit", v)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormErrorMessage>{form.errors.quantityLimit}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Flex>

        <Flex px="24px" mb="24px" alignItems={"end"}>
          <Flex
            flexDirection={{ base: "column", md: "row" }}
            gap="24px"
            alignItems={"start"}
          >
            <Field name="isLimited">
              {({ field }) => (
                <Checkbox
                  readOnly={viewOnly}
                  defaultChecked={action ? field.value : false}
                  {...field}
                >
                  Limit Quantity
                </Checkbox>
              )}
            </Field>
            <Field name="takesUpEntireTimeSlot">
              {({ field }) => (
                <Checkbox
                  readOnly={viewOnly}
                  defaultChecked={action ? field.value : false}
                  {...field}
                >
                  Take Entire Slot
                </Checkbox>
              )}
            </Field>
          </Flex>
        </Flex>

        {!viewOnly && (
          <Flex px="25px" my="20px" align="center">
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Product Rules
            </Text>
          </Flex>
        )}

        <Flex
          px="24px"
          alignItems={"end"}
          flexDirection={{ base: "column", lg: "row" }}
          gap={{ base: "24px", lg: "48px" }}
        >
          <FormControl>
            <FormLabel>Production Site</FormLabel>
            <ReactSelect
              placeholder="Select a Production Site"
              options={prodSites.map(getSelectOptionObj)}
              onChange={(selected) => setSelectionSite(selected.value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Organization</FormLabel>
            <ReactSelect
              placeholder="Select a Organisation"
              options={orgs.map(getSelectOptionObj)}
              onChange={(selected) => setSelectionOrg(selected.value)}
            />
          </FormControl>
        </Flex>

        {selectedOrg && selectedSite && (
          <div>
            {!viewOnly && (
              <div>
                <FormControl
                  isInvalid={timeInputValidationMessage && inputTimeTouched}
                >
                  <Flex
                    px="24px"
                    mt="24px"
                    gap="48px"
                    flexDirection={{ base: "column", md: "row" }}
                    align={"end"}
                  >
                    <Container px="0px">
                      <FormLabel>Delivery Pick Up Time</FormLabel>
                      <Input
                        type="time"
                        onChange={(event) => setPickUpTime(event.target.value)}
                      ></Input>
                    </Container>

                    <Container px="0px">
                      <FormLabel>Arrival Time</FormLabel>
                      <Input
                        type="time"
                        onChange={(event) =>
                          setDeliveryTime(event.target.value)
                        }
                      ></Input>
                    </Container>

                    <Container>
                      <FormLabel>Production Time Slot</FormLabel>
                      <ReactSelect
                        placeholder="Select a time slot"
                        options={prodTimeSlots.map(getSelectOptionObj)}
                        onChange={(selected) =>
                          setSelectedTimeSlot(selected.value)
                        }
                      />
                    </Container>

                    <Container>
                      <FormLabel>Production Day</FormLabel>
                      <ReactSelect
                        placeholder="Select a date"
                        options={DaysOfWeekSelectOptions}
                        onChange={(selected) => setSelectionDay(selected.value)}
                      />
                    </Container>

                    <Flex width={{ base: "100%" }}>
                      <Button
                        variant="brand"
                        width={{ base: "100%" }}
                        onClick={() => AddTime()}
                      >
                        Add Time
                      </Button>
                    </Flex>
                  </Flex>

                  <Flex px="24px" mt="24px" justify="center">
                    <FormErrorMessage>
                      {timeInputValidationMessage}
                    </FormErrorMessage>
                  </Flex>
                </FormControl>
              </div>
            )}

            <Flex px="25px" my="24px" align="center" overflowX={"scroll"}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th textAlign={"center"}>Monday</Th>
                    <Th textAlign={"center"}>Tuesday</Th>
                    <Th textAlign={"center"}>Wednesday</Th>
                    <Th textAlign={"center"}>Thursday</Th>
                    <Th textAlign={"center"}>Friday</Th>
                    <Th textAlign={"center"}>Saturday</Th>
                    <Th textAlign={"center"}>Sunday</Th>
                  </Tr>
                </Thead>
                {}
                <Tbody>
                  <Tr>
                    <Td width={1 / 7}>
                      <Flex flexDirection={"column"} gap={"24px"} mx={"0px"}>
                        {getTimes(1)}
                      </Flex>
                    </Td>
                    <Td width={1 / 7}>
                      <Flex flexDirection={"column"} gap={"24px"} mx={"0px"}>
                        {getTimes(2)}
                      </Flex>
                    </Td>
                    <Td width={1 / 7}>
                      <Flex flexDirection={"column"} gap={"24px"} mx={"0px"}>
                        {getTimes(3)}
                      </Flex>
                    </Td>
                    <Td width={1 / 7}>
                      <Flex flexDirection={"column"} gap={"24px"} mx={"0px"}>
                        {getTimes(4)}
                      </Flex>
                    </Td>
                    <Td width={1 / 7}>
                      <Flex flexDirection={"column"} gap={"24px"} mx={"0px"}>
                        {getTimes(5)}
                      </Flex>
                    </Td>
                    <Td width={1 / 7}>
                      <Flex flexDirection={"column"} gap={"24px"} mx={"0px"}>
                        {getTimes(6)}
                      </Flex>
                    </Td>
                    <Td width={1 / 7}>
                      <Flex flexDirection={"column"} gap={"24px"} mx={"0px"}>
                        {getTimes(0)}
                      </Flex>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Flex>
          </div>
        )}
      </FormContainer>
    )
  );
}
