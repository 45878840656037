import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ children, component: Component, ...rest }) => {
  const { token } = useContext(AuthContext);

  return (<Route
    render={(props) => token ? <Component {...props} /> : <Redirect to="/sign-in" />}
    {...rest}
  />);
};

export default ProtectedRoute;
