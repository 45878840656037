export const columnsDataComplex = [
    {
      Header: "Display Name",
      accessor: "displayName",
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Roles",
      accessor: "paddedRoles",
    },
    {
      Header: "Organisation Name",
      accessor: "organisation.name",
    },
    {
      Header: "Actions",
    },
];