import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { columnsDataComplex } from "views/admin/geoLocation/components/columnsData";
import { getGeoLocations, removeGeoLocation } from "apis/geoLocation";
import ItemsTable from "components/admin/ItemsTable";
import FilterBar from "views/admin/geoLocation/components/filterBar";

export default function GeoLocation() {
  useEffect(() => {
    refreshGeoLocations();
  }, []);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);

  // function prepareData(data) {
  //   const preparedData = data.map((teamGeoLocation) => {
  //     return teamGeoLocation;
  //   });
  //   setData(preparedData);
  // }

  function refreshGeoLocations() {
    getGeoLocations()
      .then((res) => {
        setUnfilteredData(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deleteGeoLocation(id) {
    removeGeoLocation(id)
      .then(() => {
        refreshGeoLocations();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={setData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="location/view"
        createUrl="location/create"
        disableCallback={deleteGeoLocation}
        disableBtnText="Delete"
        disableDialogHeader="Delete "
        disableDialogText="Are you sure you want to delete "
        disableConfirmBtnText="Delete"
      />
    </Box>
  );
}
