import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  createStandaloneToast
} from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { forceResetPassword } from "apis/user"

export default function ResetPasswordDialog(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { userId } = props;
  const cancelRef = React.useRef()
  const colorScheme = "blue";
  const toast = createStandaloneToast();
  const [showNew, setShowNew] = React.useState(false)
  function validatePassword(value) {
    return !value ? "This field cannot be empty"
      : value.length < 8 ? "Password must be at least 8 characters long" : null;
  }

  return (
    <>
      <Button
        colorScheme={colorScheme}
        onClick={onOpen}>
        Reset Password
      </Button>


      <AlertDialog
        isOpen={isOpen}
        validateOnChange={false}
        validateOnBlur={false}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <Formik
          initialValues={{ newPassword: "" }}
          onSubmit={(values, actions) => {
            forceResetPassword(userId, values)
              .then(res => {
                toast({
                  title: 'Successful',
                  description: "Password reset successfully",
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                })
                onClose()
              }).catch(err => {
                console.log(err);
              })
          }}
        >
          {(props) => (
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Reset Password
                </AlertDialogHeader>

                <AlertDialogBody>
                  <Form>
                    <Field type="password" name="newPassword" validate={validatePassword}>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.newPassword && form.touched.newPassword}>
                          <FormLabel htmlFor="newPassword">New Password</FormLabel>
                          <InputGroup>
                            <Input
                              {...field}
                              id="newPassword"
                              placeholder=""
                              type={showNew ? "text" : "password"}

                            />
                            <InputRightElement
                              display="flex"
                              alignItems="center"
                            >
                              <Icon
                                as={showNew ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                onClick={() => { setShowNew(!showNew) }}
                              />
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage>{form.errors.newPassword}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Form>
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme={colorScheme}
                    type="submit"
                    onClick={() => props.submitForm()}
                    ml={3}>
                    Reset
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          )}

        </Formik>
      </AlertDialog>

    </>
  )
}