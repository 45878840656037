export const columnsDataComplex = [
    {
        Header: "Order Person",
        accessor: "orderPerson",
    },
    {
      Header: "Product Name",
      accessor: "productName",
    },
    {
        Header: "Quantity",
        accessor: "quantity",
    },
    {
        Header: "Purchase Order",
        accessor: "purchaseOrder",
    },
    {
        Header: "Delivery Date Time",
        accessor: "deliveryDateTime",
    },
    {
      Header: "Status",
      accessor: "statusDisplay",
    },
    {
      Header: "Actions"
    },
];