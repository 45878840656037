import React, { useState , useRef} from "react";

import {
    Flex,
    Box,
    useColorModeValue,
    FormControl,
    FormLabel,
    Button,
    Spacer,
    Input,
    Text,
    Checkbox
} from "@chakra-ui/react";

import SearchBar from 'components/admin/SearchBar';

export default function FilterBar(props) {
    const { data, setData } = props;

    // states for filtering
    const [nameSearchInput, setNameSearchInput] = useState();
    const nameSearchRef = useRef(null);
    const [emailSearchInput, setEmailSearchInput] = useState();
    const emailSearchRef = useRef(null);
    const [contactSearchInput, setContactSearchInput] = useState();
    const contactSearchRef = useRef(null);
    const [addressSearchInput, setAddressSearchInput] = useState();
    const addressSearchRef = useRef(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [notDisabled, setNotDisabled] = useState(false);

    //

    const menuBg = useColorModeValue("white", "navy.800");
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function resetForm() {
        setNameSearchInput()
        nameSearchRef.current?.clear();
        setEmailSearchInput()
        emailSearchRef.current?.clear();
        setContactSearchInput()
        contactSearchRef.current?.clear();
        setAddressSearchInput()
        addressSearchRef.current?.clear();
        setIsDisabled(false)
        setNotDisabled(false)
    }

    //// filter funtions
    function filter() {
        const filtered = data.filter(organisation =>
            filterName(organisation)
            && filterContact(organisation)
            && filterEmail(organisation)
            && filterAddress(organisation)
            && filterDisabled(organisation)
        )

        setData(filtered)
    }

    function filterName(organisation) {
        if (nameSearchInput){
            if (!organisation.name.toLowerCase().includes(nameSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterEmail(organisation) {
        if (emailSearchInput){
            if (!organisation.email.toLowerCase().includes(emailSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterContact(organisation) {
        if (contactSearchInput){
            if (!organisation.contactNumber.toLowerCase().includes(contactSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterAddress(organisation) {
        if (addressSearchInput){
            if (!organisation.address.toLowerCase().includes(addressSearchInput.toLocaleLowerCase())){
                return false;
            }
        }
        return true;
    }

    function filterDisabled(organisation){
        if (isDisabled == true && !organisation.isDisabled){
            return false;
        }
        if (notDisabled == true && organisation.isDisabled){
            return false;
        }
        return true;
    }

    ////

    return (
        <form>
            <Box
                w={{ sm: "100%", lg: "auto" }}
                bg={menuBg}
                p="20px"
                borderRadius="10px"
                boxShadow={shadow}
                mb="10px"
            >

                <Flex
                    justifyContent="end"
                    alignItems="end"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                >
                    <FormControl>
                        <FormLabel>Filter By Organisation Name</FormLabel>
                        <SearchBar
                            ref={nameSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setNameSearchInput}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Email</FormLabel>
                        <SearchBar
                            ref={emailSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setEmailSearchInput}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Contact Number</FormLabel>
                        <SearchBar
                            ref={contactSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setContactSearchInput}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Filter By Address</FormLabel>
                        <SearchBar
                            ref={addressSearchRef}
                            width="unset"
                            me="10px"
                            borderRadius="0.375rem"
                            clearable
                            onChange={setAddressSearchInput}
                        />
                    </FormControl>
                    <Spacer />
                </Flex>

                <Flex
                    justifyContent="end"
                    alignItems="center"
                    flexDirection="row"
                    flexWrap={{ base: "wrap", lg: "nowrap" }}
                    gap="10px"
                    mt="10px"
                >
                    <FormControl>
                        <Flex gap="12px" fontSize="medium" >
                            <Text fontWeight="medium" >Organisation is Disabled</Text>
                            <Checkbox colorScheme='green' textColor="gray.500" isChecked={isDisabled} onChange={(e) => {setIsDisabled(e.target.checked); setNotDisabled(false)}}>
                                Yes
                            </Checkbox>
                            <Checkbox colorScheme='red' textColor="gray.500" isChecked={notDisabled} onChange={(e) => {setNotDisabled(e.target.checked); setIsDisabled(false)}}>
                                No
                            </Checkbox>
                        </Flex>
                    </FormControl>
                    
                    <Spacer />

                    <Button variant="outline" colorScheme='blue' px="30px" onClick={filter}>Search</Button>
                    <Button variant="outline" colorScheme='yellow' px="30px" type="reset" onClick={resetForm}>Clear</Button>
                </Flex>
            </Box>
        </form>
    )
}