import api from "./api";

const API_URL = `${process.env.REACT_APP_API_URL}/api/ProductionTimeSlot`;

export const getProductionTimeSlots = async () => {
  const res = await api.get(`${API_URL}`);
  return res;
};

export const getProductionTimeSlotByID = async (id) => {
  const res = await api.get(`${API_URL}/${id}`);
  return res;
};

export const createProductionTimeSlot = async (form) => {
  const res = await api.post(`${API_URL}/`, form);
  return res;
}

export const updateProductionTimeSlot = async (id, form) => {
  const res = await api.put(`${API_URL}/${id}`, form);
  return res;
}

export const disableProductionTimeSlot = async (id, isDisabled) => {
  const res = await api.put(`${API_URL}/disable/${id}`, isDisabled);
  return res;
}