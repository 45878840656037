export const columnsDataComplex = [
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Reference Link",
      accessor: "url",
      isLink: true,
    },
    {
      Header: "Actions",
      accessor: "id",
    },
];