import api from "./api";

const API_URL = `${process.env.REACT_APP_API_URL}/api/ProductionSite`;

export const getProductSite = async () => {
  const res = await api.get(`${API_URL}`);
  return res;
};

export const getProductSiteID = async (id) => {
  const res = await api.get(`${API_URL}/${id}`);
  return res;
};

export const createProductSite = async (form) => {
  const res = await api.post(`${API_URL}/`, form);
  return res;
}

export const updateProductSite = async (id, form) => {
  const res = await api.put(`${API_URL}/${id}`, form);
  return res;
}

export const disableProductSite = async (id, isDisabled) => {
  const res = await api.put(`${API_URL}/disable/${id}`, isDisabled);
  return res;
}