import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { columnsDataComplex } from "views/admin/contact/components/columnsData";
import { getContacts, removeContact } from "apis/contact";
import ItemsTable from "components/admin/ItemsTable";
import { MomentFormatWithTimeZone } from "utils/Constants";
import moment from "moment";
import FilterBar from "views/admin/contact/components/filterBar";

export default function Contact() {
  useEffect(() => {
    refreshContacts();
  }, []);

  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  function prepareData(data) {
    const preparedData = data.map((contact) => {
      contact.locationName = contact.location.name;

      contact.paddedContactedDateTime = (
        <Flex flexDirection="column" textAlign="center">
          <Text textColor={textColor}>
            {moment(contact.contactedDateTime).format(MomentFormatWithTimeZone)}
          </Text>
          <Text
            textAlign="center"
            color={
              moment(new Date()).diff(contact.contactedDateTime, "hour") > 0
                ? "red.500"
                : "green.500"
            }
            fontSize="sm"
            fontWeight="700"
          >
            {moment(new Date()).diff(contact.contactedDateTime, "hour") > 0
              ? `${moment(new Date()).to(contact.contactedDateTime)}`
              : `${moment(contact.contactedDateTime).from(new Date())}`}
          </Text>
        </Flex>
      );

      return contact;
    });

    setData(preparedData);
  }

  function refreshContacts() {
    getContacts()
      .then((res) => {
        setUnfilteredData(res.data);
        prepareData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function deleteContact(id) {
    removeContact(id)
      .then(() => {
        refreshContacts();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={prepareData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="contact/view"
        disableCallback={deleteContact}
        disableBtnText="Delete"
        disableDialogHeader="Delete "
        disableDialogText="Are you sure you want to delete "
        disableConfirmBtnText="Delete"
      />
    </Box>
  );
}
