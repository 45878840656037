import { React, useEffect, useState, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  List,
  ListItem,
  ListIcon,
  Flex,
  Text,
  FormControl,
  FormLabel,
  createStandaloneToast,
  useColorModeValue,
  Select,
  Icon,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react";

import { getOrganisation, approveLicense } from "apis/organisation";
import { UserRoles } from "utils/Constants";
import { UserContext } from "App";

import Information from "components/admin/Information";
import FormContainer from "components/admin/FormContainer";
import AdminLicenseInfo from "./components/AdminLicenseInfo";
import LicenseView from "./components/LicenseView";

export default function OrganisationView() {
  const { id } = useParams();
  const [userContext, _] = useContext(UserContext);

  const [organisation, setOrganisation] = useState(null);
  const [license, setLicense] = useState(null);
  const [licenseHistory, setLicenseHistory] = useState([]);

  const isAdmin = userContext.roles.includes(UserRoles.Admin);
  const roleUrl = isAdmin
    ? "admin"
    : userContext.roles.includes(UserRoles.ProductionStaff)
    ? "staff"
    : "delivery";

  const backUrl = `/${roleUrl}/organisations`;
  const editUrl = isAdmin ? `/admin/organisation/edit/${id}` : null;

  const cardShadow = useColorModeValue(
    "0px 20px 40px rgba(112, 144, 176, 0.2)",
    "unset"
  );
  const textColor = useColorModeValue("secondaryGray.900", "white");

  function ApproveLicenseClick(isApproved) {
    approveLicense(organisation.id, isApproved)
      .then((res) => {
        refreshData();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function refreshData() {
    getOrganisation(id)
      .then((res) => {
        setOrganisation(res.data);
        if (res.data.currentLicense) {
          setLicense(res.data.currentLicense);
        }
        setLicenseHistory(
          [...res.data.licenseHistory, res.data.currentLicense].reverse()
        );
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    refreshData();
  }, []);

  return (
    organisation && (
      <FormContainer backUrl={backUrl} editUrl={editUrl}>
        <Text color={textColor} fontWeight="bold" fontSize="2xl" mb="20px">
          Organisation Details
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
          <Information
            boxShadow={cardShadow}
            title="Name"
            value={organisation.name}
          />
          <Information
            boxShadow={cardShadow}
            title="Address"
            value={organisation.address}
          />
          <Information
            boxShadow={cardShadow}
            title="Contact"
            value={organisation.contactNumber}
          />
          <Information
            boxShadow={cardShadow}
            title="Email"
            value={organisation.email}
          />
        </SimpleGrid>

        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
          my="30px"
        >
          License Information
        </Text>

        {isAdmin ? (
          <AdminLicenseInfo
            licenseHistory={licenseHistory}
            license={license}
            setLicense={setLicense}
            ApproveLicenseClick={ApproveLicenseClick}
          />
        ) : (
          <LicenseView license={license} />
        )}
      </FormContainer>
    )
  );
}
