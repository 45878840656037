import React, { useState, useContext, useMemo } from "react";

import {
    Button,
    List,
    ListItem,
    ListIcon,
    FormControl,
    FormLabel,
    Link,
    Textarea,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Flex,
    Image,
    FormErrorMessage
} from "@chakra-ui/react";

import { DeleteIcon } from "@chakra-ui/icons";

import { Formik, Form, Field } from 'formik';

import {
    OrderStatusOptions,
    OrderStatusSelectFilterOptions,
    DriverOrderStatusOptions,
    UserRoles,
    PdfUploadFileType,
    ImageUploadFileTypes,
    MaxFilesUpload,
} from "utils/Constants";

import { MdAttachFile } from "react-icons/md";
import Upload from "components/admin/Upload";
import ReactSelect from "components/admin/ReactSelectFormik";
import { UserContext } from "App";

import { filesUpload, imageUpload } from "apis/files";
import { staffUpdateOrder } from "apis/order";
import AuthImage from "components/auth/AuthImage";
import AuthLink from "components/auth/AuthLink";

export default function StaffDriverUpdateDetailsModal(props) {
    const [userContext, _] = useContext(UserContext);
    const isStaff = userContext.roles.includes(UserRoles.ProductionStaff);
    const isDriver = userContext.roles.includes(UserRoles.Driver);

    const { children, initialValues, afterUpdateFunc, ...rest } = props
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [sharedFiles, setSharedFiles] = useState([]);
    const [internalFiles, setInternalFiles] = useState([]);
    const [imageFile, setImageFile] = useState([]);

    function onModelClose() {
        setSharedFiles([]);
        setInternalFiles([]);
        setImageFile([]);
        onClose();
    }

    function validateStatus(val) {
        if (val === null || val === undefined) {
            return "Please choose a status";
        }
        if (!isStaff && isDriver) {
            if (!DriverOrderStatusOptions.some(s => s.value == val)) {
                return "Please choose a status";
            }
        }
        return null;
    }

    const deliveredImageUrl = useMemo(() =>
        imageFile.length > 0 ?
            imageFile[0] :
            initialValues?.deliveredImageUrl ?
                initialValues.deliveredImageUrl : null,
        [imageFile]);

    function removeFile(form, field, index) {
        const { value } = field;
        value.splice(index, 1);
        form.setFieldValue(field.name, value);
    }

    function getMaxFilesUpload(fileLength) {
        return MaxFilesUpload - fileLength;
    }

    return (
        <>
            <Button onClick={onOpen} {...rest}>{children}</Button>

            <Modal size="3xl" isOpen={isOpen} onClose={onModelClose}>
                <ModalOverlay />
                <ModalContent>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={async (values, actions) => {
                            actions.setSubmitting(true);
                            try {
                                values.status = Number(values.status)

                                if (sharedFiles.length > 0) {
                                    let filesForm = new FormData();
                                    for (let file of sharedFiles) {
                                        filesForm.append("files", file);
                                    }
                                    let filesRes = await filesUpload(filesForm);
                                    values.sharedFiles.push(...filesRes.data);
                                }

                                if (internalFiles.length > 0) {
                                    let internalFilesForm = new FormData();
                                    for (let file of internalFiles) {
                                        internalFilesForm.append("files", file);
                                    }
                                    var internalFilesRes = await filesUpload(internalFilesForm)
                                    values.internalFiles.push(...internalFilesRes.data);
                                }

                                if (imageFile.length > 0) {
                                    let imageForm = new FormData();
                                    imageForm.append("image", imageFile[0]);
                                    var imageRes = await imageUpload(imageForm)
                                    values.deliveredImageUrl = imageRes.data.name
                                }

                                await staffUpdateOrder(initialValues.id, values)

                                if (afterUpdateFunc) {
                                    afterUpdateFunc()
                                }

                                onModelClose()

                            } catch (error) {
                                console.log(error);
                            } finally {
                                actions.setSubmitting(false);
                            }

                        }}
                    >
                        <Form>
                            <ModalHeader>Update Order Details</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>

                                <Field name="status" validate={validateStatus}>
                                    {({ field, form }) => {
                                        return (
                                            <FormControl
                                                id="status"
                                                isInvalid={form.errors.status && form.touched.status}
                                            >
                                                <FormLabel htmlFor="status">Status</FormLabel>
                                                <ReactSelect
                                                    {...field}
                                                    placeholder="Select a status"
                                                    options={isStaff ? OrderStatusOptions : DriverOrderStatusOptions}
                                                    filterOption={OrderStatusSelectFilterOptions}
                                                />
                                                <FormErrorMessage>{form.errors.status}</FormErrorMessage>
                                            </FormControl>
                                        );
                                    }}
                                </Field>

                                <Field name="internalNotes">
                                    {({ field }) => (
                                        <FormControl id="internalNotes" py="10px">
                                            <FormLabel htmlFor="internalNotes">Internal Notes</FormLabel>
                                            <Textarea {...field} placeholder="N/A"></Textarea>
                                        </FormControl>
                                    )}
                                </Field>

                                {isStaff && (<>
                                    <Field name="sharedFiles">
                                        {({ form, field }) => (
                                            <>
                                                <FormControl py="10px">
                                                    <FormLabel>Shared files</FormLabel>
                                                    <List spacing={3}>
                                                        {field.value?.map((file, i) => (
                                                            <ListItem key={i}>
                                                                <AuthLink
                                                                    href={file.name}
                                                                    icon={MdAttachFile}
                                                                    color="blue.400"
                                                                    isExternal
                                                                    isList
                                                                >
                                                                    {file.originalName}
                                                                </AuthLink>
                                                                <DeleteIcon
                                                                    cursor="pointer"
                                                                    ml="5px"
                                                                    color="red.500"
                                                                    onClick={() => removeFile(form, field, i)}
                                                                />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </FormControl>

                                                {field.value?.length < MaxFilesUpload && (
                                                    <Upload
                                                        max={getMaxFilesUpload(field.value.length)}
                                                        onSelected={(selectedFiles) => setSharedFiles(selectedFiles)}
                                                        files={sharedFiles}
                                                        multiple={true}
                                                        title={
                                                            initialValues?.sharedFiles.length > 0 || internalFiles.length > 0 ?
                                                                "Upload new files" : "Upload files"
                                                        }
                                                        description={`PDF only, max ${getMaxFilesUpload(
                                                            field.value.length
                                                        )} file(s)`}
                                                        fileTypes={PdfUploadFileType}
                                                        selectedFileListTitles="The following selected files will be added"
                                                    />
                                                )}


                                            </>
                                        )}
                                    </Field>

                                    <Field name="internalFiles">
                                        {({ form, field }) => (
                                            <>
                                                <FormControl py="10px">
                                                    <FormLabel>Internal files</FormLabel>
                                                    <List spacing={3}>
                                                        {field.value?.map((file, i) => (
                                                            <ListItem key={i}>
                                                                <AuthLink
                                                                    href={file.name}
                                                                    icon={MdAttachFile}
                                                                    color="blue.400"
                                                                    isExternal
                                                                    isList
                                                                >
                                                                    {file.originalName}
                                                                </AuthLink>
                                                                <DeleteIcon
                                                                    cursor="pointer"
                                                                    ml="5px"
                                                                    color="red.500"
                                                                    onClick={() => removeFile(form, field, i)}
                                                                />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </FormControl>

                                                {field.value?.length < MaxFilesUpload && (
                                                    <Upload
                                                        max={getMaxFilesUpload(field.value.length)}
                                                        onSelected={(selectedFiles) => setInternalFiles(selectedFiles)}
                                                        files={internalFiles}
                                                        multiple={true}
                                                        title={
                                                            initialValues?.internalFiles.length > 0 || internalFiles.length > 0 ?
                                                                "Upload new files" : "Upload files"
                                                        }
                                                        description={`PDF only, max ${getMaxFilesUpload(
                                                            field.value.length
                                                        )} file(s)`}
                                                        fileTypes={PdfUploadFileType}
                                                        selectedFileListTitles="The following selected files will be added"
                                                    />
                                                )}


                                            </>
                                        )}
                                    </Field>

                                </>)}

                                {isDriver && (<>
                                    <FormControl id="deliveredImageUrl">
                                        <FormLabel>Delivery Image</FormLabel>
                                        {deliveredImageUrl && (<Flex pb="10px" px="25px" mt="24px" align="center" justify="center">
                                            <AuthImage maxH="300px" src={deliveredImageUrl} isNotUploaded={imageFile.length > 0} />
                                        </Flex>)}
                                    </FormControl>

                                    <Upload
                                        onSelected={(selectedFiles) => setImageFile(selectedFiles)}
                                        multiple={false}
                                        selectedFileListTitles="Selected Image"
                                        files={imageFile}
                                        title={
                                            initialValues?.deliveredImageUrl || imageFile.length > 0
                                                ? "Change Image"
                                                : "Upload Image"
                                        }
                                        description="One image file only"
                                        fileTypes={ImageUploadFileTypes}
                                    />
                                </>)}
                            </ModalBody>

                            <ModalFooter>
                                <Button type="submit" colorScheme="brand" mr={3}>Update</Button>
                                <Button colorScheme='blue' variant="outline" onClick={onModelClose}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </Form>
                    </Formik>
                </ModalContent>
            </Modal>
        </>
    )
}