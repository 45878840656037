import React from "react"

import {
    SimpleGrid,
} from "@chakra-ui/react";

import AuthLink from "components/auth/AuthLink";

import moment from "moment";

import { MdAttachFile } from "react-icons/md";

import Information from "components/admin/Information";

import { MomentFormatWithTimeZone, MomentFormatDateOnly, LicenseStatusTextWithIcons } from "utils/Constants";

function LicenseData(props) {
    const { license } = props;
    return (<>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 5 }} gap="20px" mb="20px">

            <Information
                title="Uploader"
                value={license.uploaderName}
            />
            <Information
                title="Status"
                value={LicenseStatusTextWithIcons[license.status]}
            />
            <Information
                title="Approver"
                value={license.approverName}
            />
            <Information
                title="Uploaded on"
                value={moment(license.uploadedOn).format(MomentFormatWithTimeZone)}
            />
            <Information
                title="Expiry Date"
                value={moment(license.expiryDate).format(MomentFormatDateOnly)}
            />

        </SimpleGrid>

        <Information
            title="License file"
            value={<AuthLink
                href={license.fileInfo.name}
                icon={MdAttachFile}
                color="blue.400"
                isExternal>
                {license.fileInfo.originalName}
            </AuthLink>}
        />
    </>)
}

export default function LicenseView(props) {
    const { license } = props;

    return license ? <LicenseData {...props} /> : <Information value="No license uploaded" />
}