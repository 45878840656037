// Chakra imports
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";

export default function Information(props) {
  const { size, title, value, secondValue, boxShadow, ...rest } = props;
  
  const cardShadow = useColorModeValue(
    "0px 20px 40px rgba(112, 144, 176, 0.2)",
    "unset"
  );

  const fontSize = {
    title: 'sm',
    body: 'md'
  };
  switch (size) {
    case 'lg':
      fontSize.title = 'md'
      fontSize.body = 'lg'
      break;
    default:
      fontSize.title = 'sm'
      fontSize.body = 'md'
      break;
  }

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("yellow.700", "yellow.400");
  const titleColor = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} boxShadow={boxShadow || cardShadow} {...rest}>
      <Box>
        <Text fontWeight='500' color={titleColor} fontSize={fontSize.title}>
          {title}
        </Text>
        {typeof value === "string" || typeof secondValue === "number"
          ?
          (<Text style={{ whiteSpace: "pre-wrap" }} color={textColorPrimary} fontWeight='500' fontSize={fontSize.body}>
            {value}
          </Text>)
          :
          (<>{value}</>)
        }
        {typeof secondValue === "string" || typeof secondValue === "number"
          ?
          (<Text style={{ whiteSpace: "pre-wrap" }} as="i" color={textColorSecondary}>{secondValue}</Text>)
          :
          (<>{secondValue}</>)
        }
      </Box>
    </Card>
  );
}

